import React, { useState } from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import {useNavigate, useParams} from "react-router-dom";
import { tellSlack } from "network/apis";
import { toast } from "react-toastify"
import Createdemo from '../create';



export default function ListDemoClass(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()
    const navigate = useNavigate();


    async function loadFunc(page) {
        const demo = await network.get(`${config.base_path}/api/dashboard/demos/list/${page}?class_id=${params.class_id}`)
        setHasMore(demo.data.payload.length !== 0)
        setItems(demo.data.payload)
    }
    function DemoClass(props) {
        return <Grid item xs={12}>
            <Card style={{ margin: "8px", padding: "8px" }}>
                <Grid container direction={"row"}>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Grid item xs={12}>{props.title}</Grid>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Grid item xs={12}>
                            <ButtonGroup>
                                <Button variant={"outlined"} color={"success"}
                                    onClick={() => navigate(`/fellowship/${params.class_id}/demo/list/${props._id}/booking/1`)}>Booking</Button>
                                <Button variant={"outlined"} color={"error"}
                                    onClick={() => setEditObject(props)}>Edit</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    const handleSubmit = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/demos/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited Successfully", { autoClose: 1000 })
    }


    return <BasePage>
        <Createdemo />
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/demo/list/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <DemoClass key={index} {...a} />)} />
        {!!editObject && <EditFormDrawer onClose={() => setEditObject(null)} open={!!editObject} schema={editObject}
            onSave={handleSubmit} />}
    </BasePage>
}
