import React, { useState } from "react";
import network from "network";
import config from "config";
import Button from "@mui/material/Button";
import EditFormDrawer from "components/custom/EditFormDrawer";
import {toast} from 'react-toastify';

export default function Create(props) {
    const [object, setObject] = useState(null)

    async function loadFunc() {
        const module = await network.get(`${config.base_path}/api/dashboard/${props?.slug}/sample`)
        setObject(module.data.payload)
    }
    const handleSubmit = async (mainData) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/${props?.slug}/create`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success(`${props?.title} Created`, {autoClose : 2000})
        }
        else
            toast.error(res.data.message , {autoClose:2000})
    }
    return <>
        <Button variant={"outlined"} onClick={() => loadFunc()}>{`+Create New ${props?.title}`}</Button>
        {!!object && <EditFormDrawer id={props?.id} title={`Create ${props?.title}`} onClose={() => setObject(null)} open={!!object} schema={object} onSave={handleSubmit} />}
    </>
}
