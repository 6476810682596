export default function helperText(id, value) {

    switch (id) {
        case "work_opp": {
            switch(value) {
                case "employer_type":
                    return "individual / corporate"
                case "job_type":
                    return "project / contest / gig_work / internship"
                case "work_location_type":
                    return "remote / office / hybrid"
                default:
                    return ""
            }
        }
        case "assignments": {
            switch (value) {
                case "resource_type":
                    return "type where you have to show this assignment unluclass/module/episode"
                case "resource_id":
                    return "id of resource_type"
                default:
                    return ''
            }
        }
        case 'settings': {
            switch (value) {
                case "_id":
                    return "Don't perform any action in case of editing / Must be Empty in case of new creation"
                case "category":
                    return "platform where setting should be implemented"
                case "type":
                    return "name of object where this setting makes changes"
                case "score":
                    return "Managed by Tech team, Do not perform any action"
                default:
                    return ''
            }
        }
        case 'unlu_fellowship': {
            switch (value) {
                case "class_type":
                    return "in case of FELLOWSHIP it will be '510' and in case of UNLUCLASS it will be '500'"
                case "celeb_name":
                    return "Name of the Celebrity who are going to teach in this class"
                case "end_time":
                    return "end of enrollment date"
                case "class_extra_meta":
                    return "extra information of class extra information of class"
                case "class_extra_meta_v2":
                    return "new pdp content"
                case "batch_start_time":
                    return "this is the starting time of batch for this fellowship"
                case "application_end_time":
                    return "enrollment for this fellowship will be end on this date"
                case "is_live":
                    return "if 'True' then fellowship will shown at the listing page"
                case "is_public":
                    return "if 'True' then User Dashboard is live for users"
                case "need_to_apply":
                    return "if True - users have to submit assignment before purchase or explore"
                case "preview_url":
                    return "preview image url of existing type 'could be empty in some cases'"
                case "thumbnail_url":
                    return "This is the fellowship which appears on fellowship listing page"
                case "start_time":
                    return "fellowship starts time -'Must be change in class_extra_meta_v2 's header_content'"
                case "join_time":
                    return "last date of joining for users"
                case "class_features":
                    return "learning steps for launch journey"
                case "uid":
                    return "unique name or id"
                default:
                    return ''
            }
        }
        case 'unlulive': {
            switch (value) {
                case "type":
                    return "MEETING_ONE_TO_MANY = 1, MEETING_MANY_TO_MANY = 2"
                default:
                    return ''
            }
        }
        case 'unlulive_acl': {
            switch (value) {
                case "resource_type":
                    return "RESOURCE_UNLUCLASS_ENROLLMENT = 1,RESOURCE_USER = 2, RESOURCE_ALL_USERS = 11"
                case "role":
                    return "ROLE_NOT_ALLOWED = 0, ROLE_HOST = 1, ROLE_PARTICIPANT = 2"
                default:
                    return ''
            }
        }
        default:
            return ''
    }
};

