import Button from "@mui/material/Button";
import React from "react";
import {toast} from "react-toastify";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function CopyIDButton(props: { id: string }) {
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'))

    const onCopy = () => {
        navigator.clipboard.writeText(props.id)
        toast('ID copied to clipboard', {
            autoClose: 1000
        })
        return;
    }

    return <Button variant={"outlined"} color={"primary"} sx={{maxWidth: matchMobile ? '100%' : '200px'}}
                   onClick={onCopy}>
        copy id
    </Button>
}