import React, { useState } from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useParams, useNavigate } from "react-router-dom";
import { tellSlack } from "network/apis";
import { toast } from "react-toastify"
import Create from "components/custom/create";


export default function ManageAssets(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()
    const navigate = useNavigate()

    async function loadFunc(page) {
        const sett = await network.get(`${config.base_path}/api/dashboard/asset/list/${page}?creator_id=${params.user_id}`)
        setHasMore(sett.data.payload.length !== 0)
        setItems(sett.data.payload)
    }


    function Assets(props) {
        return <Grid item xs={12}>
            <Card style={{ margin: "8px", padding: "8px" }}>
                <Grid container direction={"row"}>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Grid item xs={12}>name: {props.title}</Grid>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Grid item xs={12}>
                            <ButtonGroup>
                                <Button variant={"outlined"} onClick={() => navigate(`/sync/artists/${params.user_id}/assets/${props._id}/1`)}>
                                    Tags
                                </Button>
                                <Button variant={"outlined"} color={"success"} onClick={() => setEditObject(props)}>Edit</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    async function handleClick(mainData, diff) {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/asset/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited Successfully", { autoClose: 1000 })
    }

    return <BasePage>
        <Grid display={"flex"}>
            <Create id={''} title={'Sync Asset'} slug={'asset'}/>
        </Grid>
        <CDataTable
            pageUrl={`/sync/artists/${params.user_id}/assets/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <Assets key={index} {...a} />)} />
        {!!editObject && <EditFormDrawer onSave={handleClick} onClose={() => setEditObject(null)} open={!!editObject}
            schema={editObject} />}
    </BasePage>
}

