import React, {useState} from 'react';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import BatchCoupon from '../../components/batch-coupon'
import BasePage from "../../components/BasePage";
import {ButtonGroup} from "@mui/material";
import SingleCoupon from "../../components/single-coupon";


const TYPE_SINGLE_COUPON = "addSingleCoupon"
const TYPE_BATCH_COUPON = "addCouponBatch"

function Coupons(props) {

    const [couponTypeToCreate, setCouponTypeToCreate] = useState(TYPE_SINGLE_COUPON)

    return <BasePage>
        <ButtonGroup fullWidth>
            <Button
                onClick={() => setCouponTypeToCreate(TYPE_SINGLE_COUPON)}
                variant="contained"
                color={couponTypeToCreate === TYPE_SINGLE_COUPON ? "primary" : "secondary"}
                disabled={couponTypeToCreate === TYPE_SINGLE_COUPON}>
                <AddCircleIcon/> &nbsp;Add Single Coupon
            </Button>
            <Button
                variant="contained"
                onClick={() => setCouponTypeToCreate(TYPE_BATCH_COUPON)}
                color={couponTypeToCreate === TYPE_BATCH_COUPON ? "primary" : "secondary"}
                disabled={couponTypeToCreate === TYPE_BATCH_COUPON}
            >
                <DynamicFeedIcon/>&nbsp;Add Coupon Batch
            </Button>
        </ButtonGroup>
        {couponTypeToCreate === TYPE_SINGLE_COUPON && <SingleCoupon/>}
        {couponTypeToCreate === TYPE_BATCH_COUPON && <BatchCoupon/>}
    </BasePage>
}

export default Coupons
