import React, { useState } from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import { useParams } from "react-router-dom";




export default function ListDemoUser(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const params = useParams()

    async function loadFunc(page) {
        const demo = await network.get(`${config.base_path}/api/dashboard/unluclass/demos/${params.demo_id}/bookings?page=${page}`)
        setHasMore(demo.data.payload.length !== 0)
        setItems(demo.data.payload)
    }
    function DemoClass(props) {
        return <Grid item xs={12}>
            <Card style={{ margin: "8px", padding: "8px" }}>
                <Grid container direction={"row"}>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Grid item xs={12}>Name: {props.user?.full_name}</Grid>
                        <Grid item xs={12}>UserName: {props.user.username}</Grid>
                        <Grid item xs={12}>Email: {props.user.email}</Grid>
                        <Grid item xs={12}>Phone: {props.user.phone_number}</Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }


    return <BasePage>
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/demo/list/${params.demo_id}/booking/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <DemoClass key={index} {...a} />)} />
    </BasePage>
}
