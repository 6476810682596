import React, { useState, useEffect } from 'react';
import { Button, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import config from "../../config";
import network from "../../network";




const useStyles = makeStyles({
    formLabel: {

        marginTop: "10px",
        color: "#191237",
        fontFamily: "Source Sans Pro",
        fontSize: "1.1rem"

    },
    textField: {
        width: '22ch',
    },

    submitBtn: {
        marginTop: "40px",
        width: "100%"

    }
});

const CreateSpaceForm = (props) => {
    const [spaceName, setSpaceName] = useState("")
    const [spaceDesc, setSpaceDesc] = useState("")
    const [ordering, setOrdering] = useState("")

    const populateSpaceData = () => {
        setSpaceName(props.formData.title)
        setSpaceDesc(props.formData.description)
        setOrdering(props.formData.ordering)
    }


    useEffect(() => {
        if (props.formData) { populateSpaceData() }
    }, [])

    const handleSubmit = e => {


        if (spaceName === "" || spaceName == null) {
            alert("Enter Space Name")
            return

        }
        else if (spaceDesc === "" || spaceDesc == null) {
            alert("Enter Space  Description")
            return
        }


        if (props.formTypeEdit) {


            network.post(config.base_path + `/api/community/dashboard/spaces/${props.formId}`,
                { title: spaceName, description: spaceDesc, ordering: ordering },
            )
                .then((response) => {
                    if (response.data.statusCode === 0) {
                        alert(spaceName + " space edited successfully!")
                        window.location.reload();
                    }
                    else {
                        alert(response.data.message)
                    }
                })
                .catch(err => console.log(err));
        }


        else {

            var generatedSlug = spaceName?.toLowerCase().replace(/[^a-z0-9\s]/g, "").split(" ").join("_")


            network.post(config.base_path + `/api/community/dashboard/spaces`,
                { title: spaceName, description: spaceDesc, slug: generatedSlug },
            )
                .then((response) => {
                    if (response.data.statusCode === 0) {
                        alert(spaceName + " space created successfully!")
                        window.location.reload();
                    }
                    else {
                        alert(response.data.message)
                    }
                })
                .catch(err => console.log(err));
        }

    }

    const classes = useStyles();
    return (

        <Paper style={{ padding: "60px 20px", maxWidth: "500px", height: "100%" }}>
            <Grid>
                {!props.formTypeEdit && <h4>Create new space</h4>}
                {props.formTypeEdit && <h4>Edit space</h4>}
            </Grid>


            <Grid container direction='row' spacing={0} alignItems="center" >
                <Grid item xs={4}   >

                    <Typography className={classes.formLabel}> Space Name </Typography>
                </Grid>
                <Grid item xs={8}  >
                    <TextField
                        className={classes.textField}
                        required id="standard-required" label="Enter Space Name"
                        onChange={(e: any) => setSpaceName(e.target.value)}
                        value={spaceName}
                    />
                </Grid>

                <Grid item xs={4}  >
                    <Typography className={classes.formLabel}>
                        Space Description
                    </Typography>

                </Grid>

                <Grid item xs={8}  >
                    <TextField
                        className={classes.textField}
                        required id="standard-required" label="Enter Space Description"
                        onChange={(e: any) => setSpaceDesc(e.target.value)}
                        value={spaceDesc}
                    />
                </Grid>

                {props.formTypeEdit && <>  <Grid item xs={4}  >
                    <Typography className={classes.formLabel}>
                        Space Ordering
                    </Typography>

                </Grid>

                    <Grid item xs={8}  >
                        <TextField
                            className={classes.textField}
                            required id="standard-required" label="Enter Space Ordering"
                            onChange={(e: any) => setOrdering(e.target.value)}
                            value={ordering}
                        />
                    </Grid>
                </>
                }
            </Grid>

            <Button className={classes.submitBtn} variant="contained" color="primary"  onClick={handleSubmit}>Submit</Button>
        </Paper>

    )
}

export default CreateSpaceForm
