import React from "react"
import ListItem  from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Typography  from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";


export default function MenuGenerator(props) {
    const { data } = props
    let navigate = useNavigate();

    function onMenuItemClick(menuItem: any) {
        return navigate(menuItem.url)
    }

    return <div style={{ marginTop: '25px' }}>
        {Object.keys(data)
            .map((submenu, index) => (<div key={submenu}>
                <Accordion variant={"outlined"} >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant={"subtitle1"}>{submenu}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ borderRadius: "4px", background: "white" }}>
                        {data[submenu].map((submenuItem: any) => <ListItem
                            onClick={() => onMenuItemClick(submenuItem)}
                            key={submenuItem.title}>
                            <ListItemIcon>{submenuItem.icon}</ListItemIcon>
                            <ListItemText style={{ cursor: 'pointer' }} primary={submenuItem.title} />
                        </ListItem>)}
                    </AccordionDetails>
                </Accordion></div>))
        }
    </div>
}
