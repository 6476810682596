import {useEffect, useState} from "react";
import {ILayer, IRegion} from "../../components/experiment/audio-editor/types";
import {FileUploader} from "react-drag-drop-files";
import Stack from "@mui/material/Stack";
import {generateGuid} from "../../components/experiment/audio-editor/utils";
import Box from "@mui/material/Box";
import {useAudioProvider} from "../../components/experiment/audio-editor/audio-provider";
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from "@mui/material/CircularProgress";

const CStack: any = Stack

export default function AudioEditor() {
    const {dispatcher, song} = useAudioProvider()

    const onTrackAdded = async (file) => {

        const layer_id = generateGuid()
        const layer = {
            version: Date.now().toString(),
            song_id: song.song_id,
            layer_id,
            audio_info: {volume: 0},
            deleted: false,
            end: 0,
            ordering: 0,
            start: 0,
            sub_type: 0,
            title: "Layer 0",
            type: 0,
            user_id: "anuragdalia",
            regions: []
        }

        dispatcher({type: "add_layer", data: {layer}})
        dispatcher({type: "add_region", data: {layer, file}})
    };

    useEffect(() => {
        dispatcher({
            type: "set_song",
            data: {
                song: {
                    song_id: generateGuid(),
                    type: 0,
                    sub_type: 0,
                    user_id: "anuragdalia",
                    title: "New song",
                    duration: 0,
                    deleted: false,
                    published: false,
                    layers: []
                }
            }
        })
    }, [])

    if (!song)
        return <CircularProgress/>

    return <Stack key={song.version} spacing={2} justifyContent={"center"} alignItems={"center"}>
        <Stack sx={{width: "100vw"}} direction={"row"}>
            <Stack flexShrink={0} sx={{width: "20vw"}}>
                <Box flexShrink={0} sx={{height: "36px", width: "100%"}}/>
                {song.layers.map((layer, index) => <Box flexShrink={0} sx={{height: "128px"}} key={`${layer.version}_meta`}>
                    Track {layer.title} controls
                </Box>)}
            </Stack>
            <Stack>
                <Box sx={{height: "36px"}}>
                    <svg width="1440" height="36">
                        <pattern id="dots" x="0" y="0" width="42" height="3200"
                                 patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" height="3200" width='1'/>
                        </pattern>
                        <rect x="0" y="0" width="3000%" height="3200" fill="url(#dots)"
                              transform="scale(0.2)"/>
                    </svg>
                </Box>
                {song.layers.map((layer, index) => <Layer
                    key={layer.version}
                    layer={layer}/>)}
            </Stack>
        </Stack>
        <FileUploader handleChange={onTrackAdded} name="file" types={["MP3", "WAV"]}/>
    </Stack>;
}

function Layer(props: { layer: ILayer }) {
    const {dispatcher} = useAudioProvider()

    return <Stack flexShrink={0} sx={{
        width: "100%",
        height: "128px",
        border: "1px solid black",
        marginBottom: "8px",
        backgroundColor: "red",
    }}
                  direction={"row"}>
        {props.layer?.regions?.map((region, index) => <Region
            key={region.version}
            region={region}/>)}
        <FileUploader handleChange={(file) => dispatcher({type: "add_region", data: {layer: props.layer, file}})}
                      name="file"
                      types={["MP3", "WAV"]}/>
    </Stack>
}

function Region(props: { region: IRegion }) {
    const {dispatcher, getSampleMeta, zoom} = useAudioProvider()
    const [sampleMeta, setSampleMeta] = useState(null)

    useEffect(() => {
        getSampleMeta(props.region.sample_id)
            .then((meta) => setSampleMeta(meta))
    }, [props.region])

    if (!sampleMeta)
        return null

    return <Box flexShrink={0} sx={{width: `${sampleMeta.duration * zoom}px`, backgroundColor: "green", margin: "8px"}}>
        <CancelIcon onClick={() => dispatcher({type: "remove_region", data: {region: props.region}})}/>
    </Box>
}