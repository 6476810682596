import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import CreateSpaceForm from '../../components/community/CreateSpaceForm'
import RightDrawer from '../../components/custom/RightDrawer';
import CreateGroupForm from '../../components/community/CreateGroupForm'
import SpaceExpandableComponent from '../../components/expandSpace';
import network from "../../network";
import config from '../../config';
import BasePage from "../../components/BasePage";


const useStyles = makeStyles({
    content: {
        marginTop: "80px",
        marginLeft: "240px",
    },
    table: {
        minWidth: 550,
        fontFamily: "Source Sans Pro",
    },

    pageHeading: {
        color: "#191237",
        fontFamily: "Source Sans Pro",
        fontSize: "1.6rem",
        fontWeight: "700"

    }
    ,
    tableHeading: {
        fontWeight: "700",
        fontSize: "1.1rem"
    },

    btn:
        {
            backgroundColor: "#E5E5E5",
            height: "58px",
            borderRadius: "8px",
            margin: "7px",
        }
});

const CommunitySpace = (classes) => {
    classes = useStyles();
    const [createGroupDrawer, setCreateGroupDrawer] = useState(false);
    const [createSpaceDrawer, setCreateSpaceDrawer] = useState(false);
    const [spaceData, setSpaceData] = useState([])


    const populateSpaceData = async () => {

        network.get(`${config.base_path}/api/community/dashboard/spaces`)
            .then((response) => setSpaceData(response.data.payload))
    }


    useEffect(() => {
        populateSpaceData()
    }, [])

    return (
        <BasePage>
            <Typography className={classes.pageHeading}>Spaces & Groups</Typography>
            <Grid container direction="row" spacing={10} alignItems='center'>
                <Grid item xs={7}>
                    <TextField label="Search space/group" margin="normal" variant="outlined"/>
                </Grid>
                <Grid item xs={5}>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Button className={classes.btn} variant="outlined"
                                onClick={() => setCreateGroupDrawer(true)}>+ Create new group</Button>
                        <RightDrawer drawerState={createGroupDrawer} setDrawerState={setCreateGroupDrawer}
                                     formComponent={CreateGroupForm}/>

                        <Button className={classes.btn} variant="outlined"
                                onClick={() => setCreateSpaceDrawer(true)}>+ Create new space</Button>
                        <RightDrawer drawerState={createSpaceDrawer} setDrawerState={setCreateSpaceDrawer}
                                     formComponent={CreateSpaceForm}/>


                    </Grid>
                </Grid>
            </Grid>
            {spaceData.map((curEle, index) => <SpaceExpandableComponent key={index} spaceData={curEle}/>)}
        </BasePage>
    )
}

export default CommunitySpace
