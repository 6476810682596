import config from "../config";
import network from "./index";


export type StandardResponse<T> = {
    status: number,
    message: string,
    payload: T
}
export type AddCelebResponseType = {
    id: string
}
export type AddCelebRequestType = {
    celeb_name: string,
    contact_details: string,
    password: string,
}

export type AccessToken = {
    access_token: string
}

// export const add_celeb = (userid, payload: AddCelebRequestType): Promise<AxiosResponse<StandardResponse<AddCelebResponseType>>> => network.post<StandardResponse<AddCelebResponseType>>(`${config.base_path}/api/user/register`, payload)

export const add_celeb = (userid, payload: AddCelebRequestType): Promise<any> => network.post(`${config.base_path}/api/user/register`, payload)


export default {
    add_celeb
}

export const tellSlack = (body: any) => network.post(`${config.base_path}/logger`, {
    message: body,
    username: "internal-dashboard"
})
