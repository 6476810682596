import { Button } from "@mui/material"
import { useState } from "react"
import EditFormDrawer from "components/custom/EditFormDrawer"
import network from "network"
import config from "config"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
export default function CreateAssignmentsQuestion() {
    const [questionObject, setQuestionObject] = useState(null)
    const params = useParams()
    async function loadFunc() {
        const question = await network.get(`${config.base_path}/api/dashboard/assignment_questions/sample`)
        setQuestionObject(question.data.payload)
    }
    const handleSubmit = async (mainData) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/assignment_questions/create`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success('Question Created', { autoClose: 2000 })
        }
        else
            toast.error(res.data.message, { autoClose: 2000 })
    }
    return <>
        <Button variant={"outlined"} onClick={() => loadFunc()}>+Create New Assignment Questions</Button>
        {!!questionObject && <EditFormDrawer id={'assignment_question'} title='Create Assignment Question' onClose={() => setQuestionObject(null)} open={!!questionObject} schema={questionObject} onSave={handleSubmit} />}
    </>
}