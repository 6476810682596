import React from 'react';
import Grid from "@mui/material/Grid";
import tsv2json from "../../utils/tsv2csv";
import network from "../../network";
import config from "../../config";
import Button from "@mui/material/Button";
import BasePage from "../../components/BasePage";
import {withNavigation} from "../../utils";

const fileDownload = require('js-file-download');

const useStyles = (theme) => ({
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    root: {
        display: 'flex',
        background: "#F0F0F7",
    },

    progress: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    toolbar: theme.mixins.toolbar,

    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        paddingLeft: "4vw"
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    loading: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
});

const expected_headers = ["Bkg_Id", "Trans_Id", "Bkg_Commit", "Trans_Date", "Cinema_Name", "Event_Name", "Show_Date_Disp", "Ticketwise_Qty", "Seat_Info", "Ticket_Qty", "Ticket_Amt", "Item_Desc", "ItemWise_Qty", "ItemWise_Amt", "Inv_Qty", "Inv_Amt", "Additional_Desc", "Add_strAmt", "Add_Charges", "Cust_Details"];

class BmsClassRunner extends React.Component<any,any> {
    constructor(props) {
        super(props);
        this.state = {
            raw_data: null,
            json_data: null,
            json_as_table: null,
            can_show_button: false
        }
    }


    processInput(e) {
        e.preventDefault();
        this.processInputInternal(e.target.value)
    }

    processInputInternal(tsvalue) {
        let jsonvalue: { [key: string]: string }[]
        let json_as_table
        let can_show_button = false
        try {
            jsonvalue = tsv2json(tsvalue, (headersArr) => {
                if (headersArr[0].split(",").every((val) => expected_headers.indexOf(val) >= 0)) {
                    return headersArr;
                } else {
                    throw new Error("Headers not in expected shape");
                }
            })

            json_as_table = jsonvalue.map((obj: {}, index) => {
                return <tr key={"x" + index}>{Object.entries(obj).map((obj => <td
                    key={obj[0] + "_" + obj[1]}>{obj[1]}</td>))}</tr>
            })

            can_show_button = true
        } catch (e) {
            jsonvalue = [{error: `Couldn't parse TSV [${e.message}]`}]
            json_as_table = "ERROR"
        }

        this.setState({
            raw_data: tsvalue,
            json_data: jsonvalue,
            json_as_table: json_as_table,
            can_show_button: can_show_button
        })
    }

    onChooseFile(event, onLoadFileHandler) {
        if (typeof window.FileReader !== 'function')
            throw new Error("The file API isn't supported on this browser.");
        let input = event.target;
        if (!input)
            throw new Error("The browser does not properly implement the event object");
        if (!input.files)
            throw new Error("This browser does not support the `files` property of the file input.");
        if (!input.files[0])
            return undefined;
        let file = input.files[0];
        let fr = new FileReader();
        fr.onload = onLoadFileHandler;
        fr.readAsText(file);
    }

    submitBms() {
        const k = this
        return network.post(`${config.base_path}/api/dashboard/bms/class/runner`, k.state.json_data)
            .then(r => {
                if (r.data.statusCode === 0) {
                    fileDownload(r.data.payload, 'runner.csv')
                } else {
                    alert("There is an error in the request: " + r.data.message)
                }
            })
            .catch(alert)
    }

    render() {
        const classes = this.props.classes
        return <BasePage>
            <Grid container style={{marginTop: "2vh"}}/>
            <Grid item xs={4}>
                <input type='file'
                       onChange={(event) => this.onChooseFile(event, (data) => this.processInputInternal(data.target.result))}/>
            </Grid>
            <Grid container>
                <table style={{"borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>
                    <thead>
                    <tr>
                        {expected_headers.map(header => <th key={header} style={{
                            "borderWidth": "1px",
                            'borderColor': "#aaaaaa",
                            'borderStyle': 'solid'
                        }}>{header}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.json_data && this.state.json_data.map((obj, index) => {
                        return <tr key={index}>{Object.entries(obj).map((obj => <td
                            key={obj[0] + "_" + obj[1]}>{obj[1]}</td>))}</tr>
                    })}</tbody>
                </table>
            </Grid>
            {this.state.can_show_button && <Button type="button"
                                                   variant="contained"
                                                   onClick={() => this.submitBms()}>Submit</Button>}
        </BasePage>
    }
}

export default withNavigation(BmsClassRunner)
