import {useEffect, useRef, useState} from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {styled} from "@mui/material";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import {ScriptNode, ScriptPage, WritingMode} from "./DataTypes";
import {makeid} from "../../../pages/experiments/script-editor";
import {useEditor} from "./EditorProvider";


const MainEditText: any = styled(Box)(({theme}) => ({
    background: "#fafafa",
    boxShadow: "0 0 10px rgba(0,0,0,0.3), 0 0 300px 25px rgba(222,198,122,0.7) inset",
    margin: "0 auto",
    padding: "24px",
    fontFamily: "Courier Prime",
    border: "1px solid black",
    width: "100%",
    overflow: "scroll",
    "& .scene": ({
        "&::before": {
            content: "counter(css-counter) '. '",
            fontWeight: 600
        },
        counterIncrement: "css-counter 1",
        fontWeight: 600,
        textTransform: "uppercase",
        background: "#f5f5f5",
        color: "#000"
    }),
    "& .slugline": ({
        textTransform: "uppercase",
        fontWeight: 600,
        color: "#000"
    }),
    "& .action": ({
        textTransform: "uppercase",
        lineHeight: "2",
    }),
    "& .character": ({
        textTransform: "uppercase",
        textAlign: "center",
    }),
    "& .casting": ({
        "&::before": {
            content: "'Casting:'",
            fontWeight: 600
        },
        textTransform: "uppercase",
        textAlign: "left",
    }),
    "& .parenthetical": ({
        textAlign: "center",
        "&::before": {
            content: "'(\\00a0 '",
        },
        "&::after": {
            content: "'\\00a0)'"
        },
    }),
    "& .dialogue": ({
        textAlign: "center",
    }),
    "& .transition": ({
        textAlign: "right",
    }),
    "& .general": ({}),
    "& p:empty:not(:focus)::before": {
        content: 'attr(data-placeholder)',
        color: "rgba(0,0,0,0.36)"
    }
}))
const CustomBox2 = styled(Box)(({theme}) => ({
    "@media only print": {
        display: "none"
    }
}))

const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time))

export default function PageBox(props: {
    data?: ScriptPage,
}) {
    const mainContainer = useRef<HTMLDivElement | null>(null);
    const {
        focus,
        storeFocus,
        updateFocus, moveFocus,
        restoreFocus,
        setCurrentWritingMode,
        changeWritingMode,
        setChangeWritingMode,
        getNodeInfoOrNullFromNode,
        createP,
        getNextModeOnEnterKey,
        getCaretCoordinates,
        saveData,
        setSaveData
    } = useEditor()

    const content = useRef<ScriptNode[]>(props.data || [
        {type: WritingMode.Scene, data: "Scene", id: makeid(6)},
        {type: WritingMode.Slugline, data: "Slugline", id: makeid(6)},
        {type: WritingMode.Action, data: "Action", id: makeid(6)},
        {type: WritingMode.Character, data: "Character", id: makeid(6)},
        {type: WritingMode.Casting, data: "Casting", id: makeid(6)},
        {type: WritingMode.Parenthetical, data: "Parenthetical", id: makeid(6)},
        {type: WritingMode.Dialogue, data: "Dialogue", id: makeid(6)},
        {type: WritingMode.Transition, data: "Transition", id: makeid(6)},
        {type: WritingMode.General, data: "General", id: makeid(6)},
    ])
    const [version, updateVersion] = useState(Date.now())
    const [contextMenu, setContextMenu] = useState<[number, number, WritingMode, string] | null>(null)
    const [actionLog, setActionLog] = useState<{ type: "insert" | "delete" }[]>([])


    useEffect(() => {
        if (props.data) {
            content.current = props.data
            updateVersion(Date.now())
        }
    }, [props.data])

    useEffect(() => {
        if (changeWritingMode !== null) {
            setWritingMode(changeWritingMode)
            setChangeWritingMode(null)
        }
    }, [changeWritingMode])

    useEffect(() => {
        if (saveData) {
            save()
            setSaveData(!saveData)
        }
    }, [saveData])


    function renderNode(node: ScriptNode, index: number) {
        switch (node.type) {
            case WritingMode.Scene:
                return createP("scene", node.data, node.id)
            case WritingMode.Slugline:
                return createP("slugline", node.data, node.id)
            case WritingMode.Action:
                return createP("action", node.data, node.id)
            case WritingMode.Character:
                return createP("character", node.data, node.id)
            case WritingMode.Casting:
                return createP("casting", node.data, node.id)
            case WritingMode.Parenthetical:
                return createP("parenthetical", node.data, node.id)
            case WritingMode.Dialogue:
                return createP("dialogue", node.data, node.id)
            case WritingMode.Transition:
                return createP("transition", node.data, node.id)
            case WritingMode.General:
                return createP("general", node.data, node.id)
            default:
                return createP("unknown", "Unkown Node", "UnknownNodeId")
        }
    }


    function updateNode(id, type) {
        return domToContent(mainContainer.current.children)
            ?.reduce((acc, item, index) => acc.concat([(item.id === id) ? Object.assign(item, {type}) : item]), [])
    }

    function insertNode({
                            type,
                            data,
                            id
                        }, atIndex: number | string = -1, positionModifier: "before" | "after" = "before") {
        const content = {current: domToContent(mainContainer.current.children)}
        if (typeof atIndex === "number") {
            if (positionModifier === "after")
                throw new Error("Cannot insert node at index with a position modifier")
            const index = atIndex < 0 ? content.current.length - atIndex : atIndex
            if (index > content.current.length || index < 0)
                throw new Error(`atIndexOutOfBounds atIndex:${index} computedIndex:${index}`)

            return [
                ...(content.current?.slice(0, index + 1)),
                {type: type, data: data, id: id},
                ...(content.current?.slice(index + 1))
            ]
        } else {
            return domToContent(mainContainer.current.children)
                ?.reduce((acc, item, index) => {
                    if (item.id === atIndex) {
                        const final = []
                        if (positionModifier === "before") {
                            final.push({type: type, data: data, id: id})
                        }

                        final.push(item)

                        if (positionModifier === "after") {
                            final.push({type: type, data: data, id: id})
                        }

                        return acc.concat(final);
                    } else {
                        return acc.concat([item]);
                    }
                }, [])
        }

    }

    function removeNode(id: string) {
        return domToContent(mainContainer.current.children)
            ?.reduce((acc, item, index) => acc.concat([(item.id === id) ? null : item]), [])

    }

    function setWritingMode(mode) {
        console.log("setWritingMode")
        const selection = window.getSelection()
        if (selection.type.toLowerCase() === "caret") {
            const info = getNodeInfoOrNullFromNode(selection.anchorNode)
            if (info) {
                storeFocus()
                content.current = updateNode(info.id, mode)
                updateFocus(info.id)
            }
        } else {
            const anchorInfo = getNodeInfoOrNullFromNode(selection.anchorNode)
            const focusInfo = getNodeInfoOrNullFromNode(selection.focusNode)
            //create a new node out of selected portions
        }
        setCurrentWritingMode(mode)
    }

    function save() {
        console.log("save")
        if (mainContainer?.current?.children?.length > 0)
            content.current = domToContent(mainContainer.current.children)
        updateVersion(Date.now())
    }

    function domToContent(children: HTMLCollection) {
        const newContent: { type: WritingMode, data: any, id: string }[] = []
        for (let i = 0; i < children.length; i++) {
            const child = children[i]
            const info = getNodeInfoOrNullFromNode(child)
            if (info) {
                newContent.push({type: info.type as WritingMode, data: (child as HTMLElement).innerText, id: info.id})
            }
        }
        return newContent
    }


    useEffect(() => {
        function onKeyUp(this: HTMLDivElement, event: KeyboardEvent) {
            const selection = document.getSelection()
            if (selection.type.toLowerCase() === "caret") {
                const info = getNodeInfoOrNullFromNode(selection.anchorNode)
                if (info) {
                    setCurrentWritingMode(info.type)
                }
            }
        }


        function onKeyDown(this: HTMLDivElement, event: KeyboardEvent) {
            const selection = document.getSelection()
            if (selection.type.toLowerCase() === "caret") {
                const info = getNodeInfoOrNullFromNode(selection.anchorNode)
                if (info) {
                    if (event.key === "Enter") {
                        event.preventDefault()
                        const newMode = getNextModeOnEnterKey(info.type)
                        storeFocus()
                        let newId = makeid(6)
                        content.current = insertNode({type: newMode, data: null, id: newId}, info.id, "after")
                        moveFocus(newId)
                        updateVersion(Date.now())
                    } else if (event.key === "i" && event.metaKey) {
                        event.preventDefault()
                        storeFocus()
                        const coordinates = getCaretCoordinates()
                        setContextMenu([coordinates.x + 2, coordinates.y - 6, info.type, info.id])
                    }
                }
            }
        }

        function onBeforeInput(this: HTMLDivElement, event: InputEvent) {
        }

        function onMouseDown(this: HTMLDivElement, event: MouseEvent) {
            const info = getNodeInfoOrNullFromNode(event.target)
            if (info) {
                setCurrentWritingMode(info.type)
            }
        }

        function onContextMenu(this: HTMLDivElement, event: MouseEvent) {
            const info = getNodeInfoOrNullFromNode(event.target)
            if (info) {
                event.preventDefault();
                storeFocus()
                setContextMenu([event.clientX + 2, event.clientY - 6, info.type, info.id])
            }
        }

        mainContainer.current?.addEventListener("keyup", onKeyUp);
        mainContainer.current?.addEventListener("keydown", onKeyDown);
        mainContainer.current?.addEventListener("beforeinput", onBeforeInput)
        mainContainer.current?.addEventListener("mousedown", onMouseDown)
        mainContainer.current?.addEventListener("contextmenu", onContextMenu)


        return () => {
            mainContainer.current?.removeEventListener("keyup", onKeyUp)
            mainContainer.current?.removeEventListener("keydown", onKeyDown)
            mainContainer.current?.removeEventListener("beforeinput", onBeforeInput)
            mainContainer.current?.removeEventListener("mousedown", onMouseDown)
            mainContainer.current?.removeEventListener("contextmenu", onContextMenu)

        }
    }, [mainContainer.current])

    useEffect(() => {
        if (!contextMenu)
            restoreFocus()
    }, [contextMenu])

    useEffect(() => {
        mainContainer.current?.append(...content.current?.map(renderNode))
        restoreFocus()
        return () => {

            if (mainContainer.current)
                mainContainer.current.innerHTML = ""

        }
    }, [mainContainer.current, content.current])

    useEffect(() => {
        console.log("Content updated:", content.current?.length);
        console.log("Focus:", focus.current);
    }, [content.current, focus.current])

    return <>
        <Stack sx={{direction: {md: "row", sx: "column"}}} spacing={1} justifyContent={"center"} alignItems={"center"}>
            <Stack spacing={1} justifyContent={"center"} alignItems={"center"} sx={{padding: "8px"}}>
                <Container maxWidth={"md"} sx={{flexGrow: 1, width: "100%"}}>
                    <MainEditText version={version} suppressContentEditableWarning ref={mainContainer}
                                  contentEditable={true}/>
                </Container>
            </Stack>
            <CustomBox2 justifyContent={"center"} alignItems={"center"}>
                {content.current
                    ?.map((a, index) => Object.assign(a, {ogIndex: index}))
                    ?.filter(a => a.type === WritingMode.Scene)
                    ?.map((scene, index) => <Chip
                        style={{maxWidth: '180px'}}
                        onClick={() => {
                            console.log(`#scene_${scene.ogIndex}`, document.getElementById(`scene_${scene.ogIndex}`))
                            document.getElementById(`scene_${scene.ogIndex}`)?.scrollIntoView({behavior: "smooth"});
                        }}
                        key={index}
                        label={scene.data}/>)}
            </CustomBox2>
        </Stack>
        <ContextMenu contextMenu={contextMenu} onClose={() => setContextMenu(null)}/>
    </>
}

function ContextMenu(props: { contextMenu: [number, number, WritingMode, string] | null, onClose?: any }) {

    const handleClose = (action: string) => {
        props.onClose?.();
    }

    return <Menu
        open={props.contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={props.contextMenu !== null
            ? {top: props.contextMenu[1], left: props.contextMenu[0]}
            : undefined
        }
    >
        {props.contextMenu && <MenuItem disabled>{props.contextMenu[2]}@ #{props.contextMenu[3]}</MenuItem>}
        <MenuItem onClick={() => handleClose("copy")}>Copy</MenuItem>
        <MenuItem onClick={() => handleClose("print")}>Print</MenuItem>
        <MenuItem onClick={() => handleClose("highlight")}>Highlight</MenuItem>
        <MenuItem onClick={() => handleClose("email")}>Email</MenuItem>
    </Menu>
}
