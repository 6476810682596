import React, { useEffect, useState } from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useNavigate, useParams } from "react-router-dom";
import CreateUnluLive from "../create";
import { tellSlack } from "network/apis";
import { toast } from "react-toastify"

export default function UnluLive(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams();
    const navigate = useNavigate()

    async function loadFunc(page) {
        const unlulives = await network.get(`${config.base_path}/api/dashboard/meetings/list/${page}`)
        setHasMore(unlulives.data.payload.length !== 0)
        setItems(unlulives.data.payload)
    }

    const onSave = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/meetings/update?_id=${mainData.id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited Successfully", { autoClose: 1000 })
    }

    function UnluLive(props) {
        return <Grid item xs={12}>
            <Card style={{ margin: "8px", padding: "8px" }}>
                <Grid container direction={"row"}>
                    <Grid item style={{ flexGrow: 1, color: `${props.deleted ? 'red' : ''}` }}>
                        <Grid item xs={12}>Title: <b>{props.title}</b></Grid>
                        <Grid item xs={12}>Slug: {props.slug}</Grid>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Button variant={"outlined"} color={"secondary"} onClick={() => navigate(`/community/live/${props.id}/acl/1`)}>access control list</Button>
                        <Button variant={"outlined"} color={"success"} onClick={() => setEditObject(props)}>Edit</Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    return <BasePage>
        <CreateUnluLive />
        <CDataTable
            pageUrl={"/community/live/list/"}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
        >{items.map((a, index) => <UnluLive key={index} {...a} />)}</CDataTable>
        {!!editObject && <EditFormDrawer
            id='unlulive'
            title={"Edit unlulive"}
            onSave={onSave}
            onClose={() => setEditObject(null)}
            open={!!editObject}
            schema={editObject} />}
    </BasePage>
}
