import React, { useState } from "react";
import network from "network";
import config from "config";
import Button from "@mui/material/Button";
import EditFormDrawer from "components/custom/EditFormDrawer";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";


export default function CreateDemoClass(props) {
    const [demoObject, setdemoObject] = useState(null)
    const params = useParams()

    async function loadFunc() {
        const module = await network.get(`${config.base_path}/api/dashboard/demos/sample`)
        setdemoObject(module.data.payload)
    }
    const handleSubmit = async (mainData, diff, diffPath) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/demos/create`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success("New Demo Class created successfully")
        }
        else
        toast.error(res.data.message , {autoClose:2000})
    }
    return <>
        <Button variant={"outlined"} onClick={() => loadFunc()}>+Add New Demo Class</Button>
        {!!demoObject && <EditFormDrawer onClose={() => setdemoObject(null)} open={!!demoObject} schema={demoObject} onSave={handleSubmit} />}
    </>
}
