import React, {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import {Select} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";

function MultiChipSelectV2({defaultItems, preSelectedItems, onSelectedItemsChanged, label}) {//defaultItems = {id:string,label:string}[]
    preSelectedItems = preSelectedItems || []
    const [items, setItems] = useState(defaultItems.filter(a => !preSelectedItems.find(b => b.id === a.id)))
    const [selectedItems, setSelectedItems] = useState(preSelectedItems)

    useEffect(() => {
        if (selectedItems && onSelectedItemsChanged)
            onSelectedItemsChanged(selectedItems)
    }, [selectedItems])

    function onRemoveItem(item) {
        setItems(items => items.concat([item]))
        setSelectedItems(items => items.filter(a => a.id !== item.id))
    }

    function onAddItem(item) {
        setSelectedItems(items => items.concat([item]))
        setItems(items => items.filter(a => a.id !== item.id))
    }

    const allItemSelected = items.length < 1
    return (<>
        <Box sx={{padding: "8px"}}>
            {selectedItems.map(item => (<Chip
                    sx={{margin: "4px"}}
                    key={item.id}
                    label={item.label}
                    deleteIcon={<CancelIcon/>}
                    onDelete={() => onRemoveItem(item)}
                    onClick={() => onRemoveItem(item)}
                />
            ))}
        </Box>
        <FormControl fullWidth>
            <InputLabel id="multi-chip-select-label">{label}</InputLabel>
            <Select
                disabled={allItemSelected}
                labelId="multi-chip-select-label"
                id="multi-chip-select"
                value={"default"}
                label="Coupon filter"
                onChange={(e: any) => onAddItem(items.find(a => a.id === e.target.value))}>
                <MenuItem value={"default"} disabled>Select</MenuItem>
                {items.map((item, index) => <MenuItem key={index} value={item.id}>{item.label}</MenuItem>)}
            </Select>
        </FormControl>
    </>);
}

export default MultiChipSelectV2;
