import React, {useEffect, useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import {useNavigate, useParams} from "react-router-dom";
import {tellSlack} from "network/apis";
import {toast} from "react-toastify"
import CreateUnluLiveAcl from "./create_acl";

export default function UnluASL() {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams();
    const navigate = useNavigate()

    async function loadFunc(page) {
        const unlulives = await network.get(`${config.base_path}/api/dashboard/meeting_acls/list/${page}?meeting_id=${params.meeting_id}`)
        setHasMore(unlulives.data.payload.length !== 0)
        setItems(unlulives.data.payload)
    }

    const onSave = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/meeting_acls/update?_id=${mainData.id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited Successfully", {autoClose: 1000})
    }

    const onDelete = async (item) => {
        const text = "Do you really want to delete it?";
        if (window.confirm(text) === true) {
            return await network.post(`${config.base_path}/api/dashboard/meeting_acls/delete?_id=${item.id}`, {})
                .then(() => window.location.reload())
        } else navigate(`/community/live/${item.id}/acl/1`)
    }

    function UnluLiveAcl(props) {
        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Grid container direction={"row"}>
                    <Grid item style={{flexGrow: 1}}>
                        <Grid item xs={12}>Resource_type: <b>{props.resource_type === 1 ? 'UNLUCLASS ENROLLMENT' : props.resource_type === 2 ? 'USER' : 'ALL USERS'}</b></Grid>
                        <Grid item xs={12}>Role: <b>{props.role === 0 ? 'ROLE_NOT_ALLOWED' : props.role === 1 ? 'ROLE_HOST' : 'ROLE_PARTICIPANT'}</b></Grid>
                    </Grid>
                    <Grid item>
                        <ButtonGroup>
                            <Button variant={"outlined"} color={"error"} onClick={() => onDelete(props)}>Delete</Button>
                            <Button variant={"outlined"} color={"success"}
                                    onClick={() => setEditObject(props)}>Edit</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    return <BasePage>
        <CreateUnluLiveAcl/>
        <CDataTable
            pageUrl={`/community/live/${params.meeting_id}/acl/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
        >{items.map((a, index) => <UnluLiveAcl key={index} {...a} />)}</CDataTable>
        {!!editObject && <EditFormDrawer
            id={'unlulive_acl'}
            title="Edit Unlulive ACL"
            onSave={onSave}
            onClose={() => setEditObject(null)}
            open={!!editObject}
            schema={editObject}/>}
    </BasePage>
}
