import React, {useState} from 'react'
import {Button, Paper} from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import network from "../../network";
import config from "../../config";
import qs from 'querystring'


const useStyles = makeStyles({
    formLabel: {

        marginTop: "10px",
        color: "#191237",
        fontFamily: "Source Sans Pro",
        fontSize: "1.1rem"

    },
    textField: {
        width: '22ch',
    },

    submitBtn: {
        marginTop: "40px",
        width: "100%"

    }
});


const AddMemberGroupForm = (props) => {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [addMemberId, setAddMemberId] = useState([]);
    const loading = open && options.length === 0;


    const populateSearchData = () => {
        let active = true;
        network.get(`${config.base_path}/api/community/search/members?search=${searchKeyword}&page=1&page_size=50`)
            .then((response) => {
                const results = (response.data.payload.results);
                if (active) {
                    setOptions(Object.keys(results).map((key) => results[key]));
                }
            })
            .catch(err => console.error(err))


        return () => {
            active = false;
        };
    }

    React.useEffect(() => {
        populateSearchData()

    }, [loading, searchKeyword]);


    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleChange = (e, value) => {
        setAddMemberId(value)
    }

    const handleSubmit = e => {

        const memberIds = Array.from(addMemberId, addMemberId => addMemberId.id);

        network.post(config.base_path + `/api/community/groups/${props.formId}/add_members`,
            {user_ids: memberIds},
        )
            .then((response) => {
                if (response.data.statusCode === 0) {
                    alert("Members added successfully!")
                    window.location.reload();
                } else {
                    alert(response.data.message)
                }
            })
            .catch(err => console.log(err));


    }


    const classes = useStyles();
    return (
        <Paper style={{padding: "60px 20px", maxWidth: "500px", height: "100%"}}>
            <Grid>
                <h4>Add members to {props.formData.title}</h4>
            </Grid>


            <Autocomplete
                multiple
                id="asynchronous-demo"
                style={{width: 400, marginTop: "20px"}}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleChange}
                getOptionLabel={(option) => option.full_name}
                options={options}
                loading={loading}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Add members to the group"
                        variant="outlined"
                        value={searchKeyword}
                        onChange={(e: any) => setSearchKeyword(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit"/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />


            <Button className={classes.submitBtn} variant="contained" color="primary" onClick={handleSubmit}>
                Add Members
            </Button>

        </Paper>
    );
}

export default AddMemberGroupForm
