import React, {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {styled} from "@mui/material";
import {useUser} from "../components/UserProvider";
import {useNavigate} from "react-router-dom";
import {PRODUCTION} from "../config";

const LoginButton = styled(Button)(({theme}) => ({
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: "#43425D",
    border: "1px",
    borderStyle: "solid",
    borderColor: "#43425D",
    borderRadius: "4px",
    opacity: 1,
}))

export default function LoginIn() {
    const [passwordHidden, setPasswordHidden] = useState(false)
    const [email, setEmail] = useState(false)
    const [password, setPassword] = useState(false)
    const user = useUser()
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault();
        user.login(email, password).then(() => navigate("/"));
    }

    if (user.isLoggedIn) {
        navigate("/")
        return <></>
    }

    return (
        <Grid container style={{height: "100vh"}}>
            <Grid item xs={false} sm={4} md={6} style={{
                backgroundImage: "url(/Login.png)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}/>
            <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
                <div style={{
                    marginTop: "300px",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <img src={"/assets/Layer.png"} alt="Unlu" style={{
                        backgroundPosition: "center"
                    }}/>

                    <Typography component="p" style={{
                        color: "#4D4F5C",
                        opacity: 0.5,
                        marginTop: "50px",
                        fontSize: "1.2rem",
                        fontFamily: "Source Sans Pro",
                    }}>
                        Welcome to the dashboard! Please login to your account.<br/>
                        Connecting to {PRODUCTION ? "Prod" : "Testing"}
                    </Typography>

                    <form noValidate onSubmit={e => handleSubmit(e)}>
                        <TextField
                            style={{
                                fontFamily: "Source Sans Pro",
                                color: "#43425D",
                                opacity: 1,
                                fontSize: "15px",
                            }}
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            name="email"
                            onChange={(e: any) => setEmail(e.target.value)}
                            id="email"
                            label={
                                <span
                                    style={{
                                        fontFamily: "Source Sans Pro",
                                        color: "#43425D",
                                        opacity: 1,
                                        fontSize: "15px",
                                    }}
                                >
                                            Username
                                        </span>
                            }
                            autoComplete="email"
                            autoFocus
                        />

                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            onChange={(e: any) => setPassword(e.target.value)}
                            label={<span style={{
                                fontFamily: "Source Sans Pro",
                                color: "#43425D",
                                opacity: 1,
                                fontSize: "15px",
                            }}>Password</span>}
                            type={passwordHidden ? "text" : "password"}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setPasswordHidden(!passwordHidden)}
                                        aria-label="toggle password visibility"
                                        size="large">
                                        {
                                            password ? <VisibilityOff/> : <Visibility/>
                                        }
                                    </IconButton>
                                </InputAdornment>)
                            }}
                        />

                        <LoginButton type="submit" fullWidth variant="contained" color="primary">
                            Sign In
                        </LoginButton>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}



