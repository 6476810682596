import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import {useNavigate, useParams} from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import {tellSlack} from "../../network/apis";
import {toast} from "react-toastify"
import Create from "components/custom/create";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function ListFellowship(props) {
    const params = useParams()
    const navigate = useNavigate()
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'))

    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)

    async function loadFunc(page) {
        const unluclasses = await network.get(`${config.base_path}/api/dashboard/unluclass/list/${page}?class_type=500`)
        setHasMore(unluclasses.data.payload.length !== 0)
        setItems(unluclasses.data.payload)
    }

    function Unluclass(props) {
        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px", overflowX: matchMobile ? 'scroll' : 'clip'}}>
                <Grid container direction={"row"}
                      style={{margin: "8px", padding: "8px"}}>
                    <Grid item style={{flexGrow: 1}}>
                        <Grid item xs={12}>Title: {props.title}</Grid>
                        <Grid item xs={12}>Slug: {props.slug}</Grid>
                        <Grid item xs={12}>Class Type: {props.class_type}</Grid>
                    </Grid>
                    <Grid item>
                        <Grid item xs={12}>
                            <ButtonGroup>
                                <Button variant="outlined"
                                        sx={{color: '#5053EC', borderColor: '#5053EC'}}
                                        onClick={() => navigate(`/fellowship/${props._id}/enrollments/list/1`)}>
                                    enrollments
                                </Button>
                                <Button variant={"outlined"}
                                        onClick={() => navigate(`/unluclass/${props._id}/episodes/1`)}>
                                    Episodes
                                </Button>
                                <Button variant={"outlined"} color={"success"}
                                        onClick={() => setEditObject(props)}>Edit</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    const handleSubmit = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/unluclass/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited Successfully", {autoClose: 1000})
    }

    return <BasePage>
        <Create id={"unluclass"} title={'UnluClass'} slug={'unluclass'}/>
        <CDataTable
            pageUrl={"/unluclass/"}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <Unluclass key={index} {...a} />)}/>
        {!!editObject && <EditFormDrawer onSave={handleSubmit} onClose={() => setEditObject(null)} open={!!editObject}
                                         schema={editObject}/>}
    </BasePage>
}
