import moment from "moment/moment";

export const convertDateAndTime = (givenDate) => {
    if (!givenDate) return "N/A";
    const date = moment(givenDate).format("MMMM DD, YYYY");
    const time = moment(givenDate).format("h:mm a");
    return `${date} | ${time}`;
};

export function convertDateDDMMYYYY(givenDate){
    if (!givenDate) return "N/A";
    return moment(givenDate).format("DD-MM-YYYY");
}