import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import {useNavigate, useParams} from "react-router-dom";
import CreateModules from "components/create-module";
import {tellSlack} from "network/apis";
import {toast} from "react-toastify"
import LongMenu from "components/custom/LongMenu";
import EditIcon from "@mui/icons-material/Edit";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box"

const EditBox = styled(Box)(() => ({
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    '&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.04)',}
}))

export default function ListModules(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()
    const navigate = useNavigate()

    async function loadFunc(page) {
        const fellowships = await network.get(`${config.base_path}/api/dashboard/module/list/${page}?class_id=${params.class_id}`)
        setHasMore(fellowships.data.payload.length !== 0)
        setItems(fellowships.data.payload)
    }

    function FellowShip(props) {
        const option = [
            {
                item: 'Assignment',
                redirect: `/fellowship/${params.class_id}/modules/${props._id}/assignments/list/1`
            },
            {
                item: 'Workbook',
                redirect: `/fellowship/${params.class_id}/modules/${props._id}/workbook/list/1`
            }
        ]

        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Grid container direction={"row"} sx={{alignItems: 'center'}}>
                    <Grid item style={{flexGrow: 1, color: `${props.deleted ? 'red' : ''}`}}>
                        <b style={{color: '#0470dc'}}>{props.ordering}.&nbsp;</b>{props.title}
                    </Grid>
                    <Grid item>
                            <Button variant={"outlined"} sx={{marginRight: '40px'}}
                                    onClick={() => navigate(`/fellowship/${params.class_id}/modules/${props._id}/episodes/1`)}>Episodes</Button>
                    </Grid>
                    <EditBox onClick={() => setEditObject(props)}>
                        <EditIcon sx={{margin: '8px', cursor: 'pointer'}} fontSize={'small'}/>
                    </EditBox>
                    <LongMenu options={option}/>
                </Grid>
            </Card>
        </Grid>
    }

    const handleSubmit = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/module/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited Successfully", {autoClose: 1000})
    }


    return <BasePage>
        <CreateModules/>
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/modules/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <FellowShip key={index} {...a} />)}/>
        {!!editObject && <EditFormDrawer onClose={() => setEditObject(null)} open={!!editObject} schema={editObject}
                                         onSave={handleSubmit}/>}
    </BasePage>
}
