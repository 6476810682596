import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "@mui/material/index";
import EditFormDrawer from "components/custom/EditFormDrawer";
import {toast} from "react-toastify";


export default function ListAssignmentQueResponses() {
    const [item, setItem] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [feedbackDrawer, setFeedbackDrawer] = useState(null)
    const params = useParams()
    const navigate = useNavigate()

    async function loadFunc() {
        const question = await network.get(`${config.base_path}/api/dashboard/assignments/${params.assignment_id}/user/${params.user_id}/responses`)
        setHasMore(question.data.payload.length !== 0)
        setItem(question.data.payload)
    }

    const handleSubmit = async (mainData) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/assignments/${params.assignment_id}/user/${params.user_id}/${params.user_id}`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success('Feedback Submitted', {autoClose: 2000})
        } else
            toast.error(res.data.message, {autoClose: 2000})
    }


    function Assignment(item) {

        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <p>Ques: &nbsp;{item.title}</p>
                <Button variant={"outlined"} onClick={() => navigate(`/fellowship/${params.class_id}/assignments/${params.assignment_id}/users/${params.user_id}/${item.user_response[0].thread_id}/1`)}>View response</Button>
            </Card>
        </Grid>
    }

    return <BasePage>
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/assignments/${params.assignment_id}/user/${params.user_id}/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={item.map((a, index) => <Assignment key={index} {...a} />)}/>
        {feedbackDrawer && <EditFormDrawer id={'assignments_feedback'} title='Assignment Feedback'
                                           onClose={() => setFeedbackDrawer(null)} open={!!feedbackDrawer}
                                           schema={feedbackDrawer} onSave={handleSubmit}/>}
    </BasePage>
}
