import React, { useEffect, useState } from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useParams, useNavigate } from "react-router-dom";
import Create from "components/custom/create";
import EditFormDrawer from "components/custom/EditFormDrawer";
import {tellSlack} from "network/apis";
import {toast} from "react-toastify";

export default function GroupAsset(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const params = useParams()

    async function loadFunc(page) {
        const sett = await network.get(`${config.base_path}/api/dashboard/asset_group_mapping/list/${page}?group_id=${params.group_id}`)
        setHasMore(sett.data.payload.length !== 0)
        setItems(sett.data.payload)
    }

    function Users(props) {
        const [editObject, setEditObject] = useState(null)

        const onSave = async (mainData, diff) => {
            await tellSlack(JSON.stringify({
                original: editObject,
                update: diff
            }, null, 1))
            await network.post(`${config.base_path}/api/dashboard/asset_group_mapping/update?_id=${mainData._id}`, diff)
            setEditObject(null)
            await loadFunc(params.page)
            toast.success("Edited Successfully", { autoClose: 1000 })
        }

        return <Grid item xs={12}>
            <Card style={{ margin: "8px", padding: "8px" }}>
                <Grid container direction={"row"}>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Grid item xs={12}>Asset Name: <b>{props?.asset?.title}</b></Grid>
                        <Grid item xs={12}>Score: <b>{props?.score}</b></Grid>
                        {props?.asset?.desc && <Grid item xs={12}>Desc: <b>{props?.asset?.desc}</b></Grid>}
                        <Grid item xs={12}>Thumbnail Url : <b>{props?.asset?.thumbnail_url}</b></Grid>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Grid item xs={12}>
                            <ButtonGroup>
                                <Button variant={"outlined"} color={'primary'} onClick={() => setEditObject(props)}>
                                    Edit
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            {!!editObject && <EditFormDrawer
                id={'sync-groups-asset'}
                title="Edit Asset of group"
                onSave={onSave}
                onClose={() => setEditObject(null)}
                open={!!editObject}
                schema={editObject} />}
        </Grid>
    }

    return <BasePage>
        <Create id={"sync-groups-asset"} title={'Asset of group'} slug={'asset_group_mapping'}/>
        <CDataTable
            pageUrl={`/sync/groups/${params.group_id}/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <Users key={index} {...a} />)} />
    </BasePage>
}
