import React, {PropsWithChildren, useContext, useMemo, useReducer, useRef} from 'react';
import ButtonGroup from "@mui/material/ButtonGroup";
import utils, {convertFileToBlob, generateGuid} from "./utils";
import {db} from "./db";
import {ISong} from "./types";
import _cloneDeep from "lodash/cloneDeep"
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

export type RegionActions = "remove_region" | "split_region" | "add_region"
export type LayerActions = "remove_layer" | "add_layer" | RegionActions;
export type Actions = {} | LayerActions

export type AudioProviderState = { zoom: number, song: ISong }
export type AudioProviderContext =
    {
        getSampleMeta: (sample_id: string) => Promise<({ duration: number })>, dispatcher: ({
                                                                                                type,
                                                                                                data
                                                                                            }:
                                                                                                {
                                                                                                    type: Actions,
                                                                                                    data: any
                                                                                                }) => void
    }
    & AudioProviderState

const AudioContext = React.createContext<AudioProviderContext>(undefined);

function reducer(oldState: AudioProviderState, action: { type: Actions, data: any }): AudioProviderState {
    const newState = _cloneDeep(oldState)

    async function addRegion(layer, file) {
        const newSample = {
            sample_id: generateGuid(),
            blob: await convertFileToBlob(file)
        }
        await db.soundSamples.add(newSample);
        const region = {
            version: Date.now().toString(),
            song_id: newState.song.song_id,
            layer_id: layer.layer_id,
            title: "Region 0",
            start: 0,
            end: 0,
            sample_id: newSample.sample_id
        }

        newState.song.layers = newState.song.layers.reduce((acc, item) => {
            if (item.layer_id === layer.layer_id) {
                item.regions.push(region)
                item.version = Date.now().toString()
            }
            acc.push(item)
            return acc;
        }, [])

        newState.song.version = Date.now().toString()

    }

    if (action.type === "add_layer") {
        newState.song.layers.push(action.data.layer)
    } else if (action.type === "remove_layer") {
        newState.song.layers = newState.song.layers.filter(l => l.layer_id !== action.data.layer.layer_id)
        newState.song.version = Date.now().toString()
    } else if (action.type === "remove_region") {
        newState.song.layers = newState.song.layers.reduce((acc, item, index) => {
            if (action.data.layer.layer_id === item.layer_id) {
                acc.push(Object.assign(item, {
                    version: Date.now().toString(),
                    regions: item.regions.filter(region1 => region1.sample_id !== action.data.region.sample_id)
                }))
            } else
                acc.push(item)
            return acc;
        }, [])

    } else if (action.type === "add_region") {
        addRegion(action.data.layer, action.data.file)
    } else if (action.type === "set_song") {
        newState.song = action.data.song
        newState.song.version = Date.now().toString();
    }

    return newState
}

export function AudioProvider({children, song}: PropsWithChildren<{ song?: ISong }>) {
    const audioContext = useRef<AudioContext | null>(null);
    const [state, dispatcher] = useReducer(reducer, {song: song, zoom: 10})
    const samples = useRef<{ [key: string]: AudioBuffer }>({})

    async function getSampleBuffer(sample_id: string): Promise<AudioBuffer> {
        if (samples.current[sample_id])
            return samples.current[sample_id]

        const audioBuf = await db.soundSamples.get({sample_id})
            .then(sample => utils.blobToArrayBuffer(sample.blob))
            .then(arrayBuf => getAudioContext()?.decodeAudioData(arrayBuf))
        samples.current[sample_id] = audioBuf

        return audioBuf
    }

    async function getSampleMeta(sample_id: string) {
        const buffer = await getSampleBuffer(sample_id)
        return {duration: buffer.duration, channel_count: buffer.numberOfChannels}
    }

    function getAudioContext() {
        if (audioContext.current)
            return audioContext.current
        audioContext.current = new ((window as any).AudioContext || (window as any).webkitAudioContext);
        return audioContext.current
    }

    const value = useMemo<AudioProviderContext>(function () {
        return {...state, dispatcher, getSampleMeta}
    }, [state])

    return <AudioContext.Provider value={value}>
        <Stack justifyContent={"center"} alignItems={"center"}>
            <AudioProviderUI/>
            {children}
        </Stack>
    </AudioContext.Provider>
}

export function AudioProviderUI() {
    return <>
        <audio/>
        <ButtonGroup>
            <Button>Play</Button>
            <Button>Pause</Button>
            <Button>Record</Button>
        </ButtonGroup>
    </>
}

export function useAudioProvider() {
    const context = useContext(AudioContext);
    if (!context) {
        throw new Error('error')
    }
    return context;
}
