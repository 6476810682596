export const currencyChart = {
    rates: {
        CAD: 0.0173089451 * 1.05,
        HKD: 0.105773546 * 1.05,
        ISK: 1.7316740444 * 1.05,
        PHP: 0.6564436649 * 1.05,
        DKK: 0.082818564 * 1.05,
        HUF: 4.004788552 * 1.05,
        CZK: 0.29135555 * 1.05,
        GBP: 0.010036805 * 1.05,
        RON: 0.0542420446 * 1.05,
        SEK: 0.1119296194 * 1.05,
        IDR: 192.0691556001 * 1.05,
        INR: 1.0,
        BRL: 0.073218074 * 1.05,
        RUB: 1.0111640079 * 1.05,
        HRK: 0.0842896517 * 1.05,
        JPY: 1.4171886745 * 1.05,
        THB: 0.4103454996 * 1.05,
        CHF: 0.0120571285 * 1.05,
        EUR: 0.0111361675 * 1.05,
        MYR: 0.0549670091 * 1.05,
        BGN: 0.0217801164 * 1.05,
        TRY: 0.1003880954 * 1.05,
        CNY: 0.0881806286 * 1.05,
        NOK: 0.1145499596 * 1.05,
        NZD: 0.0188011916 * 1.05,
        ZAR: 0.2084824188 * 1.05,
        USD: 0.0136418052 * 1.05,
        MXN: 0.2725220635 * 1.05,
        SGD: 0.0180717726 * 1.05,
        AUD: 0.0175483727 * 1.05,
        ILS: 0.0434098945 * 1.05,
        KRW: 14.8990784821 * 1.05,
        PLN: 0.0502385924 * 1.05,
    }, base: "INR", date: "2021-01-08"
};

export const currencyCountryChart = {
    AF: 'USD',
    AL: 'USD',
    DZ: 'USD',
    AS: 'USD',
    AD: 'EUR',
    AO: 'USD',
    AI: 'USD',
    AQ: 'USD',
    AG: 'USD',
    AR: 'USD',
    AM: 'USD',
    AW: 'USD',
    AC: 'USD',
    AU: 'AUD',
    AT: 'EUR',
    AZ: 'USD',
    BS: 'USD',
    BH: 'USD',
    BD: 'USD',
    BB: 'USD',
    BY: 'USD',
    BE: 'EUR',
    BZ: 'USD',
    BJ: 'USD',
    BM: 'USD',
    BT: 'USD',
    BO: 'USD',
    BA: 'USD',
    BW: 'USD',
    BV: 'USD',
    BR: 'BRL',
    IO: 'USD',
    BN: 'USD',
    BG: 'BGN',
    BF: 'USD',
    BI: 'USD',
    KH: 'USD',
    CM: 'USD',
    CA: 'CAD',
    CV: 'USD',
    KY: 'USD',
    CF: 'USD',
    TD: 'USD',
    CL: 'USD',
    CN: 'CNY',
    CX: 'USD',
    CC: 'USD',
    CO: 'USD',
    KM: 'USD',
    CG: 'USD',
    CD: 'USD',
    CK: 'NZD',
    CR: 'USD',
    CI: 'USD',
    HR: 'HRK',
    CU: 'USD',
    CY: 'EUR',
    CZ: 'CZK',
    DK: 'DKK',
    DJ: 'USD',
    DM: 'USD',
    DO: 'USD',
    TP: 'USD',
    EC: 'USD',
    EG: 'USD',
    SV: 'USD',
    GQ: 'USD',
    ER: 'USD',
    EE: 'EUR',
    ET: 'USD',
    FK: 'USD',
    FO: 'DKK',
    FJ: 'USD',
    FI: 'EUR',
    FR: 'EUR',
    GF: 'EUR',
    PF: 'USD',
    TF: 'EUR',
    GA: 'USD',
    GM: 'USD',
    GE: 'USD',
    DE: 'EUR',
    GH: 'USD',
    GI: 'GBP',
    GR: 'EUR',
    GL: 'DKK',
    GD: 'USD',
    GP: 'USD',
    GU: 'USD',
    GT: 'USD',
    GG: 'GBP',
    GN: 'USD',
    GW: 'USD',
    GY: 'USD',
    HT: 'USD',
    HM: 'USD',
    HN: 'USD',
    HK: 'HKD',
    HU: 'HUF',
    IS: 'ISK',
    IN: 'INR',
    ID: 'IDR',
    IR: 'USD',
    IQ: 'USD',
    IE: 'EUR',
    IM: 'GBP',
    IL: 'ILS',
    IT: 'EUR',
    JM: 'USD',
    JP: 'JPY',
    JE: 'GBP',
    JO: 'USD',
    KZ: 'USD',
    KE: 'USD',
    KI: 'USD',
    KP: 'USD',
    KR: 'USD',
    KW: 'USD',
    KG: 'USD',
    LA: 'USD',
    LV: 'USD',
    LB: 'USD',
    LS: 'ZAR',
    LR: 'USD',
    LY: 'USD',
    LI: 'CHF',
    LT: 'USD',
    LU: 'EUR',
    MO: 'USD',
    MK: 'USD',
    MG: 'USD',
    MW: 'USD',
    MY: 'MYR',
    MV: 'USD',
    ML: 'USD',
    MT: 'EUR',
    MH: 'USD',
    MQ: 'EUR',
    MR: 'USD',
    MU: 'USD',
    YT: 'EUR',
    MX: 'MXN',
    FM: 'USD',
    MD: 'USD',
    MC: 'EUR',
    MN: 'USD',
    MS: 'USD',
    MA: 'USD',
    MZ: 'USD',
    MM: 'USD',
    NA: 'USD',
    NR: 'AUD',
    NP: 'USD',
    NL: 'EUR',
    AN: 'USD',
    NC: 'USD',
    NZ: 'NZD',
    NI: 'USD',
    NE: 'USD',
    NG: 'USD',
    NU: 'NZD',
    NF: 'AUD',
    MP: 'USD',
    NO: 'NOK',
    OM: 'USD',
    PK: 'USD',
    PW: 'USD',
    PS: 'USD',
    PA: 'USD',
    PG: 'USD',
    PY: 'USD',
    PE: 'USD',
    PH: 'PHP',
    PN: 'USD',
    PL: 'PLN',
    PT: 'EUR',
    PR: 'USD',
    QA: 'USD',
    RE: 'USD',
    RO: 'USD',
    RU: 'RUB',
    RW: 'USD',
    KN: 'USD',
    LC: 'USD',
    VC: 'USD',
    WS: 'USD',
    SM: 'EUR',
    ST: 'USD',
    SA: 'USD',
    SN: 'USD',
    CS: 'EUR',
    SC: 'USD',
    SL: 'USD',
    SG: 'SGD',
    SK: 'USD',
    SI: 'EUR',
    SB: 'USD',
    SO: 'USD',
    ZA: 'ZAR',
    GS: 'USD',
    ES: 'EUR',
    LK: 'USD',
    SH: 'USD',
    PM: 'USD',
    SD: 'USD',
    SR: 'USD',
    SJ: 'USD',
    SZ: 'USD',
    SE: 'SEK',
    CH: 'CHF',
    SY: 'USD',
    TW: 'USD',
    TJ: 'USD',
    TZ: 'USD',
    TH: 'THB',
    TG: 'USD',
    TK: 'USD',
    TO: 'USD',
    TT: 'USD',
    TN: 'USD',
    TR: 'USD',
    TM: 'USD',
    TC: 'USD',
    TV: 'USD',
    UM: 'USD',
    UG: 'USD',
    UA: 'USD',
    AE: 'USD',
    UK: 'GBP',
    US: 'USD',
    UY: 'USD',
    UZ: 'USD',
    VU: 'USD',
    VA: 'USD',
    VE: 'USD',
    VN: 'USD',
    VG: 'USD',
    VI: 'USD',
    WF: 'USD',
    EH: 'USD',
    YE: 'USD',
    ZM: 'USD',
    ZW: 'USD'
};

export const currencies = Object.keys(currencyChart.rates)

export function currencyConverter(price, from_currency, to_currency) {
    const from_rate = currencyChart.rates[from_currency];
    const to_rate = currencyChart.rates[to_currency];
    if (!to_rate || !from_rate)
        throw new Error("We dont support this currency yet!");
    return Math.ceil((price * to_rate) / from_rate)
}

export const countryToCurrency = (country) => currencyCountryChart[country];

export default {
    currencyChart,
    currencyCountryChart,
    currencies,
    currencyConverter,
    countryToCurrency
}
