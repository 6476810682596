import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import {useNavigate, useParams} from "react-router-dom";
import {tellSlack} from "../../../../network/apis";
import {toast} from "react-toastify"
import CreateFaq from "./create";




export default function ListFaqs(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()

    async function loadFunc(page) {
        const faqs = await network.get(`${config.base_path}/api/dashboard/faq/list/${page}?resource_type=class&resource_id=${params.class_id}`)
        setHasMore(faqs.data.payload.length !== 0)
        setItems(faqs.data.payload)
    }
    function Faq(props) {
        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Grid container direction={"row"}>
                    <Grid item style={{flexGrow: 1}}>
                        <Grid item xs={12}>{props.title}</Grid>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Grid item xs={12}>
                            <ButtonGroup>
                                <Button variant={"outlined"} color={"success"}
                                        onClick={() => setEditObject(props)}>Edit</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    const handleSubmit = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/faq/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited Successfully", {autoClose: 1000})
    }


    return <BasePage>
    <CreateFaq />
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/faq/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <Faq key={index} {...a} />)}/>
        {!!editObject && <EditFormDrawer onClose={() => setEditObject(null)} open={!!editObject} schema={editObject}
                                         onSave={handleSubmit}/>}
    </BasePage>
}
