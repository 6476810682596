import {Button, Card, Grid, Stack} from "@mui/material";
import {useState} from "react";
import {useParams} from "react-router-dom";
import network from "network";
import config from "config";
import {toast} from "react-toastify";
import CDataTable from "components/custom/CDataTable";
import BasePage from "../../../../../../components/BasePage";

function Tags(props) {
    const params = useParams()
    const {multiSelect, onItemSelect, selectItem} = props
    const isPresented = selectItem?.find(a => a === props._id)

    async function handleClick(id) {
        await network.post(`${config.base_path}/api/dashboard/asset_tag_mapping/create`, {
            asset_id: params.asset_id,
            tag_id: id
        })
        toast.success("Edited Successfully", {autoClose: 1000})
        setInterval(() => window.location.reload(), 1000);
    }

    return <Grid item xs={6} md={4}>
        <Card style={{
            margin: "8px",
            padding: "8px",
            cursor: multiSelect ? "pointer" : 'auto',
            backgroundColor: isPresented ? '#02386E' : '#fff'
        }}
              onClick={() => multiSelect ? onItemSelect(props._id) : null}>
            <p>Name: <b>{props.name}</b></p>
            <p>Type: <b>{props.type}</b></p>
            {!multiSelect && <Button variant={"outlined"} color={"success"}
                                     onClick={() => handleClick(props._id)}>Attach</Button>}
        </Card>
    </Grid>
}

export default function AttachTags() {
    const params = useParams()
    const [items, setItems] = useState([])
    const [multiSelect, setMultiSelect] = useState(false)
    const [selectItem, setSelectItem] = useState([])

    async function loadFunc() {
        const res = await network.get(`${config.base_path}/api/dashboard/asset_tag/list/1?page_size=200&asset_id=${params.asset_id}`)
        setItems(res.data.payload)
    }

    function onItemSelect(item) {
        if (selectItem?.find(a => a === item)) {
            const newArray = selectItem.filter((itemToRemove) => itemToRemove !== item);
            setSelectItem(newArray)
        } else
            setSelectItem([...selectItem, item])
    }

    async function handleClick(id) {
        await network.post(`${config.base_path}/api/dashboard/asset_tag_mapping/create`, {
            asset_id: params.asset_id,
            tag_id: id
        })
        toast.success("Edited Successfully", {autoClose: 1000})
        setInterval(() => window.location.reload(), 1000);
    }

    return <BasePage>
        <Stack justifyContent={'space-between'} direction={'row'}>
            <Button variant={'outlined'}
                    onClick={() => setMultiSelect(!multiSelect)}>{multiSelect ? `Select one by one` : `Switch to multiple select`}</Button>
            {selectItem.length > 0 && <Button variant={'contained'} onClick={() => handleClick(selectItem)}>
                Attach selected tags
            </Button>}
        </Stack>
        <CDataTable
            pageUrl={`/sync/artists/${params.user_id}/assets/${params.asset_id}/attach-tags`}
            page={parseInt("1")}
            pageChanged={loadFunc}
            hasMore={null}
            sortBy={'hide'}
            pagination={'hide'}
            items={items.map((a, index) => <Tags key={index} {...a} multiSelect={multiSelect}
                                                 onItemSelect={onItemSelect} selectItem={selectItem}/>)}/>
    </BasePage>
}