import {ILayer, IRegion, ISong} from "./types";

export function generateGuid(): string {
    let d = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
        .replace(/[xy]/g, (c) => {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
        });
}

export function convertFileToBlob(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(new Blob([reader.result]));
        };
        reader.readAsArrayBuffer(file);
    });
}

export async function getFileMeta(file: File): Promise<{}> {
    return {}
}

export function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
            resolve(reader.result as ArrayBuffer);
        });
        reader.addEventListener('error', reject);
        reader.readAsArrayBuffer(blob);
    });
}

export function arrayBufferToBlob(buffer: Buffer, type: string) {
    return new Blob([buffer], {type: type});
}

export function filterData(audioBuffer) {
    const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
    const samples = 70; // Number of samples we want to have in our final data set
    const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
    const filteredData = [];
    for (let i = 0; i < samples; i++) {
        let blockStart = blockSize * i; // the location of the first sample in the block
        let sum = 0;
        for (let j = 0; j < blockSize; j++) {
            sum = sum + Math.abs(rawData[blockStart + j]) // find the sum of all the samples in the block
        }
        filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
    }
    return filteredData;
}

export function normalizeData(filteredData) {
    const multiplier = Math.pow(Math.max(...filteredData), -1);
    return filteredData.map(n => n * multiplier);
}

function draw(canvas: HTMLCanvasElement, normalizedData: any) {
    // set up the canvas
    const dpr = window.devicePixelRatio || 1;
    const padding = 20;
    canvas.width = canvas.offsetWidth * dpr;
    canvas.height = (canvas.offsetHeight + padding * 2) * dpr;
    const ctx = canvas.getContext("2d");
    ctx.scale(dpr, dpr);
    ctx.translate(0, canvas.offsetHeight / 2 + padding); // set Y = 0 to be in the middle of the canvas

    // draw the line segments
    const width = canvas.offsetWidth / normalizedData.length;
    for (let i = 0; i < normalizedData.length; i++) {
        const x = width * i;
        let height = normalizedData[i] * canvas.offsetHeight - padding;
        if (height < 0) {
            height = 0;
        } else if (height > canvas.offsetHeight / 2) {
            height = canvas.offsetHeight / 2;
        }
        drawLineSegment(ctx, x, height, width, (i + 1) % 2);
    }
}

/**
 * A utility function for drawing our line segments
 * @param {AudioContext} ctx the audio context
 * @param {number} x  the x coordinate of the beginning of the line segment
 * @param {number} height the desired height of the line segment
 * @param {number} width the desired width of the line segment
 * @param {boolean} isEven whether or not the segmented is even-numbered
 */
const drawLineSegment = (ctx, x, height, width, isEven) => {
    ctx.lineWidth = 1; // how thick the line is
    ctx.strokeStyle = "#fff"; // what color our line is
    ctx.beginPath();
    height = isEven ? height : -height;
    ctx.moveTo(x, 0);
    ctx.lineTo(x, height);
    ctx.arc(x + width / 2, height, width / 2, Math.PI, 0, isEven);
    ctx.lineTo(x + width, 0);
    ctx.stroke();
};


export default {
    generateGuid,
    convertFileToBlob,
    getFileMeta,
    blobToArrayBuffer,
    arrayBufferToBlob
}

export function removeRegion(song: ISong, layer: ILayer, region: IRegion): ISong {
    return Object.assign(song, {layers: song.layers.map(l => l.layer_id === layer.layer_id ? Object.assign(l, {regions: l.regions.filter(r => r.sample_id !== region.sample_id)}) : l)})
}

export function addRegion(song: ISong, layer: ILayer, region: IRegion): ISong {
    return song
}

export function removeLayer(song: ISong, layer: ILayer): ISong {
    return song
}

export function addLayer(song: ISong, layer: ILayer): ISong {
    return song
}

export function splitRegion(song: ISong, layer: ILayer, region: IRegion, at: number): ISong {
    return song
}

function test() {
    const region1 = {}
    const region2 = {}
    const layer1 = {}
    const layer2 = {}
    const song = {}
    let newSong;
    // newSong = addLayer(song, layer1)
    // newSong = addRegion(newSong, layer1, region1)
    // newSong  = addLayer(newSong,layer2)
    // newSong = addRegion(newSong, layer2, region1)
    // newSong = addRegion(newSong, layer1, region2)
    //
}