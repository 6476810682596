import { Button } from "@mui/material"
import { useState } from "react"
import EditFormDrawer from "components/custom/EditFormDrawer"
import network from "network"
import config from "config"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
export default function CreateEpisode(props) {
    const [episodeObject, setEpisodeObject] = useState(null)
    const params = useParams()
    async function loadFunc() {
        const episode = await network.get(`${config.base_path}/api/dashboard/episode/sample`)
        setEpisodeObject({...episode?.data?.payload})
    }
    const handleSubmit = async (mainData, diff, diffPath) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/episode/create?class_id=${params.class_id}&module_id=${params.module_id}`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success('Episode Created', { autoClose: 2000 })
        }
        else
            toast.error(res.data.message, { autoClose: 2000 })
    }
    return <>
        <Button variant={"outlined"} onClick={() => loadFunc()}>+Create New Episode</Button>
        {!!episodeObject && <EditFormDrawer id={'episode'} onClose={() => setEpisodeObject(null)} open={!!episodeObject} schema={episodeObject} onSave={handleSubmit} />}
    </>
}