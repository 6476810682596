import {useState} from "react";
import {Button, Container, IconButton, TextField} from '@mui/material';
import BasePage from "components/BasePage";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useLocation, useParams} from "react-router-dom";
import network from "../../../network";
import config from "../../../config";
import {toast} from "react-toastify";

//"mail_ids":["satyendra.sg@mugafi.com"],"offset_days":3,"campaign_slugs": ["express-program-songwriting-express-program"
export default function Leads(props) {
    const params = useParams()
    const location = useLocation()

    const [offsetDays, setOffsetDays] = useState<number>(0);
    const [email, setEmail] = useState('');
    const [emails, setEmails] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };
    const handleAddEmail = () => {
        if (email.trim() !== '') {
            setEmails([...emails, email]);
            setEmail('');
        }
    };
    const handleOffsetDaysChange = (event) => {
        const updatedOffsetDays = Number(event.target.value);
        setOffsetDays(updatedOffsetDays);
    };


    const handleSubmit = async () => {
        let campSlug;
        if (location.state?.includes('express')) {
            campSlug = `express-program-${params.slug}`
        } else if (params.slug.includes('fellowship_' || 'class_')) {
            if (params.slug.includes('fellowship_'))
                campSlug = `marketing-fellowship-${params.slug}`
            else campSlug = `marketing-class-${params.slug}`
        } else campSlug = `marketing-${params.slug}`

        const payload = {
            "mail_ids": emails,
            "offset_days": offsetDays,
            "campaign_slugs": [campSlug]
        }
        setIsLoading(true)
        const result = await network.post(`${config.base_path}/api/dashboard/mail_leads`, payload)
        setIsLoading(false)
        if (result.data.statusCode === 0) {
            toast.success('Information sent to provided Email')
        } else toast.error(result.data.message)
    };

    function removeItemFromArray(c_index) {
        let c = []
        emails.map((item, index) => {
            if (index !== c_index)
                c.push(item)
        })
        setEmails(c)
    }

    return <BasePage>
        <Container>
            <Stack direction={'row'} spacing={4}>
                <TextField
                    label="Email ID"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                />
                <Button variant="contained" onClick={handleAddEmail} sx={{width: '50%'}} disabled={!email}>
                    Add Email
                </Button>
            </Stack>
            {emails.length > 0 && <>
                <Typography variant="h6" style={{marginTop: '16px'}}>Email IDs:</Typography>
                {emails.map((email, index) => <Stack key={index} direction={'row'} alignItems={'center'}>
                    <Typography variant="body1">
                        {email}
                    </Typography>
                    <IconButton onClick={() => removeItemFromArray(index)}>
                        <DeleteForeverIcon/>
                    </IconButton>
                </Stack>)}
            </>}
            <TextField
                label="Offset Days"
                type="number"
                value={offsetDays}
                onChange={handleOffsetDaysChange}
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}
                    disabled={emails.length === 0 || offsetDays <= 0}>
                Submit
            </Button>
        </Container>
    </BasePage>
}