import React, {useState, useEffect} from 'react'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import network from 'network/index';
import config from 'config';
import Switch from '@mui/material/Switch';
import {toast} from 'react-toastify';
import {ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {useNavigate} from 'react-router-dom';


export default function EditUserInfo(props) {
    const user = props.userData
    const navigate = useNavigate()
    const [firstName, setFirstName] = useState(user.name?.first || '')
    const [lastName, setLastName] = useState(user.name.last || '')
    // const [birthday, setBirthday] = useState(user.birthday || '')
    const [about, setAbout] = useState(user.about || '')
    const [gender, setGender] = useState(user.gender || '')
    const [miniAbout, setMiniAbout] = useState(user.mini_about || -1)
    const [profilePic, setProfilePic] = useState(user.profile_pic || '')
    const [responseTime, setResponseTime] = useState(user.response_time || '')
    const [createItem, setCreateItem] = useState(false)
    const [manageItem, setManageItem] = useState(false)
    const [permission, setPermission] = useState<any>({})
    const [enrollments, setEnrollments] = useState([])

    useEffect(() => {
        network.get(config.base_path + `/api/dashboard/enrollment/${props.id}/get`)
            .then(response => {
                setEnrollments(response.data.payload)
            })
    }, [])


    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.setDrawerState(open);
    };

    function onItemClick(enrollItem) {
        if (enrollItem.type === 430)
            return navigate(`/unluclass/${enrollItem.class_id}/episodes/1`)
        else if (enrollItem.type === 450)
            return navigate(`/fellowship/${enrollItem.class_id}/modules/1`)
        else alert("About to come soon!!")
    }

    function onSave(e) {
        e.preventDefault();
        const payload = {}
        if (user.name.first !== firstName || user.name.last !== lastName)
            payload['name'] = {first: firstName, last: lastName}
        // if (user.birthday !== birthday)
        //     payload["birthday"] = birthday
        if (user.about !== about)
            payload["about"] = about
        if (user.profile_pic !== profilePic)
            payload["profile_pic"] = profilePic
        if (user.response_time !== responseTime)
            payload["response_time"] = responseTime
        if (user.mini_about !== miniAbout)
            payload["mini_about"] = miniAbout
        if (user.gender !== gender)
            payload["gender"] = gender
        if (permission.manage_items !== manageItem || permission.create_items !== createItem) {
            const group_id = 'general'
            if (permission.manage_items !== manageItem)
                payload["permissions"] = {manage_items: manageItem}
            else if (permission.create_items !== createItem)
                payload["permissions"] = {create_items: createItem}
            network.post(config.base_path + `/api/dashboard/user/${props.id}/group/${group_id}/update_permissions`, payload)
                .then(response => {
                    if (response.data.statusCode === 0) {
                        toast(" Updated")
                        window.location.assign(`/user/${props.id}`);
                    } else {
                        toast.error(" Not Updated: " + response.data.message)
                    }
                })
                .catch(err => {
                    console.error(err)
                });
        }
        if (permission.manage_items === manageItem && permission.create_items === createItem && user.name.first === firstName && user.name.last === lastName && user.about === about &&
            user.profile_pic === profilePic && user.mini_about === miniAbout && user.gender === gender) {
            toast.error("Please Update at least one field!")
            return;
        } else if (user.name.first !== firstName || user.name.last !== lastName || user.about !== about ||
            user.profile_pic !== profilePic || user.mini_about !== miniAbout || user.gender !== gender) {
            network.post(config.base_path + `/api/dashboard/user/${props.id}/update`, payload)
                .then(response => {
                    if (response.data.message === 'Success') {
                        toast(" Updated")
                        window.location.assign(`/user/${props.id}`);
                    } else {
                        toast.error(" Not Updated: " + response.data.message)
                    }
                })
                .catch(err => {
                    toast.error("There is some error in the request" + err);
                });
        }
    }

    function deleteUser() {
        network.post(config.base_path + `/api/dashboard/user/${props.id}/update`, ({
            deleted: true
        }))
            .then(response => window.location.assign(`/`))
            .catch(err => {
                console.error(err)
                alert("There is some error in the request" + err);
            });
    }


    useEffect(() => {
        network.get(`${config.base_path}/api/dashboard/user/${props.id}/group/general/permissions`)
            .then(response => {
                const permissions = response.data.payload.permissions
                setPermission(permissions)
                setManageItem(permissions.manage_items)
                setCreateItem(permissions.create_items)
            })
            .catch(console.error.bind(null, "permission fetch error"));
    }, [])

    return <Drawer anchor="right" PaperProps={{sx: {width: "50%"}}} open={props.drawerState}
                   onClose={toggleDrawer(false)}>
        <IconButton
            onClick={toggleDrawer(false)}
            style={{position: "absolute", right: "10px", margin: "20px 0"}}
            size="large">
            <CloseIcon/>
        </IconButton>
        <Grid container style={{marginTop: '8vh', padding: '4vh'}}>
            <Grid item xs={12}>
                <Typography variant={"h4"}>
                    Edit {user.name.first}'s Account
                </Typography>
            </Grid>
            <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="name"
                onChange={(e: any) => {
                    const name = e.target.value
                    const brokenName = name.split(" ")
                    const first_name = brokenName[0]
                    let last_name;
                    if (brokenName.length === 1)
                        last_name = "";
                    else if (brokenName.length === 2)
                        last_name = brokenName[1];
                    else if (brokenName.length > 2)
                        last_name = brokenName.slice(1).join(" ").trim();
                    else last_name = ""
                    setFirstName(first_name)
                    setLastName(last_name)
                }}
                value={firstName + " " + lastName}
                id="name"
                label={"Name"}
            />
            {/* <DateTimePicker
                value={birthday}
                renderInput={(props) => <TextField style={{ flexGrow: 1, }} {...props} />}
                onChange={(e: any) => { setBirthday(e.toDate()) }}
                id='birthday'
                label={'birthday'} /> */}
            <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="about"
                onChange={(e: any) => setAbout(e.target.value)}
                value={about}
                id="about"
                label={"about"}
            />
            <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="profile_pic"
                value={profilePic}
                onChange={(e: any) => setProfilePic(e.target.value)}
                label={"Profile Pic URL"}
                id="profile_pic"
            />
            <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="response_time"
                value={responseTime / 86400000}
                onChange={(e: any) => {
                    if (isNaN(Number(setResponseTime(e.target.value)))) {
                        alert("Please enter a Valid Number")
                    } else
                        setResponseTime(e.target.value * 86400000)
                }}
                label={"Response Time(in Days)"}
                id="response_time"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        disabled
                        checked={false}
                        onChange={(e: any) => e.target.checked}
                        name="hidden"
                        color="primary"
                    />
                }
                label={"delete"}
            />
            <Grid item xs={12}>
                <Typography variant={"h6"}>
                    User's Community Permissions :-
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={createItem}
                            onChange={(e: any) => setCreateItem(e.target.checked)}
                            name="create_items"
                            color="primary"
                        />
                    }
                    label="create_items"
                />
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={manageItem}
                            onChange={(e: any) => setManageItem(e.target.checked)}
                            name="manage_items"
                            color="primary"
                        />
                    }
                    label={"manage_items"}
                />
            </Grid>
            {enrollments.length !== 0 && <Grid item xs={12} mt={2}>
                <Accordion variant={"outlined"}>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                        <Typography variant={"subtitle1"}>User Enrollments</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{borderRadius: "4px"}}>
                        {enrollments.map((item, index) => <ListItem key={index} style={{cursor: 'pointer'}}
                                                                    onClick={() => onItemClick(item)}>
                            <Grid>
                                {item.slug && <ListItemText> Item: <b>{item.slug}</b></ListItemText>}
                                <ListItemText> Item ID: <b>{item.class_id}</b></ListItemText>
                                <ListItemText> progress: <b>{item.progress}</b></ListItemText>
                            </Grid>
                        </ListItem>)}
                    </AccordionDetails>
                </Accordion>
            </Grid>}
            <Grid item xs={5.5} mt={3}>
                <FormControl fullWidth>
                    <InputLabel>
                        Gender
                    </InputLabel>
                    <Select
                        fullWidth
                        label="gender"
                        name="gender"
                        value={gender}
                        id="gender"
                        onChange={(e: any) => setGender(e.target.value)}
                    >
                        <MenuItem value={-1} disabled>Select</MenuItem>
                        <MenuItem value={1}>Male</MenuItem>
                        <MenuItem value={2}>Female</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={5.5} mt={3}>
                <FormControl fullWidth>
                    <InputLabel>
                        Mini About
                    </InputLabel>
                    <Select
                        fullWidth
                        label="mini_about"
                        name="mini_about"
                        value={miniAbout || -1}
                        onChange={(e: any) => setMiniAbout(e.target.value)}
                    >
                        <MenuItem value={-1} disabled>Select</MenuItem>
                        <MenuItem value={0}>Chess Player</MenuItem>
                        <MenuItem value={1}>Writer</MenuItem>
                        <MenuItem value={2}>Lyricist</MenuItem>
                        <MenuItem value={3}>Youtuber</MenuItem>
                        <MenuItem value={4}>author</MenuItem>
                        <MenuItem value={5}>Screenwriter</MenuItem>
                        <MenuItem value={6}>Singer</MenuItem>
                        <MenuItem value={7}>Voice Actor</MenuItem>
                        <MenuItem value={8}>Actor</MenuItem>
                        <MenuItem value={9}>Comedian</MenuItem>
                        <MenuItem value={10}>Director</MenuItem>
                        <MenuItem value={11}>Tennis Player</MenuItem>
                        <MenuItem value={12}>Fitness Freak</MenuItem>
                        <MenuItem value={13}>Crypto Nerd</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item container style={{marginTop: '20px'}} justifyContent={"space-evenly"} spacing={1} xs={12}>
                <Grid item>
                    <Button variant="outlined" color="error" onClick={deleteUser}>
                        Delete
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={toggleDrawer(false)} variant="outlined" color='secondary'>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color="success" onClick={(e) => onSave(e)}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Drawer>;
}

