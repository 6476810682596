import network from "network";
import config from "config";
import React, {useState} from "react";
import CDataTable from "../../../components/custom/CDataTable";
import {useParams} from "react-router-dom";
import BasePage from "../../../components/BasePage";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EditFormDrawer from "../../../components/custom/EditFormDrawer";
import {tellSlack} from "../../../network/apis";
import {toast} from "react-toastify";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Create from "../../../components/custom/create";
import CreateTags from "./create";
import {ButtonGroup} from "@mui/material";

export default function TagList() {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)

    const params = useParams()


    async function loadFunc(page) {
        await network.get(config.base_path + `/api/dashboard/tags/get?type=${params.tag_type}&page=${page}`)
            .then(res => {
                setHasMore(res.data.payload.length !== 0)
                setItems(res.data.payload)
            })
            .catch(console.error.bind(null, "tags fetch error"));
    }

    return <BasePage>
        <CreateTags type={params.tag_type} />
        <CDataTable
            pageUrl={`/tags/${params.tag_type}/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <UnluTag key={index} value={a}/>)}/>
    </BasePage>
}

const UnluTag = (props) => {

    const [editObject, setEditObject] = useState(null)

    const handleClick = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/tags/${mainData.uid}/update`, diff)
        setEditObject(null)
        toast.success("Edited Successfully", {autoClose: 1000})
    }

    const onDelete = async (item) => {
        const text = "Do you really want to delete it?";
        if (window.confirm(text) === true) {
            return await network.post(`${config.base_path}/api/dashboard/tags/${item.uid}/update`, {
                deleted: true
            })
                .then(() => window.location.reload())
        } else
            return;
    }

    return <>
        <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Stack direction={"row"} sx={{width:"100%"}}>
                    <Box flexGrow={1}>name: {props.value.alias}</Box>
                    <ButtonGroup>
                        <Button variant={"outlined"} color={"success"}
                                onClick={() => setEditObject(props.value)}>Edit</Button>
                        <Button variant={"outlined"} color={"error"}
                                onClick={() => onDelete(props.value)}>Delete</Button>
                    </ButtonGroup>
                </Stack>
            </Card>
        </Grid>
        {!!editObject && <EditFormDrawer onSave={handleClick} onClose={() => setEditObject(null)} open={!!editObject}
                                         schema={editObject}/>}
    </>
}