import {useUser} from "../../../../components/UserProvider";
import BasePage from "../../../../components/BasePage";
import network from "../../../../network";
import config from "../../../../config";
import {useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {Link} from "@mui/material";
import Stack from "@mui/material/Stack";

export default function UserSubmission() {
    const user = useUser()
    const params = useParams()
    const projectRef = useRef<{ id: string }>()

    const [submission, setSubmission] = useState([])

    async function getSubmission() {
        const res = await network.get(`${config.base_path}/api/community/dashboard/attachments?user_id=${params.user_id}&resource_id=${params.resource_id}&resource_type=unlu_event`)
        if (res.data.statusCode === 0)
            setSubmission(res.data.payload)
        else toast.error(res.data.message)
    }

    useEffect(() => {
        getSubmission()
    }, [])

    async function openTool(id) {
        const importantStuff = window.open('', '_blank');
        importantStuff.document.write('Loading ved...');

        projectRef.current?.id


        if (id)
            window.open(``)
        else {
            importantStuff.close();
            toast.error("Error")
        }
    }

    return <BasePage>
        Click to the link to get user's Entry:
        <Stack mt={4}>
            {submission?.[0]?.attachment?.url &&
                <Link href={submission?.[0]?.attachment?.url}
                      target={"_blank"}>{submission?.[0]?.attachment?.url}</Link>}
            {submission?.[0]?.attachment?.story_metadata &&
                <Link
                    href={`https://www.mugafi.com/tools/write-with-ved/${submission?.[0]?.attachment?.story_metadata.id}`}
                    target={"_blank"}>Open {submission?.[0]?.attachment?.story_metadata.title}</Link>}

        </Stack>
    </BasePage>
}