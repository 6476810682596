import React, {useState, useEffect} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import ButtonGroup from "@mui/material/ButtonGroup";
import RightDrawer from "components/custom/RightDrawer";
import CDataTable from "components/custom/CDataTable"
import CreateInterestForm from "components/community/CreateInterestForm";
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import {useParams, Navigate} from "react-router-dom";

const CommunityInterests = (props) => {
    let pageSize = 10;
    const formType = "edit";
    const [interests, setInterests] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [editInterestDrawer, setEditInterestDrawer] = useState(false);
    const [formDataId, setFormDataId] = useState();
    const [formData, setFormData] = useState([]);
    const [createInterestDrawer, setCreateInterestDrawer] = useState(false);
    const [searchValue, setSearchValue] = useState("")
    const [page, setPage] = useState(0)
    const params = useParams()


    const handleChange = (e) => {
        setSearchValue(e.target.value)
    }

    useEffect(() => {
        loadFunc(1)
    }, [searchValue])

    async function loadFunc(page) {
        const response = await network.get(config.base_path + `/api/dashboard/interests?page=${page}&page_size=${pageSize}&search=${searchValue}`)
        setPage(page)
        setHasMore(response.data.payload.length !== 0)
        setInterests(response.data.payload)
    }

    function handleEdit(id) {
        let interestData = interests.find((o) => o.id === id);
        setEditInterestDrawer(true);
        setFormDataId(id);
        setFormData(interestData);
    }

    function handleDelete(id) {
        const text = "Are you sure,wants to delete interest?";
        if (window.confirm(text) === true) {
            network
                ._delete(`${config.base_path}/api/dashboard/interests/${id}`)
                .then((response) => {
                    window.location.reload();
                });
        } else {
            Navigate({to:"/"})
        }
    }

    function Interest(props) {
        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Grid container direction={"row"}>
                    <Grid item style={{flexGrow: 1}}>
                        <Grid item xs={12}>{pageSize * page - (pageSize - (props.index + 1))}. {props.name}</Grid>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Grid item xs={12}>
                            <ButtonGroup>
                                <Button variant={"outlined"} color={"success"} onClick={() => handleEdit(props.id)}>
                                    Edit
                                </Button>
                                <Button variant={"outlined"} color={"error"} onClick={() => handleDelete(props.id)}>
                                    Delete
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    return <BasePage>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography variant={"h6"} style={{flexGrow: 1, fontWeight: "bold"}}>Active interest list</Typography>
            <TextField
                type="text"
                name="searchValue"
                value={searchValue}
                onChange={handleChange}
                autoComplete="off"
                variant="outlined"
                label="Search Interest..."
            />
            <Button variant="outlined" onClick={() => setCreateInterestDrawer(true)}>
                + Create New Interest
            </Button>
        </Stack>
        <Divider style={{width: "100%", margin: "8px"}}/>
        <CDataTable
            pageUrl={`/community/interests/`}
            pageChanged={loadFunc}
            page={parseInt(params.page)}
            hasMore={hasMore}
        >
            {interests.map((a, index) => <Interest key={index} index={index} {...a} />)}
        </CDataTable>

        <RightDrawer
            drawerState={editInterestDrawer}
            setDrawerState={setEditInterestDrawer}
            formComponent={CreateInterestForm}
            formType={formType}
            formId={formDataId}
            formData={formData}
        />
        <RightDrawer
            drawerState={createInterestDrawer}
            setDrawerState={setCreateInterestDrawer}
            formComponent={CreateInterestForm}
        />
    </BasePage>
};

export default CommunityInterests;
