import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import {useParams} from "react-router-dom";
import CreateEpisode from "./create";
import {tellSlack} from "network/apis";
import {toast} from "react-toastify"
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import LongMenu from "../../../../../../components/custom/LongMenu";
import Stack from "@mui/material/Stack";

export default function ListEpisodes(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()
    const navigate = useNavigate()

    async function loadFunc(page) {
        const fellowships = await network.get(`${config.base_path}/api/dashboard/episode/list/${page}?module_id=${params.module_id}`)
        setHasMore(fellowships.data.payload.length !== 0)
        setItems(fellowships.data.payload)
    }

    function FellowShip(props) {
        const date = new Date(props.start_time)
        const startDate = `${date.getFullYear()}-${String("0" + (date.getMonth() + 1)).slice(-2)}-${String("0" + (date.getDate())).slice(-2)}`

        async function onProcessVideo() {
            const processedVideo = await network.post(`${config.base_path}/api/dashboard/episode/${props._id}/process_video`, {})
            if (processedVideo?.data?.statusCode === 0)
                toast.success("video processing has started...")
            else toast.error(processedVideo?.data?.message)
        }

        const option = [
            {
                item: 'Process video',
                onClickFunc: () => onProcessVideo()
            }
        ]

        return <Grid item xs={12}>
            <Card style={{
                margin: "8px",
                padding: "8px",
                background: props.type === 'workbook' ? `linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)` : props.type === 'assignment' ? `linear-gradient(135deg, #fcdf8a 0%,#f38381 100%)` : ''
            }}>
                <Grid container direction={"row"}>
                    <Grid item style={{flexGrow: 1, color: `${props.deleted ? 'red' : ''}`}}>
                        {props.type === 'assignment' && <Box sx={{color: '#0000FF'}}>Assignment</Box>}
                        {props.type === 'workbook' && <Box sx={{color: '#0000FE'}}>Workbook</Box>}
                        <Grid item xs={12}><b>{props?.ordering}. &nbsp;{props.title}</b></Grid>
                        <Grid item xs={12} sx={{fontSize: '13px'}}>Start Date: <b>{startDate}</b></Grid>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        {props.type === 'assignment' &&
                            <>
                                <ButtonGroup>
                                    <Button variant={"outlined"}
                                            onClick={() => navigate(`/fellowship/${params.class_id}/assignments/${props.resource_id}/questions/1`)}>Questions</Button>
                                    <Button variant={"outlined"} style={{marginRight: '20px'}}
                                            onClick={() => navigate(`/fellowship/${params.class_id}/assignments/${props.resource_id}/responses/1`)}>Responses</Button>
                                </ButtonGroup>
                            </>
                        }
                        <Stack direction={'row'}>
                            <Button variant={"outlined"} color={"success"}
                                    onClick={() => setEditObject(props)}>Edit</Button>
                            {(props.type !== 'assignment' || props.type !== 'workbook') && <LongMenu options={option}/>}
                        </Stack>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    async function handleClick(mainData, diff) {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/episode/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited Successfully", {autoClose: 1000})
    }

    return <BasePage>
        <Grid display={"flex"}>
            <CreateEpisode/>
        </Grid>
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/modules/${params.module_id}/episodes/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <FellowShip key={index} {...a} />)}/>
        {!!editObject && <EditFormDrawer onSave={handleClick} onClose={() => setEditObject(null)} open={!!editObject}
                                         schema={editObject}/>}
    </BasePage>
}
