import {useEffect, useRef, useState} from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {styled} from "@mui/material";
import Container from "@mui/material/Container";
import {CastData, CastList, CastType, ScriptNode, WritingMode} from "./DataTypes";
import {useEditor} from "./EditorProvider";


const MainEditText: any = styled(Box)(({theme}) => ({
    background: "#fafafa",
    boxShadow: "0 0 10px rgba(0,0,0,0.3), 0 0 300px 25px rgba(222,198,122,0.7) inset",
    margin: "0 auto",
    padding: "24px",
    fontFamily: "Courier Prime",
    border: "1px solid black",
    width: "100%",
    overflow: "scroll",
    "& .narrator": ({
        "&::before": {
            content: "' 'counter(css-counter) '. Narrator: '",
            fontWeight: 600
        },
        display: 'flex',
        alignItems: 'center'
    }),
    "& .member": ({
        "&::before": {
            content: "' 'counter(css-counter) '. Cast: '",
            fontWeight: 600
        },
        display: 'flex',
        alignItems: 'center'
    }),

    "& .actor": ({
        "&::before": {
            content: "'Actor: '",
            fontWeight: 600
        }
    }),

    "& .character": ({
        "&::before": {
            content: "'Character: '",
            fontWeight: 600
        }
    })
}))

export default function CharacterList() {
    const {
        castList,
        setCastList,
        createP
    } = useEditor()

    const [content, setContent] = useState<CastList>(castList || [
        {type: CastType.Narrator, actor: 'Actor',},
        {type: CastType.Member, actor: 'Actor', character: 'Character'},
    ])
    const [version, updateVersion] = useState(Date.now())

    useEffect(() => {
        if (castList) {
            setContent(castList)
            updateVersion(Date.now())
        }
    }, [castList])


    return <Stack sx={{direction: {md: "row", sx: "column"}}} spacing={1} justifyContent={"center"}
                  alignItems={"center"}>
        <Stack spacing={1} justifyContent={"center"} alignItems={"center"} sx={{padding: "8px"}}>
            <Container maxWidth={"md"} sx={{flexGrow: 1, width: "100%"}}>
                <MainEditText version={version}
                              suppressContentEditableWarning
                              contentEditable={true}>
                    {content.map((a, index) =>
                        <p className={a.type.toString().toLowerCase()}> &nbsp;
                            {a.actor && <p className={"actor"}> {a.actor} &nbsp;</p>}
                            {a.character && <p className={"character"}> {a.character} </p>}
                        </p>
                    )}
                </MainEditText>
            </Container>
        </Stack>
    </Stack>
}
