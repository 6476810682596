import Dexie, {Table} from 'dexie';

export interface SoundSample {
    id?: number;
    sample_id: string;
    blob: Blob;
}

export class UnluLabsDb extends Dexie {
    soundSamples!: Table<SoundSample>;

    constructor() {
        super('unlulabs');
        this.version(1)
            .stores({
                soundSamples: '++id, sample_id, blob' // Primary key and indexed props
            });
    }
}

export const db = new UnluLabsDb();