import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import {useNavigate, useParams} from "react-router-dom";
import Create from "../../../components/custom/create";
import EditFormDrawer from "../../../components/custom/EditFormDrawer";
import {tellSlack} from "../../../network/apis";
import {toast} from "react-toastify";


export default function ManageGroups(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()

    async function loadFunc(page) {
        const sett = await network.get(`${config.base_path}/api/dashboard/asset_group/list/${page}`)
        setHasMore(sett.data.payload.length !== 0)
        setItems(sett.data.payload)
    }

    const onSave = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/asset_group/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        await loadFunc(params.page)
        toast.success("Edited Successfully", {autoClose: 1000})
    }

    function Users(props) {
        const navigate = useNavigate()

        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Grid container direction={"row"}>
                    <Grid item style={{flexGrow: 1}}>
                        <Grid item xs={12}>Group Name: <b>{props.name}</b></Grid>
                        <Grid item xs={12}>Group Slug: <b>{props.slug}</b></Grid>
                        {props?.thumbnail_url && <Grid item xs={12}>Thumbnail url: <b>{props.thumbnail_url}</b></Grid>}
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Grid item xs={12}>
                            <ButtonGroup>
                                <Button variant={"outlined"}
                                        onClick={() => navigate(`/sync/groups/${props?.id || props?._id}/1`)}>
                                    Assets
                                </Button>
                                <Button variant={"outlined"} color={'success'} onClick={() => setEditObject(props)}>
                                    EDit
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    return <BasePage>
        <Create id={"sync-groups"} title={'Group'} slug={'asset_group'}/>
        <CDataTable
            pageUrl={`/sync/groups/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <Users key={index} {...a} />)}/>
        {!!editObject && <EditFormDrawer
            id={'sync-groups-asset'}
            title="Edit Asset of group"
            onSave={onSave}
            onClose={() => setEditObject(null)}
            open={!!editObject}
            schema={editObject}/>}
    </BasePage>
}
