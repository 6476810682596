import React, {useState} from "react";
import {Button, Paper} from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import config from "../../config";
import network from "../../network";
import { toast } from "react-toastify";

const useStyles = makeStyles({
    formLabel: {
        marginTop: "10px",
        color: "#191237",
        fontFamily: "Source Sans Pro",
        fontSize: "1.1rem",
    },
    textField: {
        width: "22ch",
    },

    submitBtn: {
        marginTop: "40px",
        width: "100%",
    },
});

const CreateSkillForm = (props) => {
    const [skillName, setSkillName] = useState();
    // const [value, setValue] = useState(false);
    const handleSubmit = (e) => {
        if (skillName === "" || skillName == null) {
            toast.warning("Enter Skill Name");
            return;
        }
        if (props.formTypeEdit) {
            network
                .post(
                    `${config.base_path}/api/dashboard/skills/${props.formId}`,
                    {name: skillName, verified: props.formData.verified}
                )
                .then((response) => {
                    if (response.data.statusCode === 0) {
                        window.location.reload();
                        toast.success(skillName + " skill edited successfully!",{autoClose : 3000});
                    } else {
                        toast.error(response.data.message, {autoClose : 3000});
                    }
                })
                .catch((err) => console.log(err));
        } else {
            network
                .post(
                    `${config.base_path}/api/dashboard/skills`,
                    {
                        name: skillName,
                    }
                )

                .then((response) => {
                    if (response.data.statusCode === 0) {
                        window.location.reload();
                        toast.success(skillName + " skill created successfully!" , {autoClose : 3000});
                    } else {
                        toast.error(response.data.message , {autoClose : 3000});
                    }
                });
        }
    };

    const classes = useStyles();
    return (
        <Paper style={{padding: "30mm 20mm", maxWidth: "500px", height: "100%"}}>
            {props.formTypeEdit ? (
                <Grid>
                    <Grid container direction="row" spacing={0} alignItems="center">
                        <Grid item xs={12}>
                            <Typography
                                className={classes.formLabel}
                                style={{fontWeight: "bold"}}
                            >
                                Existing Skill : {props.formData.name}
                            </Typography>
                            <Typography className={classes.formLabel}>New Skill :</Typography>
                            <TextField
                                className={classes.textField}
                                required
                                id="standard-required"
                                onChange={(e: any) => setSkillName(e.target.value)}
                                value={skillName}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid>
                    <h4>Create new skill</h4>
                    <Grid container direction="row" spacing={0} alignItems="center">
                        <Grid item xs={8}>
                            <TextField
                                className={classes.textField}
                                required
                                id="standard-required"
                                label="Enter Skill "
                                onChange={(e: any) => setSkillName(e.target.value)}
                                value={skillName}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <Button
                className={classes.submitBtn}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Submit
            </Button>
        </Paper>
    );
};

export default CreateSkillForm;
