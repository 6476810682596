import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import network from "../network";
import config from "../config";
import processCoupon from "../utils/coupon_processor";
import DialogBox from './custom/dialog-box'
import Stack from "@mui/material/Stack";
import MultiChipSelectV2 from "./custom/MultiChipSelectV2";
import {DatePicker} from "@mui/lab";
import moment from "moment";
import {useFetchPrograms} from "./common/fetchPrograms";

const couponDeliverableTypeFilter = [
    {id: 430, label: "unluclass"},
    {id: 440, label: "All Access Pass"},
    {id: 450, label: "Fellowship"},
    {id: 520, label: "Program"},
    {id: 499, label: "Cart"},
    {id: 490, label: "Sync Asset"},
]

function SingleCoupon(props) {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [confirmDialogContent, setConfirmDialogContent] = useState<any>(null)
    const [couponRulesCheckerText, setCouponRulesCheckerText] = useState<string[]>([])
    const [isDealValid, setIsDealValid] = useState(false)
    const [couponFor, setCouponFor] = useState([])

    const [couponCode, setCouponCode] = useState("")
    const [couponDeal, setCouponDeal] = useState("")
    const [totalCoupons, setTotalCoupons] = useState(1)
    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment().add(1, "month"))
    const [selectedUnluclass, setSelectedUnluclass] = useState([])
    const [selectedFellowship, setSelectedFellowship] = useState([])
    const [selectedProgram, setSelectedProgram] = useState([])
    const [showClassFilter, setShowClassFilter] = useState(false);
    const [showFellowshipFilter, setShowFellowshipFilter] = useState(false);
    const [showProgramFilter, setShowProgramFilter] = useState(false);

    const {
        fellowships,
        program,
        unluclasses
    } = useFetchPrograms();

    useEffect(() => {
        const couponForIds = couponFor.map((a) => a.id);
        setShowClassFilter(couponForIds.includes(430));
        setShowFellowshipFilter(couponForIds.includes(450));
        setShowProgramFilter(couponForIds.includes(520));
    }, [couponFor]);

    function confirmCouponCreation() {
        const confirmationText = confirmCouponCreationText()
        setConfirmDialogContent(confirmationText)
        if (!!confirmationText)
            setConfirmDialogOpen(true);
    }

    function confirmCouponCreationText() {

        if (!isDealValid) {
            return alert("Please check the description at the end of the page and provide a valid coupon deal.")
        }

        if (couponCode.trim() === "" || couponDeal.trim() === "" || (totalCoupons < 1 || totalCoupons > 10000) || couponFor.length === 0) {
            return alert("All fields are mandatory.")
        }

        if (!startDate.isValid()) {
            return alert('Enter a valid Start Date')
        }

        if (!endDate.isValid()) {
            return alert('Enter a valid Expiry Date')
        }

        return <>
            {`Coupon Code: ${couponCode}`}<br/>
            {`Coupon Deal: ${couponDeal}`}<br/>
            {`StartDate: ${startDate}`}<br/>
            {`End Date: ${endDate}`}<br/>
            {`Total Coupons: ${totalCoupons}`}<br/>
            {`Coupon For: ${couponFor.map(a => a.label).join(",")}`}<br/>
            {selectedUnluclass.length ? `unluclasses: ${selectedUnluclass.map(a => a.label).join(" | ")}` : ""}<br/>
            {selectedFellowship.length ? `fellowships: ${selectedFellowship.map(a => a.label).join(" | ")}` : ""}<br/>
            {selectedProgram.length ? `program: ${selectedProgram.map(a => a.label).join(" | ")}` : ""}<br/>
        </>
    }

    function createCouponApiCall() {
        const classes = (selectedUnluclass || []).concat((selectedFellowship || [])).concat((selectedProgram || []))

        const requestPayload = Object.assign({
            coupon_code: couponCode.trim().toLowerCase(),
            coupon_deal: couponDeal,
            start_date: startDate.toDate().getTime(),
            end_date: endDate.toDate().getTime(),
            coupon_for: couponFor.map(a => a.id),
            total_coupons: totalCoupons
        }, classes.length > 0 ? {unluclasses: classes.map(a => a.id)} : {})

        network.post(`${config.base_path}/api/dashboard/coupon/add`, requestPayload)
            .then(response => {
                if (response.data.statusCode === 0) {
                    alert(`Coupon ${couponCode} successfully added`)
                    return setConfirmDialogOpen(false)
                }
                return alert("There is some error in the request. \n" + response.data.message)
            })
            .catch(err => {
                console.log(err);
                alert("There is some error in the request. \n" + err)
            });
    }

    function checkCouponDeal(value) {
        setCouponDeal(value)
        let tests = []
        let validityFlag = true;
        for (let i = 0; i < 5; i++) {
            const price = Math.pow(10, i)
            const currency = "INR"
            try {
                const re = processCoupon({deal: value}, price, currency)
                tests.push(<>
                    <b>{i + 1}. Original price:</b> {price} {currency} <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;new price: {re.price} <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;<b style={{color: 'green'}}>Description: {re.description}</b><br/><br/>
                </>)
            } catch (e) {
                tests.push(<>
                    <b>{i + 1}. Original price:</b> {price} {currency}
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <b style={{color: 'red'}}>Description: {e.message}</b><br/><br/>
                </>)
                validityFlag = false;
            }
        }

        setIsDealValid(validityFlag)
        setCouponRulesCheckerText(tests)
    }

    return (<>
        <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                    <Typography>Generate Single Coupon</Typography>
                    <TextField
                        required
                        fullWidth
                        name="couponCode"
                        placeholder={"Enter a unique coupon code"}
                        value={couponCode}
                        onChange={(e: any) => setCouponCode(e.target.value)}
                        label={<span>Enter a unique coupon code</span>}
                    />
                    <TextField
                        required
                        fullWidth
                        name="couponDeal"
                        placeholder={"Enter Coupon Deal"}
                        value={couponDeal}
                        onChange={(e: any) => checkCouponDeal(e.target.value)}
                        label={<span>Enter Coupon Deal</span>}
                    />
                    <MultiChipSelectV2
                        preSelectedItems={undefined}
                        onSelectedItemsChanged={(items) => setCouponFor(items)}
                        defaultItems={couponDeliverableTypeFilter}
                        label={<span>Coupon For</span>}
                    />
                    {showClassFilter && <MultiChipSelectV2
                        onSelectedItemsChanged={(items) => setSelectedUnluclass(items)}
                        defaultItems={unluclasses}
                        label={<span>unluclasses</span>} preSelectedItems={undefined}/>}
                    {showFellowshipFilter && <MultiChipSelectV2
                        preSelectedItems={undefined}
                        onSelectedItemsChanged={(items) => setSelectedFellowship(items)}
                        defaultItems={fellowships}
                        label={<span>Fellowships</span>}
                    />}
                    {showProgramFilter && <MultiChipSelectV2
                        preSelectedItems={undefined}
                        onSelectedItemsChanged={(items) => setSelectedProgram(items)}
                        defaultItems={program}
                        label={<span>program</span>}
                    />}
                    <DatePicker
                        inputFormat={"DD/MM/YYYY"}
                        value={startDate}
                        disablePast
                        renderInput={(props) => <TextField style={{flexGrow: 1,}} {...props} />}
                        onChange={value => setStartDate(value)}
                        label={<span>Start Date</span>}
                    />
                    <DatePicker
                        inputFormat={"DD/MM/YYYY"}
                        value={endDate}
                        disablePast
                        minDate={startDate}
                        renderInput={(props) => <TextField style={{flexGrow: 1,}} {...props} />}
                        onChange={value => setEndDate(value)}
                        label={<span>Expiry Date</span>}
                    />
                    <TextField
                        required
                        fullWidth
                        type={"number"}
                        name="totalCoupons"
                        value={totalCoupons}
                        placeholder={"Enter Number of times it can be used"}
                        onChange={(e: any) => setTotalCoupons(e.target.value)}
                        label={<span>Enter Number of times it can be used</span>}
                        id="tag"
                    />
                    <Button variant="contained" color="primary"
                            onClick={() => confirmCouponCreation()}>Add</Button>
                </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
                <Typography>Coupon deal Validator</Typography>
                <Typography>
                    {couponRulesCheckerText}
                </Typography>
            </Grid>
        </Grid>
        <DialogBox closeBox={() => setConfirmDialogOpen(false)}
                   open={confirmDialogOpen}
                   confirm={() => createCouponApiCall()}
                   heading={'Please check all the fields and confirm'}
                   text={confirmDialogContent} cancelText={'Cancel'}
                   acceptText={'Create Single Coupon'}/>
    </>)
}

export default SingleCoupon
