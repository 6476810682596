import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {CastList, Script, ScriptNode, WritingMode} from "./DataTypes";

interface IEditorState {
    focus: any
    storeFocus: any,
    updateFocus: any,
    moveFocus: any,
    restoreFocus: any,
    currentWritingMode: WritingMode
    setCurrentWritingMode: any,
    changeWritingMode: WritingMode
    setChangeWritingMode: any,
    createP: any,
    // renderNode: any,
    getNodeInfoOrNullFromId: any,
    getNodeInfoOrNullFromNode: any
    getNextModeOnEnterKey: any
    getCaretCoordinates: any
    saveData: boolean,
    setSaveData: any,
    data: any,
    setData: any,
    castList: CastList,
    setCastList: any
}

type IEditorContext = IEditorState


const EditorContext = React.createContext<IEditorContext>({
    focus: null,
    storeFocus: console.log.bind(null, "storeFocus"),
    updateFocus: console.log.bind(null, "updateFocus"),
    moveFocus: console.log.bind(null, "moveFocus"),
    restoreFocus: console.log.bind(null, "restoreFocus"),
    currentWritingMode: WritingMode.Scene,
    setCurrentWritingMode: console.log.bind(null, "setCurrentWritingMode"),
    changeWritingMode: WritingMode.Scene,
    setChangeWritingMode: console.log.bind(null, "setChangeWritingMode"),
    createP: console.log.bind(null, "createP"),
    getNodeInfoOrNullFromId: console.log.bind(null, "getNodeInfoOrNullFromId"),
    getNodeInfoOrNullFromNode: console.log.bind(null, "getNodeInfoOrNullFromNode"),
    getNextModeOnEnterKey: console.log.bind(null, "getNextModeOnEnterKey"),
    getCaretCoordinates: console.log.bind(null, "getCaretCoordinates"),
    saveData: false,
    setSaveData: console.log.bind(null, "setSaveData"),
    data: [],
    setData: console.log.bind(null, "setData"),
    castList: [],
    setCastList: console.log.bind(null, "setCastList"),
});

export function useEditor() {
    const context = useContext(EditorContext)
    return context
}


export const EditorProvider = (props: { children, data?: Script, castList?: CastList }) => {

    const focus = useRef<[string, number, string, number] | null>(null)
    const [currentWritingMode, setCurrentWritingMode] = useState<WritingMode>(WritingMode.Scene)
    const [changeWritingMode, setChangeWritingMode] = useState<WritingMode>(WritingMode.Scene)
    const [saveData, setSaveData] = useState(false)
    const [data, setData] = useState(props.data)
    const [castList, setCastList] = useState(props.castList)

    useEffect(() => {
        setData(props.data || [])
    }, [props.data])

    useEffect(() => {
        setCastList(props.castList || [])
    }, [props.castList])

    function createP(type, data, id) {
        const p = document.createElement("p");
        p.id = id
        p.innerHTML = data
        p.className = type
        p.setAttribute("node-type", type)
        switch (type) {
            case WritingMode.Action:
                p.setAttribute("data-placeholder", "Write an action line")
                break;
            case WritingMode.Character:
                p.setAttribute("data-placeholder", "Write a name of the character")
                break;
            case WritingMode.Dialogue:
                p.setAttribute("data-placeholder", "Write a dialogue")
                break;
            case WritingMode.General:
                p.setAttribute("data-placeholder", "Use this for adding information that doesnt fall into any other category")
                break;
        }
        return p
    }


    function getNodeInfoOrNullFromNode(element: Node | EventTarget): { type: WritingMode, data: string, id: string } | null {
        console.log("getNodeInfoOrNullFromNode")
        if (!(element instanceof Node)) return null
        console.log("nodeType=>", element.nodeType)
        switch (element.nodeType) {
            case 1:
                if (element instanceof HTMLParagraphElement) {
                    return {
                        type: element.getAttribute("node-type") as WritingMode,
                        data: element.innerHTML,
                        id: element.id
                    }
                }
            case 3:
                console.log(element.parentElement)
                return getNodeInfoOrNullFromNode(element.parentElement)
            default:
                console.log(`unexpected node type ${element.nodeType}`, element)
                break;
        }
        return null
    }

    function getNodeInfoOrNullFromId(id: string) {
        let element = document.getElementById(id)
        return {
            type: element.getAttribute('type') as WritingMode,
            data: element.innerHTML,
            id: id
        }
        return null;
    }

    function storeFocus() {
        console.log("storeFocus")
        const selection = window.getSelection()
        const info1 = getNodeInfoOrNullFromNode(selection.anchorNode)
        const info2 = getNodeInfoOrNullFromNode(selection.focusNode)
        if (info1 && info2) {
            focus.current = [info1.id, selection.anchorOffset, info2.id, selection.focusOffset]
        } else {
            console.log("instanceOf", selection)
        }
    }

    function updateFocus(id: string) {
        console.log("updateFocus")
        if (focus.current) {
            const oldAnchorNodeInfo = getNodeInfoOrNullFromId(focus.current[0])
            const oldFocusNodeInfo = getNodeInfoOrNullFromId(focus.current[2])
            const newFocus: [string, number, string, number] = [oldAnchorNodeInfo.id, focus.current[1], oldFocusNodeInfo.id, focus.current[3]]
            focus.current = newFocus
        }
    }

    function moveFocus(id: string) {
        console.log("moveFocus")
        if (focus.current) {
            //check if range or caret and on the basis of that move focus
            const newFocus: [string, number, string, number] = [id, 0, id, 0]
            focus.current = newFocus
        }
    }

    function restoreFocus() {
        try {
            if (focus.current) {
                console.log("restoreFocus", focus.current[0], focus.current[2])
                const range = document.createRange();
                const startEle = document.getElementById(focus.current[0])
                const endEle = document.getElementById(focus.current[2])
                if (!startEle || !endEle)
                    return
                range.setStart(startEle.innerText.length > 0 ? startEle.firstChild : startEle, focus.current[1]);
                range.setEnd(endEle.innerText.length > 0 ? endEle.firstChild : endEle, focus.current[3]);
                range.collapse(focus.current[0] === focus.current[2] && focus.current[1] === focus.current[3])
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
                focus.current = null;
            }
        } catch (e) {
            console.log("Failed to restore focus", e.message)
        }
    }


    function getNextModeOnEnterKey(current: WritingMode): WritingMode {
        switch (current) {
            case WritingMode.Scene:
                return WritingMode.Action
            case WritingMode.Action:
                return WritingMode.Character
            case WritingMode.Character:
                return WritingMode.Dialogue;
            case WritingMode.Dialogue:
                return WritingMode.Action
            default:
                return current
        }
    }

    function getCaretCoordinates() {
        let x = 0,
            y = 0;
        const isSupported = typeof window.getSelection !== "undefined";
        if (isSupported) {
            const selection = window.getSelection();
            // Check if there is a selection (i.e. cursor in place)
            if (selection.rangeCount !== 0) {
                // Clone the range
                const range = selection.getRangeAt(0).cloneRange();
                // Collapse the range to the start, so there are not multiple chars selected
                range.collapse(true);
                // getCientRects returns all the positioning information we need
                const rect = range.getClientRects()[0];
                if (rect) {
                    x = rect.left; // since the caret is only 1px wide, left == right
                    y = rect.top; // top edge of the caret
                }
            }
        }
        return {x, y};
    }


    const contextData: IEditorContext = {
        focus,
        storeFocus,
        updateFocus,
        moveFocus,
        restoreFocus,
        currentWritingMode,
        setCurrentWritingMode,
        changeWritingMode,
        setChangeWritingMode,
        createP,
        getNodeInfoOrNullFromNode,
        getNodeInfoOrNullFromId,
        getNextModeOnEnterKey,
        getCaretCoordinates,
        saveData,
        setSaveData,
        data,
        setData,
        castList,
        setCastList
    }


    return (
        <EditorContext.Provider value={contextData}>
            {props.children}
        </EditorContext.Provider>
    );
}
