import {currencyConverter} from "./currency_utils";

const local = false

function tester(code, price, currency) {
    try {
        const resp = processCoupon({deal: code}, price, currency)
        console.log(`${price}, ${code}, ${resp.price}, ${resp.description}`)
    } catch (e) {
        console.log(`${price}, ${code},"Error", ${e.message}`)
    }
}

export default function processCoupon(coupon, price_currency, currency) {
    let coupon_description = "";
    const flatX = coupon.deal.match(/^flat(\d+)$/)//flat100
    const flatXUptoYPercent = coupon.deal.match(/^flat(\d+)upto(\d+)%$/)//flat100upto20%
    const flatXPercent = coupon.deal.match(/^flat(\d+)%$/)//flat10%
    const flatXPercentUptoY = coupon.deal.match(/^flat(\d+)%upto(\d+)$/)//flat20%upto300

    const flatXPercentUptoYPurchaseZ = coupon.deal.match(/^flat(\d+)%upto(\d+)purchase(\d+)$/)//flat20%upto300
    const flatXUptoYPercentPurchaseZ = coupon.deal.match(/^flat(\d+)upto(\d+)%purchase(\d+)$/)//flat20%upto300
    const flatXPercentPurchaseZ = coupon.deal.match(/^flat(\d+)%purchase(\d+)$/)//flat20%upto300
    const flatXPurchaseZ = coupon.deal.match(/^flat(\d+)purchase(\d+)$/)//flat20%upto300

    if (flatX) {
        const x_inr = flatX[1]
        const x_currency = currencyConverter(x_inr, "INR", currency)
        price_currency = price_currency - x_currency;
        coupon_description = `Flat ${x_currency}${currency} off`
    } else if (flatXPercent) {
        const x_percent = flatXPercent[1]
        const x_currency = (price_currency * x_percent) / 100
        price_currency = price_currency - x_currency;
        coupon_description = `Flat ${x_percent}% off`
    } else if (flatXUptoYPercent) {
        const x_inr = flatXUptoYPercent[1]
        const y_percent = flatXUptoYPercent[2]
        const x_currency = currencyConverter(x_inr, "INR", currency)
        const converted_amount_in_currency = Math.min(x_currency, price_currency * y_percent / 100)
        price_currency = price_currency - converted_amount_in_currency;
        coupon_description = `Flat ${x_currency}${currency} off upto ${y_percent}%`
    } else if (flatXPercentUptoY) {
        const x_percent = flatXPercentUptoY[1]
        const y_inr = flatXPercentUptoY[2]
        const y_currency = currencyConverter(y_inr, "INR", currency)

        const converted_amount = Math.min(price_currency * x_percent / 100, y_currency)
        price_currency = price_currency - converted_amount;
        coupon_description = `Flat ${x_percent}% off upto ${y_currency}${currency}`
    } else if (flatXPurchaseZ) {
        const x_inr = flatXPurchaseZ[1]
        const z_inr = flatXPurchaseZ[2]
        const x_currency = currencyConverter(x_inr, "INR", currency)
        const z_currency = currencyConverter(z_inr, "INR", currency)

        if (price_currency < z_currency)
            throw new Error("This Coupon code isn't applicable on this order")
        price_currency = price_currency - x_currency;
        coupon_description = `Flat ${x_currency}${currency} off`
    } else if (flatXPercentPurchaseZ) {
        const x_percent = flatXPercentPurchaseZ[1]
        const z_inr = flatXPercentPurchaseZ[2]
        const z_currency = currencyConverter(z_inr, "INR", currency)

        if (price_currency < z_currency)
            throw new Error("This Coupon code isn't applicable on this order")
        const converted_amount = price_currency * x_percent / 100
        price_currency = price_currency - converted_amount;
        coupon_description = `Flat ${x_percent}% off`
    } else if (flatXUptoYPercentPurchaseZ) {
        const x_inr = flatXUptoYPercentPurchaseZ[1]
        const x_currency = currencyConverter(x_inr, "INR", currency)
        const y_percent = flatXUptoYPercentPurchaseZ[2]
        const z_inr = flatXUptoYPercentPurchaseZ[3]
        const z_currency = currencyConverter(z_inr, "INR", currency)

        if (price_currency < z_currency)
            throw new Error("This Coupon code isn't applicable on this order")
        const converted_amount = Math.min(x_currency, price_currency * y_percent / 100)
        price_currency = price_currency - converted_amount;
        coupon_description = `Flat ${x_currency}${currency} off upto ${y_percent}%`
    } else if (flatXPercentUptoYPurchaseZ) {
        const x_percent = flatXPercentUptoYPurchaseZ[1]
        const y_inr = flatXPercentUptoYPurchaseZ[2]
        const y_currency = currencyConverter(y_inr, "INR", currency)
        const z_inr = flatXPercentUptoYPurchaseZ[3]
        const z_currency = currencyConverter(z_inr, "INR", currency)
        if (price_currency < z_currency)
            throw new Error("This Coupon code isn't applicable on this order")
        const converted_amount = Math.min(price_currency * x_percent / 100, y_currency)
        price_currency = price_currency - converted_amount;
        coupon_description = `Flat ${x_percent}% off upto ${y_currency}${currency}`
    } else
        throw new Error("This Coupon code is invalid")

    if (price_currency < 0)
        price_currency = 0
    return {price: price_currency, description: coupon_description};
}

if (local) {
    tester("flat20%", 1999, "INR")
    tester("flat500", 1999, "INR")
    tester("flat20%upto3000", 1999, "INR")
    tester("flat500upto30%", 1999, "INR")
    tester("flat1000purchase5000", 1999, "INR")
    tester("flat20%purchase5000", 1999, "INR")
    tester("flat20%upto3000purchase5000", 1999, "INR")
    tester("flat2000upto30%purchase5000", 1999, "INR")
    tester("flat20%", 19999, "INR")
    tester("flat300", 19999, "INR")
    tester("flat30%upto3000", 19999, "INR")
    tester("flat400upto50%", 19999, "INR")
    tester("flat1000purchase5000", 19999, "INR")
    tester("flat20%purchase5000", 19999, "INR")
    tester("flat20%upto3000purchase5000", 19999, "INR")
    tester("flat2000upto30%purchase5000", 19999, "INR")

    tester("flat20%", 1999, "USD")
    tester("flat500", 1999, "USD")
    tester("flat20%upto3000", 1999, "USD")
    tester("flat500upto30%", 1999, "USD")
    tester("flat1000purchase5000", 1999, "USD")
    tester("flat20%purchase5000", 1999, "USD")
    tester("flat20%upto3000purchase5000", 1999, "USD")
    tester("flat2000upto30%purchase5000", 1999, "USD")
    tester("flat20%", 19999, "USD")
    tester("flat300", 19999, "USD")
    tester("flat30%upto3000", 19999, "USD")
    tester("flat400upto50%", 19999, "USD")
    tester("flat1000purchase5000", 19999, "USD")
    tester("flat20%purchase5000", 19999, "USD")
    tester("flat20%upto3000purchase5000", 19999, "USD")
    tester("flat2000upto30%purchase5000", 19999, "USD")
}
