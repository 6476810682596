import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import {useParams} from "react-router-dom";
import CreateCampaign from "./create";
import {tellSlack} from "../../../../network/apis";
import {toast} from "react-toastify"
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import LongMenu from "../../../../components/custom/LongMenu";
import CopyIDButton from "../../../../components/custom/CopyIdButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

export default function ListCampaigns(props) {
    const params = useParams()
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'))

    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    let campSlug = null

    async function loadFunc(page) {
        const fellowships = await network.get(`${config.base_path}/api/dashboard/campaign/list/${page}`)
        setHasMore(fellowships.data.payload.length !== 0)
        setItems(fellowships.data.payload)
    }

    async function onSave(mainData, diff) {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/campaign/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited successfully", {autoClose: 1000})
    }

    function Campaign(props) {

        const cUrl = (slug) => {
            if (slug.includes('fellowship_')) {
                const res = slug.split('fellowship_')[1]
                return `fellowship/${res}`
            } else if (slug.includes('class_')) {
                const res = slug.split('class_')[1]
                return `class/${res}`
            } else return slug
        }

        if (props.slug.includes('fellowship_' || 'class_')) {
            if (props.slug.includes('fellowship_'))
                campSlug = `marketing-fellowship-${props.slug}`
            else campSlug = `marketing-class-${props.slug}`
        } else campSlug = `marketing-${props.slug}`

        const option = [
            {
                item: 'Get Leads',
                redirect: `/marketing/leads/${props.slug}`
            }
        ]


        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Stack direction={matchMobile ? "column" : "row"} alignItems={'center'}>
                    <Stack spacing={1}>
                        <CopyIDButton id={props._id}/>
                        <p>Slug: {props.slug}</p>
                        {campSlug && <p>LS source: {props?.lead_source || campSlug}</p>}
                        <Link target={'_blank'} href={`https://mugafi.com/lp/${cUrl(props.slug)}`}>
                            Go to page
                        </Link>
                    </Stack>
                    <Stack sx={{marginLeft: matchMobile ? '0px' : 'auto'}} direction={'row'} alignItems={'center'}>
                        <ButtonGroup sx={{maxHeight: '32px'}}>
                            <Button variant={"outlined"} onClick={() => setEditObject(props)}>Edit</Button>
                        </ButtonGroup>
                        <LongMenu options={option}/>
                    </Stack>
                </Stack>
            </Card>
        </Grid>
    }

    return <BasePage>
        <CreateCampaign/>
        <CDataTable
            pageUrl={"/marketing/campaign/list/"}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}>
            {items.map((a, index) => <Campaign key={index} {...a}/>)}
        </CDataTable>
        {!!editObject && <EditFormDrawer onSave={onSave} onClose={() => setEditObject(null)} open={!!editObject}
                                         schema={editObject}/>}
    </BasePage>
}
