import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import {useParams} from "react-router-dom";
import {tellSlack} from "network/apis";
import {toast} from "react-toastify"
import {useNavigate} from "react-router-dom";
import CreateRoom from "../fellowship/_class_id/rooms/create";


function WorkRoom(props) {
    const navigate = useNavigate()
    return <Grid item xs={12}>
        <Card style={{margin: "8px", padding: "8px", alignItems: 'center'}}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <img src={props?.image_url} alt={''} width={'70px'} height={'70px'}/>
                <Stack spacing={'0px'}>
                    <p>Title: <b>{props?.title}</b></p>
                    <p>slug: <b>{props?.slug}</b></p>
                    <p>Total seats: <b>{props?.total_seats}</b></p>
                </Stack>
                <Button variant={"outlined"} color={"success"} onClick={props.onClick}
                        style={{marginLeft: 'auto'}}>Edit</Button>
            </Stack>
        </Card>
    </Grid>
}

export default function WorkRooms(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()

    async function loadFunc(page) {
        const fellowships = await network.get(`${config.base_path}/api/community/dashboard/rooms?page=${page}`)
        setHasMore(fellowships.data.payload.length !== 0)
        setItems(fellowships.data.payload)
    }

    async function handleClick(mainData, diff) {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        const res = await network.post(`${config.base_path}/api/community/dashboard/rooms/${mainData.id}`, diff)
        if (res.data.status !== 0)
            toast.error(res.data.message, {autoClose: 1000})
        else {
            toast.success("Edited Successfully", {autoClose: 1000})
            setEditObject(null)
            loadFunc(params.page)
        }
    }

    return <BasePage>
        <CreateRoom/>
        <CDataTable
            pageUrl={`/rooms/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <WorkRoom key={index} {...a} onClick={() => setEditObject(a)}/>)}/>
        {!!editObject &&
            <EditFormDrawer id='rooms' title='Edit Works rooms' onSave={handleClick} onClose={() => setEditObject(null)}
                            open={!!editObject}
                            schema={editObject}/>}
    </BasePage>
}
