import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import IconButton from "@mui/material/IconButton";
import {toast} from 'react-toastify';


export default function AddUser(props) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNo, setPhoneNo] = useState('')
    const [password, setPassword] = useState('')
    const [username, setUsername] = useState('')
    const [hidden, setHidden] = useState(false)
    const [registrar, setRegistrar] = useState('password')
    const [errorEmailMessage, setErrorEmailMessage] = useState(null)
    const [errorNameMessage, setErrorNameMessage] = useState(null)
    const [errorNoMessage, setErrorNoMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [artist, setArtist] = useState(false)

    const submitButtonEnabled = (name && username && password) && (!errorNameMessage)


    useEffect(() => {
        if (!email) return setErrorEmailMessage(null);
        const emailValidation = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(String(email).toLowerCase())

        if (!emailValidation)
            setErrorEmailMessage('Please Enter Valid Email')
        else
            setErrorEmailMessage(null)

    }, [email])
    useEffect(() => {
        if (!name) return setErrorNameMessage(null);
        const nameValidation = (/^[a-zA-Z]/).test(String(name).toLowerCase())

        if (!nameValidation)
            setErrorNameMessage('Please Enter Valid Name')
        else
            setErrorNameMessage(null)

    }, [name])


    useEffect(() => {
        if (!phoneNo) return setErrorNoMessage(null);
        const noValidation = (/(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/).test(phoneNo) && phoneNo.length === 13

        if (!noValidation)
            setErrorNoMessage('Please Enter Valid Number')
        else
            setErrorNoMessage(null)

    }, [phoneNo])

    function navigate(path) {
        window.location.assign(path)
    }

    function cancel() {
        navigate("/");
    }

    function addUser() {
        if (!artist) {
            if (!name || !username || !email || !password)
                return setErrorMessage('Mandatory fields are required ')
        } else {
            if (!name || !username || !password)
                return setErrorMessage('Mandatory fields are required ')
        }

        if (username.length < 6 || username.length > 23) {
            alert("Usernamer should be between 6-23 characters long")
        }
        network.post(`${config.base_path}/api/user/register`, {
            name: name,
            access_token: password,
            username: username,
            registrar: registrar,
            email: email,
            user_type: !!artist ? 2 : 0,
            phone_number: phoneNo
        })
            .then(response => {
                if (response?.data?.statusCode !== 0) {
                    toast(response.data.message)
                }
            })
            .catch(err => {
                toast("There is some error in the request", err.data.message);
            });
    }

    function toggleShow() {
        setHidden(!hidden)
    }

    return (
        <BasePage>
            <Button variant='contained' color='primary'
                    onClick={() => setArtist(!artist)}>{!artist ? 'Add Artist' : 'Add Creator'}</Button>
            <Typography style={{marginTop: '20px'}}>
                {artist ? 'Add Artist Account' : 'Add Creator Account'}
            </Typography>
            <div>
                <div>

                    <form noValidate>
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            error={!!errorNameMessage}
                            helperText={errorNameMessage}
                            defaultValue={name}
                            name="name"
                            onChange={(e: any) => setName(e.target.value)}
                            id="name"
                            label={"Name"}
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            defaultValue={username}
                            name="username"
                            onChange={(e: any) => setUsername(e.target.value)}
                            id="username"
                            label={"Username"}
                        />
                        <TextField
                            variant="standard"
                            margin="normal"

                            fullWidth
                            name="email"
                            defaultValue={email}
                            error={!!errorEmailMessage}
                            helperText={errorEmailMessage}
                            onChange={(e: any) => setEmail(e.target.value)}
                            id="email"
                            label={"Email"}
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            error={!!errorNoMessage}
                            helperText={errorNoMessage}
                            name="phone_number"
                            onChange={(e: any) => setPhoneNo(e.target.value)}
                            id="phone_number"
                            label={"Phone number"}
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            onChange={(e: any) => setPassword(e.target.value)}
                            id="password"
                            type={hidden ? "text" : "password"}
                            label={"Password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleShow}
                                            aria-label="toggle password visibility"
                                            size="large">
                                            {hidden ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </form>
                </div>
                <Typography color={'error'} style={{textAlign: 'center'}}>{errorMessage} </Typography>
                <Grid container justifyContent={"space-evenly"} spacing={2}>
                    <Grid item>
                        <Button onClick={() => cancel()} variant="outlined">
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={!submitButtonEnabled} variant="contained" color="primary" onClick={addUser}>
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </BasePage>
    );
};
