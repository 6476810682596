import React, {useEffect, useState} from 'react';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from "@mui/material/CircularProgress";
import network from "../../network";
import config from "../../config";
import {toast} from "react-toastify"
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import {useFetchPrograms} from "../common/fetchPrograms";
import InputLabel from "@mui/material/InputLabel";

const UNLU_BOT_COMMANDS = `
    Slack Bot Commands

    User Commands
        @unlubot user userame USERNAME          - Get User Details using Username
        @unlubot user id USER_ID                - Get User Details using user id
        @unlubot user phone USER_PHONE          - Get User Details using phone
        @unlubot user email USER_EMAIL          - Get User Details using email
        @unlubot user USER_ID set KEY VALUE     - Set User Details (name, email) using user id
        @unlubot user USER_ID get KEY           - Get User Relations (enrollments) using user id
        @unlubot user merge USER_1 USER_2       - Merge Users using id, email, phonenumber, username

    Coupon Commands
        @unlubot coupon coupon_code COUPON_CODE     - Get coupon details using coupon code
        @unlubot coupon COUPON_CODE set KEY VALUE   - Set coupon details (start_date, end_date, total_coupons) using coupon code
        @unlubot coupon COUPON_CODE get KEY         - Get coupon additional details (redemptions) using coupon code

        @unlubot batch_coupon batch BATCH           - Get coupon details using batch
        @unlubot batch_coupon BATCH set KEY VALUE   - Set coupon details (start_date, end_date [MM/DD/YYYY], total_coupons) using coupon code
        @unlubot batch_coupon BATCH get KEY         - Get coupon additional details (redemptions) using batch

    Enrollment Commands
        @unlubot enrollment id ENROLLMENT_ID        - Get enrollment using enrollment id
        @unlubot enrollment ENROLLMENT_ID cancel    - Cancel enrollment using enrollment id (Set payment status to refund initiated)
        @unlubot enrollment ENROLLMENT_ID refund    - Refund enrollment order using enrolment id (Set payment status to refund completed)

    Unluclass Commands
        @unlubot unluclass id UNLUCLASS_ID_SLUG         - Get Unluclass Details
        @unlubot unluclass UNLUCLASS_ID_SLUG get KEY    - Get Unluclass episodes (id, title, applications)
        @unlubot unluclass UNLUCLASS_ID_SLUG enroll USER_ID     - Get Unluclass episodes (id, title, applications)
        @unlubot episode EPISODE_ID get                 - Get details of episode (id, title, urls, assignment)
        @unlubot episode EPISODE_ID set KEY VALUE       - Set episode details (title, assignment)
        @unlubot application APPLICATION_ID accept      - Accept unlu program application
        @unlubot application APPLICATION_ID reject      - Reject unlu program application

    Community Commands
        @unlubot community group GROUP_ID user USER_ID add permissions PERMISSIONS  - Add user permissions for a group
        @unlubot community group GROUP_ID user USER_ID remove permissions PERMISSIONS  - Remove user permissions for a group

    Export Commands
        @unlubot export EXPORT_KEY to EMAIL_ID          - Export details to email id (EXPORT_KEY - enrolled_users)

    Debug Commands
        @unlubot debug dump_payload NUMBER          - Get last n bot commands text

    Help Commands
        @unlubot help commands                      - Get help commands
`;

export async function executeBotCommand(command) {
    const payload = {
        "text": command
    }

    return await network.post(`${config.base_path}/api/dashboard/unlubot`, payload)
}

export default function UserEnrollments({drawerState, setDrawerState, user}) {
    const userId = user._id
    const username = user.username
    const [enrollments, setEnrollments] = useState([])
    const [openProgList, setOpenProgList] = useState(false)
    const [loading, setLoading] = useState(false)

    async function getEnrollments() {
        setLoading(true)
        const res = await executeBotCommand(`user ${userId} get enrollments`)
        if (!res.data.response.error) {
            const filteredEnrollments = res.data.response.filter(enrollment => enrollment.enrolled);
            setEnrollments(filteredEnrollments)
        } else toast.error('Error: ' + res.data.response.error)
        setLoading(false)
    }

    async function onCancelEnrollment(unluClass) {
        if (window.confirm(`Are you sure about to cancel the enrollment of ${username} for ${unluClass.class_id} ?`)) {
            setLoading(true)
            const res = await executeBotCommand(`enrollment ${unluClass.id} cancel`)
            if (!res.data.response.error) {
                toast.success('Enrollment cancelled')
                getEnrollments()
            } else toast.error('Error: ' + res.data.response.error)
            setLoading(false)
        }
    }

    useEffect(() => {
        getEnrollments()
    }, [])

    return <>
        <Drawer anchor="right"
                PaperProps={{sx: {width: "50%", padding: '24px 0px', alignItems: 'center'}}}
                open={drawerState}
                onClose={() => setDrawerState(false)}>
            <Typography variant="h5" align="center" sx={{marginBottom: '20px'}}>
                User Enrollments
            </Typography>
            {loading ? <CircularProgress/> :
                <>
                    {enrollments?.length === 0 ? <Stack direction={'row'}>
                            <SentimentVeryDissatisfiedIcon/>
                            <Typography>
                                No enrollments found for this user.
                            </Typography>
                        </Stack> :
                        <Stack sx={{width: '100%'}} padding={'16px 32px'} spacing={1}>
                            {enrollments?.map((enrollment, index) => (
                                <Stack key={index} direction={'row'} spacing={2} alignItems={'center'}
                                       sx={{maxWidth: '100%', justifyContent: 'space-between'}}>
                                    <Typography variant="body1" sx={{maxWidth: '60%'}}>
                                        <b>{index + 1}.</b> {enrollment.class_id}
                                    </Typography>
                                    <Button variant={'outlined'} color={'error'} sx={{maxWidth: '40%'}}
                                            onClick={() => onCancelEnrollment(enrollment)}>
                                        cancel enrollment
                                    </Button>
                                </Stack>
                            ))}
                        </Stack>
                    }
                    <Button variant={'outlined'} color={'success'} onClick={() => setOpenProgList(true)}>
                        Assign Enrollment
                    </Button>
                </>
            }
        </Drawer>
        {openProgList && <ListOfRunningPrograms open={openProgList} userId={userId}
                                                onClose={() => {
                                                    setOpenProgList(false)
                                                    getEnrollments()
                                                }}/>
        }
    </>
};

function ListOfRunningPrograms({open, onClose, userId}) {
    const {fellowships, program, unluclasses} = useFetchPrograms(true);
    const [loading, setLoading] = useState(false)
    const [selectedType, setSelectedType] = useState('unluclass');
    const [selectedItem, setSelectedItem] = useState(null)

    async function onProvidingEnrollment() {
        if (window.confirm(`Confirmation to provide enrollment for ${selectedItem.label} ?`)) {
            setLoading(true)
            const res = await executeBotCommand(`unluclass ${selectedItem.id} enroll ${userId}`)
            if (!res.data.response.error) {
                toast.success('Enrollment provided')
                setLoading(false)
                onClose()
            } else toast.error('Error: ' + res.data.response.error)
            setLoading(false)
        }
    }

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedType(event.target.value);
        setSelectedItem(null);
    };

    return <Dialog open={open} onClose={onClose}
                   aria-labelledby="responsive-dialog-title">
        <Stack padding={4} spacing={2}>
            <Typography><b>Please choose one program that will be available for user access :</b></Typography>
            {loading ? <CircularProgress/> :
                <>
                    <InputLabel>Enroll for : </InputLabel>
                    <Select onChange={handleChange}
                            value={selectedType}>
                        <MenuItem value={"unluclass"}>Unluclass</MenuItem>
                        <MenuItem value={"fellowship"}>Fellowship</MenuItem>
                        <MenuItem value={"program"}>Program</MenuItem>
                    </Select>
                </>
            }
            {selectedType === "unluclass" &&
                <Select onChange={(event) => setSelectedItem(event.target.value)}>
                    {unluclasses.map((item, index) => <MenuItem key={index} value={item}>
                            {item.label}
                        </MenuItem>
                    )}
                </Select>
            }
            {selectedType === "fellowship" &&
                <Select onChange={(event) => setSelectedItem(event.target.value)}>
                    {fellowships.map((item, index) => <MenuItem key={index} value={item}>
                            {item.label}
                        </MenuItem>
                    )}
                </Select>
            }
            {selectedType === "program" &&
                <Select onChange={(event) => setSelectedItem(event.target.value)}>
                    {program.map((item, index) => <MenuItem key={index} value={item}>
                            {item.label}
                        </MenuItem>
                    )}
                </Select>
            }
            {selectedItem && <Button variant={'outlined'} onClick={onProvidingEnrollment}>Proceed</Button>}
        </Stack>
    </Dialog>
}