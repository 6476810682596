import React, { useEffect, useState } from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useParams, useNavigate } from "react-router-dom";


export default function ManageArtist(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const user_type = 2
    const params = useParams()
    const navigate = useNavigate()

    async function loadFunc(page) {
        const sett = await network.get(`${config.base_path}/api/dashboard/user/list/${page}?user_type=${user_type}`)
        setHasMore(sett.data.payload.length !== 0)
        setItems(sett.data.payload)
    }


    function Users(props) {
        return <Grid item xs={12}>
            <Card style={{ margin: "8px", padding: "8px" }}>
                <Grid container direction={"row"}>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Grid item xs={12}>Artist Name: <b>{props.name}</b></Grid>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Grid item xs={12}>
                            <ButtonGroup>
                                <Button variant={"outlined"}
                                    onClick={() => navigate(`/sync/artists/${props.id}/assets/1`)}>
                                    Assets
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    return <BasePage>
        <CDataTable
            pageUrl={`/sync/artists/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <Users key={index} {...a} />)} />
    </BasePage>
}
