import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {executeBotCommand} from "../../components/users/UserEnrollments";
import {toast} from "react-toastify";
import {useState} from "react";
import Stack from "@mui/material/Stack";
import BasePage from "../../components/BasePage";

export default function AWSMediaConvert() {
    const [link, setLink] = useState('')
    const [loader, setLoader] = useState(false)

    async function handleButtonClick() {
        setLoader(true)
        const res = await executeBotCommand(`episode process_video ${link} 0 0 0`)
        setLoader(false)
        if (!res.data.response?.error) {
            toast.success(res.data.response)
        } else toast.error(res.data.response.error)
    }

    if (loader)
        return <Stack>
            <b>Please wait a while</b>
        </Stack>

    return <BasePage>
        <TextField
            label="Enter Text"
            variant="outlined"
            margin="normal"
            placeholder={"Add S3 link here"}
            sx={{width: '90%'}}
            value={link}
            onChange={(e) => setLink(e.target.value)}
        />
        <br/>
        <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            disabled={!link}
        >
            Submit
        </Button>
    </BasePage>
}