import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Drawer from "@mui/material/Drawer";
import {Select, styled} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, {createContext, useContext, useEffect, useState} from "react";
import _set from "lodash/set";
import _get from "lodash/get";
import _clone from "lodash/cloneDeep";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import CloseIcon from "@mui/icons-material/Close";
import ButtonGroup from "@mui/material/ButtonGroup";
import Add from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import helperText from "utils/helper-form-drawer/helper_text";
import {dropdownOption, overrideType} from "utils/helper-form-drawer/helper_type_and_menuitem";
import network from "../../network";
import config from "../../config";
import Autocomplete from "@mui/material/Autocomplete";
import {DateTimePicker} from "@mui/x-date-pickers";

const trueTypeOf = (obj) => {
    const sysType = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
    if (sysType === "string" && moment(obj, true).isValid())
        return "date-string"
    return sysType;
};

const CStack = styled(Stack)(({theme}) => ({
    padding: "8px",
    width: "100vw",
    [theme.breakpoints.up("md")]: {
        width: "55vw",
        margin: "8xp",
    }
}))

const FormDataContext = createContext({
    mainData: null,
    setDataAtPath: null,
    setData: null,
    diff: null,
    path: [],
    label: "",
    id: ".."
});

function FormDataProvider(props: { onSave?: (path: string | string[], newValue: any, mainData: any, diffData?: any) => void, path: string[], label: string, children: any, id: string, value: any, hide?: boolean }) {
    const [mainData, setMainData] = useState(props.value)
    const [diff, setDiff] = useState({})

    function setDataAtPath(path, value) {
        const mainDataClone = _clone(mainData);
        const diffClone = _clone(diff)

        const originalValue = _get(props.value, path)
        const valueType = trueTypeOf(originalValue)
        let finalValue = value
        if (valueType === "number") {
            finalValue = parseInt(value)
        }

        const finalMainData = (path.length !== 0) ? _set(mainDataClone, path, finalValue) : finalValue
        const finalDiff = (path.length !== 0) ? _set(diffClone, path, finalValue) : finalValue
        setMainData(finalMainData)
        setDiff(finalDiff)

        props.onSave?.(path, value, finalMainData, finalDiff)
    }

    function setData(mainData, diff) {
        setMainData(mainData)
        setDiff(diff)
    }

    return <FormDataContext.Provider
        value={{path: props.path, mainData, diff, setData, setDataAtPath, label: props.label, id: props.id}}>
        {props.children}
    </FormDataContext.Provider>
}

function useFormData() {
    return useContext(FormDataContext)
}

export default function EditFormDrawer(props) {
    return <FormDataProvider value={props.schema} label={props.title || "Edit"} id={props.id || 'edit'} path={[]}>
        <EditFormDrawerInternal {...props} />
    </FormDataProvider>
}

function EditFormDrawerInternal(props) {
    const formData = useFormData()
    const [editable, setEditable] = useState(false)
    const [editAsJson, setEditAsJson] = useState(false)
    const [jsonAsTextHasError, setJsonAsTextHasError] = useState<boolean | string | null>("")
    const [mainDataAsJSON, setMainDataAsJSON] = useState(JSON.stringify(formData.mainData, null, 2))

    useEffect(() => {
        setMainDataAsJSON(JSON.stringify(formData.mainData, null, 2))
    }, [formData.mainData])


    function onSave() {
        props.onSave(formData.mainData, formData.diff)
    }

    return <Drawer anchor={"right"} open={props.open} variant={"temporary"} onClose={props.onClose}>
        <CStack justifyContent={"center"}>
            <Stack direction={"row-reverse"} style={{width: "100%"}}>
                <IconButton onClick={props.onClose}>
                    <Close/>
                </IconButton>
                <Button onClick={() => setEditAsJson(!editAsJson)}>Edit as JSON</Button>
                <Button onClick={() => setEditable(!editable)}>Change types</Button>
            </Stack>
            {!!editAsJson ?
                <TextField multiline label={"Text as json"}
                           error={!!jsonAsTextHasError}
                           helperText={jsonAsTextHasError}
                           defaultValue={mainDataAsJSON}
                           onChange={(e: any) => {
                               const rawJson = e.target.value;
                               try {
                                   const parsedJson = JSON.parse(rawJson)
                                   formData.setData(parsedJson, parsedJson)
                                   setJsonAsTextHasError(false)
                               } catch (e) {
                                   setJsonAsTextHasError(e.message)
                               }

                           }}/>
                : <FormItem editable={editable} label={formData.label} id={formData.id} value={formData.mainData}
                            path={[]}/>}
            <Stack direction={"row-reverse"} style={{padding: "8px"}}>
                <ButtonGroup>
                    <Button color={"error"} variant={"outlined"} onClick={() => props.onClose()}>Cancel</Button>
                    <Button color={"success"} variant={"outlined"}
                            onClick={() => onSave()}>{!editAsJson ? 'Save' : 'Save Json'}</Button>
                </ButtonGroup>
            </Stack>
        </CStack>
    </Drawer>
}

const optionValue = (option) => {
    switch (option) {
        case 'ROLE_NOT_ALLOWED':
            return 1
        case 'ROLE_HOST':
        case 'MEETING_ONE_TO_MANY':
        case 'RESOURCE_UNLUCLASS_ENROLLMENT':
            return 1;
        case 'ROLE_PARTICIPANT':
        case 'MEETING_MANY_TO_MANY' :
        case 'RESOURCE_USER':
            return 2;
        case 'RESOURCE_ALL_USERS':
            return 11;
        default:
            return;
    }
}

function FormItem(props) {
    const {id, label, value: data, path: path, style, nested, editable, ...other} = props
    const formData = useFormData();
    const [options, setOptions] = useState(dropdownOption(id, label) || [])
    const [formItemType, setFormItemType] = useState(overrideType(id, label))
    const [asset, setAsset] = useState([])
    const [users, setUsers] = useState([])


    const onUserInputChange = (val) => {
        network.get(config.base_path + "/api/community/search/members?search=" + val)
            .then((res) => setUsers(res?.data?.payload?.results || []))
    }

    const onInputChange = (val) => {
        network.get(config.base_path + "/api/v1/sync/assets?q=" + val)
            .then((res) => setAsset(res?.data?.payload?.results || []))
    }


    useEffect(() => {
        if (formItemType === '')
            setFormItemType(trueTypeOf(data))
        if (formItemType === 'artform-type') {
            network.get(config.base_path + "/api/artforms")
                .then((res) => setOptions(res?.data?.payload || []))
        }
        if (formItemType === 'unluclass-type') {
            network.get(`${config.base_path}/api/web/unluclass/1/get?class_type=510,520&page_size=50`)
                .then((res) => setOptions(res?.data?.payload || []))
        }
    }, [formItemType])

    switch (formItemType) {
        case "dropDown":
            return <FormDataProvider path={path} label={label} value={data} id={id}
                                     onSave={(localPath, newValue, mainData, diffData) => {
                                         formData.setDataAtPath(path, mainData)
                                     }}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label={props.label}
                    value={props.data}
                    onChange={(e) => formData.setDataAtPath(props.path, e.target.value)}
                    helperText={helperText(props.id, props.label)}
                >
                    {options?.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </FormDataProvider>
        case "meeting-type-dropDown":
        case "meeting-role":
        case "meeting-resource-type":
            return <FormDataProvider path={path} label={label} value={data} id={id}
                                     onSave={(localPath, newValue, mainData, diffData) => {
                                         formData.setDataAtPath(path, mainData)
                                     }}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label={props.label}
                    value={props.data}
                    onChange={(e) => formData.setDataAtPath(props.path, e.target.value)}
                    helperText={helperText(props.id, props.label)}
                >
                    {options?.map((option, index) => (
                        <MenuItem key={index} value={optionValue(option)}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </FormDataProvider>
        case "unlu-user":
            return <FormDataProvider path={path} label={label} value={data} id={id}
                                     onSave={(localPath, newValue, mainData, diffData) => {
                                         formData.setDataAtPath(path, mainData)
                                     }}>
                <Autocomplete
                    options={users}
                    onChange={(e, newValue) => formData.setDataAtPath(props.path, newValue.id)}
                    onInputChange={(e: any) => onUserInputChange(e.target.value)}
                    filterSelectedOptions
                    autoHighlight
                    getOptionLabel={option => option.username}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={props.label}
                            placeholder="select member"
                            fullWidth
                        />)}
                />
            </FormDataProvider>
        case "asset-type":
            return <FormDataProvider path={path} label={label} value={data} id={id}
                                     onSave={(localPath, newValue, mainData, diffData) => {
                                         formData.setDataAtPath(path, mainData)
                                     }}>
                <Autocomplete
                    options={asset}
                    onChange={(e, newValue) => formData.setDataAtPath(props.path, newValue.id)}
                    onInputChange={(e: any) => onInputChange(e.target.value)}
                    filterSelectedOptions
                    autoHighlight
                    getOptionLabel={option => option?.title}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={props.label}
                            placeholder="select Asset"
                            fullWidth
                        />)}/>
            </FormDataProvider>
        case "artform-type":
        case "unluclass-type":
            return <TextField
                id="outlined-select-currency"
                select
                label={label}
                value={data}
                onChange={(e: any) => formData.setDataAtPath(path, e.target.value)}
                helperText={helperText(id, label)}
            >
                {options?.map((option: any) => (
                    <MenuItem key={option?.id} value={option?.id || option?._id}>
                        {option?.name || option?.title}
                    </MenuItem>
                ))}
            </TextField>
        case "array":
            return <FormDataProvider path={path} label={label} value={data} id={id}
                                     onSave={(localPath, newValue, mainData, diffData) => {
                                         formData.setDataAtPath(path, mainData)
                                     }}>
                <ArrayType editable={editable} open={!nested}/>
            </FormDataProvider>
        case "object":
            return <FormDataProvider hide={!editable} path={path} label={label} value={data} id={id}
                                     onSave={(localPath, newValue, mainData, diffData) => {
                                         if (!nested)
                                             formData.setDataAtPath([...path, ...localPath], newValue)
                                         else
                                             formData.setDataAtPath(path, mainData)
                                     }}>
                <ObjectType editable={editable} open={!nested}/>
            </FormDataProvider>
        case "string":
            return <Stack direction={"row"} columnGap={1} alignItems={"center"} alignContent={"center"}>
                <TextField {...other}
                           onChange={(e: any) => formData.setDataAtPath(path, e.target.value)}
                           color={"error"}
                           multiline
                           label={label}
                           id={id}
                           helperText={helperText(id, label)}
                           style={{flexGrow: 1, ...style}}
                           value={data}/>
                <ChangeTypeButton currentType={formItemType}
                                  hide={!editable}
                                  onTypeChanged={(type, defaultValue) => formData.setDataAtPath(path, defaultValue)}/>
            </Stack>
        case "date":
            return <Stack direction={"row"} columnGap={1} alignItems={"center"} alignContent={"center"}>
                <DateTimePicker {...other}
                                value={moment(data)}
                                id={id}
                                renderInput={(props) => <TextField style={{flexGrow: 1,}} {...props}
                                                                   helperText={helperText(id, label)}/>}
                                onChange={(value: any) => formData.setDataAtPath(path, value)}
                                style={{...style}}
                                label={label}/>
                <ChangeTypeButton currentType={formItemType}
                                  hide={!editable}
                                  onTypeChanged={(type, defaultValue) => formData.setDataAtPath(path, defaultValue)}/>
            </Stack>
        case "date-string":
            return <Stack direction={"row"} columnGap={1} alignItems={"center"} alignContent={"center"}>
                <DateTimePicker {...other}
                                value={moment(data)}
                                id={id}
                                renderInput={(props) => <TextField style={{flexGrow: 1,}} {...props}
                                                                   helperText={helperText(id, label)}/>}
                                onChange={(value: any) => formData.setDataAtPath(path, value)}
                                style={{...style}}
                                label={label}/>
                <ChangeTypeButton currentType={formItemType}
                                  hide={!editable}
                                  onTypeChanged={(type, defaultValue) => formData.setDataAtPath(path, defaultValue)}/>
            </Stack>
        case "number":
            return <Stack direction={"row"} columnGap={1} alignItems={"center"} alignContent={"center"}>
                <TextField
                    {...other}
                    value={data || ""}
                    id={id}
                    onChange={(e: any) => formData.setDataAtPath(path, !!e.target.value ? parseInt(e.target.value) : undefined)}
                    color={"primary"}
                    helperText={helperText(id, label)}
                    style={{flexGrow: 1, ...style}}
                    label={label}/>
                <ChangeTypeButton currentType={formItemType}
                                  hide={!editable}
                                  onTypeChanged={(type, defaultValue) => formData.setDataAtPath(path, defaultValue)}/>
            </Stack>
        case "function":
            return <Typography variant={"body1"}>{label}: function</Typography>
        case "regexp":
            return <Typography variant={"body1"}>{label}: regexp</Typography>
        case "boolean":
            return <Stack direction={"row"} columnGap={1} alignItems={"center"} alignContent={"center"}>
                <FormControlLabel
                    {...other}
                    style={{
                        marginLeft: 0,
                        marginRight: 0,
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "4px",
                        padding: "8px",
                        flexGrow: 1,
                        ...style
                    }}
                    control={<>
                        {helperText(id, label) !== '' ?
                            <Tooltip style={{position: 'absolute', right: '100px'}} title={helperText(id, label)}>
                                <InfoOutlinedIcon/>
                            </Tooltip> : null}
                        <Switch name="switch"
                                onChange={(e: any) => formData.setDataAtPath(path, e.target.checked)}
                                checked={data}/>
                    </>}
                    labelPlacement={"start"}
                    componentsProps={{typography: {style: {flexGrow: 1}}}}
                    label={label}/>
                <ChangeTypeButton currentType={formItemType}
                                  hide={!editable}
                                  onTypeChanged={(type, defaultValue) => formData.setDataAtPath(path, defaultValue)}/>
            </Stack>
        case "null":
            return <Stack direction={"row"} columnGap={1} alignItems={"center"} alignContent={"center"}>
                <Typography style={{flexGrow: 1}} variant={"body1"}>{label}: null</Typography>
                <ChangeTypeButton currentType={formItemType}
                                  hide={!editable}
                                  onTypeChanged={(type, defaultValue) => formData.setDataAtPath(path, defaultValue)}/>
            </Stack>
        case "undefined":
            return <Stack direction={"row"} columnGap={1} alignItems={"center"} alignContent={"center"}>
                <Typography style={{flexGrow: 1}} variant={"body1"}>{label}: undefined</Typography>
                <ChangeTypeButton currentType={formItemType}
                                  hide={!editable}
                                  onTypeChanged={(type, defaultValue) => formData.setDataAtPath(path, defaultValue)}/>
            </Stack>
        default:
            return <Stack direction={"row"} columnGap={1} alignItems={"center"} alignContent={"center"}>
                <Typography style={{flexGrow: 1}} variant={"body1"}>{label}: {data?.toString()}</Typography>
                <ChangeTypeButton currentType={formItemType}
                                  hide={!editable}
                                  onTypeChanged={(type, defaultValue) => formData.setDataAtPath(path, defaultValue)}/>
            </Stack>
    }
}

function ChangeTypeButton(props) {

    function handleChange(event) {
        const selectedType = event.target.value;

        let defaultValue
        if ("array" === selectedType)
            defaultValue = []
        else if ("object" === selectedType)
            defaultValue = {}
        else if ("string" === selectedType)
            defaultValue = ""
        else if ("date" === selectedType)
            defaultValue = new Date()
        else if ("date-string" === selectedType)
            defaultValue = (new Date()).toISOString()
        else if ("number" === selectedType)
            defaultValue = 0
        else if ("boolean" === selectedType)
            defaultValue = false
        else if ("null" === selectedType)
            defaultValue = null
        else if ("undefined" === selectedType)
            defaultValue = undefined

        props.onTypeChanged(event.target.value, defaultValue)
    }

    if (props.hide)
        return <></>

    return <FormControl sx={{m: 1, minWidth: 80}}>
        <InputLabel id="type-select-label">Type</InputLabel>
        <Select
            labelId="type-select-label"
            id="type-select"
            value={props.currentType}
            onChange={handleChange}
            autoWidth
            label="Type"
        >
            <MenuItem value={""}><em>Auto</em></MenuItem>
            <MenuItem value={"array"}>Array</MenuItem>
            <MenuItem value={"object"}>Object</MenuItem>
            <MenuItem value={"string"}>String</MenuItem>
            <MenuItem value={"date"}>Date</MenuItem>
            <MenuItem value={"date-string"}>Date-string</MenuItem>
            <MenuItem value={"number"}>Number</MenuItem>
            <MenuItem value={"boolean"}>Boolean</MenuItem>
            <MenuItem value={"null"}>Null</MenuItem>
            <MenuItem value={"undefined"}>Undefined</MenuItem>
        </Select>
    </FormControl>
}

function ArrayType(props) {
    const formData = useFormData();

    function addItemToArray() {
        const array = _clone(formData.mainData)
        if (array.length > 0)
            array.push(array[array.length - 1])
        else
            array.push('')
        formData.setDataAtPath([], array)
    }

    function removeItemFromArray(index) {
        let arr = _clone(formData.mainData)
        if (index > -1) {
            arr.splice(index, 1);
        }
        formData.setDataAtPath([], arr)
    }

    return <Accordion disabled={props.open}
                      TransitionProps={{unmountOnExit: true}}
                      variant={"outlined"}
                      defaultExpanded={props.open}
                      style={{
                          borderRadius: "4px",
                          padding: "8px",
                          backgroundColor: "#" + pathToColor(formData.path.join("")) + "80"
                      }}>
        <AccordionSummary expandIcon={<ExpandMore/>}>
            <Typography variant={"subtitle1"}> List of {formData.label}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{borderRadius: "4px", background: "white"}}>
            <Stack spacing={2}>
                {formData.mainData.map((curEle, index) =>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item xs={10}>
                            <FormItem
                                key={index}
                                nested
                                id={formData.id}
                                editable={props.editable}
                                label={index}
                                value={curEle}
                                path={[index]}/>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => removeItemFromArray(index)} endIcon={<CloseIcon/>}></Button>
                        </Grid>
                    </Grid>
                )}
            </Stack>
        </AccordionDetails>
        <AccordionActions style={{borderRadius: "4px", background: "white"}}>
            <Button onClick={addItemToArray} endIcon={<Add/>}>Add Item</Button>
        </AccordionActions>
    </Accordion>
}

function ObjectType(props) {
    const formData = useFormData();
    const [open, setOpen] = useState(false);
    const [newKey, setNewKey] = useState("");
    const [newValue, setNewValue] = useState("")

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function addItemToObject() {
        const obj = _clone(formData.mainData)
        const newObj = Object.assign(obj, {[newKey]: newValue})
        formData.setDataAtPath([], newObj)
        setOpen(false);
        setNewKey("")
        setNewValue("")
    }

    return <Accordion disabled={props.open}
                      TransitionProps={{unmountOnExit: true}}
                      defaultExpanded={props.open}
                      style={{
                          borderRadius: "4px",
                          padding: "8px",
                          backgroundColor: "#" + pathToColor(formData.path.join("")) + "80"
                      }}>
        <AccordionSummary expandIcon={<ExpandMore/>}>
            <Typography variant={"subtitle1"}>{formData.label}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{borderRadius: "4px", background: "white"}}>
            <Stack spacing={2}>
                {Object.keys(formData.mainData)
                    .map((a, index) => <FormItem editable={props.editable} nested key={index} label={a} id={formData.id}
                                                 value={formData.mainData[a]}
                                                 path={[a]}/>)}
            </Stack>
        </AccordionDetails>
        <AccordionActions style={{borderRadius: "4px", background: "white"}}>
            <Button variant="outlined" onClick={handleClickOpen}>
                + Add New Field
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Field</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{padding: "6px"}}>
                        <TextField
                            required
                            id="key"
                            label="New Key"
                            value={newKey}
                            onChange={(e: any) => setNewKey(e.target.value)}
                        />
                        <TextField
                            required
                            id="value"
                            disabled={!newKey}
                            label={newKey || "New Value"}
                            value={newValue}
                            onChange={(e: any) => setNewValue(e.target.value)}
                        />
                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={addItemToObject}>Add</Button>
                </DialogActions>
            </Dialog>
        </AccordionActions>
    </Accordion>
}

function pathToColor(path: string) {
    return (intToRGB(hashCode(path)))
}

function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

function intToRGB(i) {
    const c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}