import post from "./post";
import get from "./get";
import apis from "./apis";
import axios from "axios"
import _delete from "./delete";

export const NI = axios.create({
    withCredentials: true
})

export default {
    post,
    get,
    apis,
    _delete
}
