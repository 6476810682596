import BasePage from "../../components/BasePage";
import React, {useState} from "react";
import tsv2json from "../../utils/tsv2csv";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FolderSelector from "../../components/FolderSelector";
import Tooltip from "@mui/material/Tooltip";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";


const expected_headers = [
    // "Song name",
    // "Artist",
    // "Date",
    "Audio",
    "Thumbnail",
    // "Genres",
    // "Mood",
    // "Secondary Mood",
    // "Instruments",
    // "Audio Category",
    // "Secondary Audio Category",
    // "Language",
    // "Length",
    // "Tempo (BPM)",
    // "Key/Scale",
    // "Vocals (Y/N)",
    // "Release type",
    "Email",
    // "Phone"
];

export default function DataValidator(props) {
    const [json, setJson] = useState<({ [key: string]: string } & { errors?: { thumbnail?: string[], audio?: string[] } })[]>(null)
    const [errorsOnly, setErrorsOnly] = useState(false)

    function processInputInternal(tsvalue) {
        let jsonvalue: { [key: string]: string }[]
        try {
            jsonvalue = tsv2json(tsvalue, (headersArr) => {
                if (expected_headers.every((val) => headersArr.indexOf(val) >= 0)) {
                    return expected_headers;
                } else {
                    throw new Error("Headers not in expected shape");
                }
            })
        } catch (e) {
            jsonvalue = [{error: `Couldn't parse TSV [${e.message}]`}]
        }

        setJson(jsonvalue)
    }

    function onChooseFile(event, onLoadFileHandler) {
        if (typeof (window as any).FileReader !== 'function')
            throw new Error("The file API isn't supported on this browser.");
        let input = event.target;
        if (!input)
            throw new Error("The browser does not properly implement the event object");
        if (!input.files)
            throw new Error("This browser does not support the `files` property of the file input.");
        if (!input.files[0])
            return undefined;
        let file = input.files[0];
        let fr = new FileReader();
        fr.onload = (data) => {
            onLoadFileHandler(data);
            event.target.value = "";
        }
        fr.readAsText(file);
    }

    function folderValidator(event) {
        const theFiles: FileList = event.target.files;
        if (theFiles.length === 0) return
        const relativePath = theFiles[0].webkitRelativePath;
        const isFolder = relativePath.trim().length > 0
        const baseFolder = isFolder ? relativePath.split("/")[0] : null

        if (!isFolder)
            throw new Error("multiple files are Unsupported. Use a folder instead.")

        const files = []
        for (let i = 0; i < theFiles.length; i++) {
            const file = theFiles.item(i)
            files.push(isFolder ? file.webkitRelativePath.substring(baseFolder.length + 1) : file.name)
        }

        setJson(oJson => oJson.filter(line => Object.keys(line).length > 1).map(line => {
            const audio = line["Audio"]
            const thumb = line["Thumbnail"]
            if (!audio || !thumb) {
                return line;
            }
            line.errors = line.errors || {}
            line.errors.audio = []
            line.errors.thumbnail = []

            if (!files.includes(audio))
                line.errors.audio.push("File not in folder")
            if (!files.includes(thumb))
                line.errors.thumbnail.push("File not in folder")

            if (!audio.match(/^[A-Za-z0-9\_]*\.(mp3|wav)$/))
                line.errors.audio.push("File name doesnt follow convention")
            if (!thumb.match(/^[A-Za-z0-9\_]*\.(jpg|jpeg|png)$/))
                line.errors.thumbnail.push("File name doesnt follow convention")

            return line
        }))
        event.target.value = "";
    }


    return <BasePage>
        <Stack>
            <Stack direction={"row"} justifyContent={"space-evenly"} spacing={1}>
                <Button variant="contained" component="label">
                    Select tsv file to data
                    <input hidden type='file'
                           onChange={(event) => onChooseFile(event, (data) => processInputInternal(data.target.result))}/>
                </Button>
                <Button variant="contained" disabled={!json} component="label">
                    Select folder containing files
                    <FolderSelector hidden id="base_folder" onChange={folderValidator}/>
                </Button>
                <FormControlLabel
                    control={<Switch checked={errorsOnly} onChange={e => setErrorsOnly(e.target.checked)}/>}
                    label="Errors only"/>
            </Stack>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Index
                            </TableCell>
                            {expected_headers.map((header, index) => <TableCell key={index}>{header}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {json?.filter(line => errorsOnly ? (line?.errors?.audio?.length > 0 || line?.errors?.thumbnail?.length > 0) : true)?.map((line, index) => {
                            const {errors, ...data} = line
                            return <TableRow key={index}>
                                {Object.entries(data).map((tokens: [string, string], tIndex) => {
                                    let error = "";
                                    if (tokens[0] === "Audio" && errors?.audio?.length > 0)
                                        error = errors?.audio?.join(" | ")
                                    else if (tokens[0] === "Thumbnail" && errors?.thumbnail?.length > 0)
                                        error = errors?.thumbnail?.join(" | ")

                                    return <TableCell key={tIndex}>
                                        <Tooltip title={error}>
                                            <Box sx={{backgroundColor: error ? "red" : "unset"}}>{tokens[1]}</Box>
                                        </Tooltip>
                                    </TableCell>;
                                })}
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    </BasePage>
}
