import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import {Button} from '@mui/material';

const useStyles = makeStyles({

    btn: {
        marginRight: "10px"
    },
});

const TableRowComponent = (props) => {
    const classes = useStyles();
    return (
        <TableRow key={props.rowId}>
            {props.rowhead.map((td, index) => {
                const tdData = props.rowbody[td.id];
                if (td.id === 'actions') {
                    return (<TableCell key={index} align="right">
                        {tdData.includes('view') && <Button variant="outlined" color="success"
                                                            onClick={() => props.onViewClick(props.rowId)}
                                                            className={classes.btn}>
                            View Members
                        </Button>}

                        {tdData.includes('addMember') && <Button variant="outlined" color="primary"
                                                                 onClick={() => props.onAddMemberClick(props.rowId)}
                                                                 className={classes.btn}>
                            Add Member
                        </Button>}

                        {tdData.includes('edit') && <Button variant="outlined" color="success"
                                                            onClick={() => props.onEditClick(props.rowId)}
                                                            className={classes.btn}>
                            Edit
                        </Button>}
                        {tdData.includes('delete') &&
                            <Button variant="contained" onClick={() => props.onDeleteClick(props.rowId)}
                                    className={classes.btn}>Delete</Button>}

                    </TableCell>)
                } else if (td.id === 'profilePic') {
                    return (<TableCell key={index}>
                        <img src={tdData} alt={td.id} style={{width: 50, height: 50, borderRadius: 50 / 2}}></img>
                    </TableCell>)
                } else {
                    return (<TableCell key={index}>{tdData}</TableCell>);
                }
            })}
        </TableRow>
    );
}

export default TableRowComponent;
