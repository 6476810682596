import { Button } from "@mui/material"
import { useState } from "react"
import EditFormDrawer from "components/custom/EditFormDrawer"
import network from "network"
import config from "config"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
export default function CreateUnluLiveAcl() {
    const [meetingObject, setMeetingObject] = useState(null)
    const params = useParams()
    async function loadFunc() {
        const meeting = { resource_type: '', role: '' }
        setMeetingObject(meeting)
    }
    const handleSubmit = async (mainData, diff, diffPath) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/meeting_acls/create?meeting_id=${params.meeting_id}`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success('meeting Created', { autoClose: 2000 })
        }
        else
            toast.error(res.data.message, { autoClose: 2000 })
    }
    return <>
        <Button variant={"outlined"} onClick={() => loadFunc()}>+Create New Meeting ACL</Button>
        {!!meetingObject && <EditFormDrawer id={'unlulive_acl'} title={'Create Meeting ACL'} onClose={() => setMeetingObject(null)} open={!!meetingObject} schema={meetingObject} onSave={handleSubmit} />}
    </>
}