import React, {useState} from "react";
import {Button, Paper} from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import config from "../../config";
import network from "../../network";
import {toast} from "react-toastify";

const useStyles = makeStyles({
    formLabel: {
        marginTop: "10px",
        color: "#191237",
        fontFamily: "Source Sans Pro",
        fontSize: "1.1rem",
    },
    textField: {
        width: "22ch",
    },

    submitBtn: {
        marginTop: "40px",
        width: "100%",
    },
});

const CreateInterestForm = (props) => {
    const [interestName, setInterestName] = useState();
    // const [value, setValue] = useState(false);
    const handleSubmit = (e) => {
        if (interestName === "" || interestName == null) {
            toast.warning("Enter Interest Name", {autoClose: 3000});
            return;
        }
        if (props.formTypeEdit) {
            network
                .post(
                    `${config.base_path}/api/dashboard/interests/${props.formId}`,
                    {name: interestName, verified: props.formData.verified}
                )
                .then((response) => {
                    if (response.data.statusCode === 0) {
                        toast.success(interestName + " interest edited successfully!", {autoClose: 2000});
                        window.location.reload();
                    } else {
                        toast.error(response.data.message, {autoClose: 3000});
                    }
                })
                .catch((err) => console.log(err));
        } else {
            network
                .post(
                    `${config.base_path}/api/dashboard/interests`,
                    {
                        name: interestName,
                    }
                )

                .then((response) => {
                    if (response.data.statusCode === 0) {
                        window.location.reload();
                        toast.success(interestName + " interest created successfully!", {autoClose: 3000});
                    } else {
                        toast.error(response.data.message, {autoClose: 3000});
                    }
                });
        }
    };

    const classes = useStyles();
    return (
        <Paper style={{padding: "30mm 20mm", maxWidth: "500px", height: "100%"}}>
            {props.formTypeEdit ? (
                <Grid key={props.formData.id}>
                    <Grid container direction="row" spacing={0} alignItems="center">
                        <Grid item xs={12}>
                            <Typography
                                className={classes.formLabel}
                                style={{fontWeight: "bold"}}
                            >
                                Existing Interest : {props.formData.name}
                            </Typography>
                            <Typography className={classes.formLabel}>
                                New Interest :
                            </Typography>
                            <TextField
                                className={classes.textField}
                                required
                                id="standard-required"
                                onChange={(e: any) => setInterestName(e.target.value)}
                                value={interestName}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid>
                    <h4>Create new interest</h4>
                    <Grid container direction="row" spacing={0} alignItems="center">
                        <Grid item xs={8}>
                            <TextField
                                className={classes.textField}
                                required
                                id="standard-required"
                                label="Interest Name"
                                onChange={(e: any) => setInterestName(e.target.value)}
                                value={interestName}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <Button className={classes.submitBtn}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
            >
                Submit
            </Button>
        </Paper>
    );
};

export default CreateInterestForm;
