import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import {useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import EditFormDrawer from "components/custom/EditFormDrawer";
import {toast} from "react-toastify";
import {styled} from "@mui/material/styles";

const SubmitButton = styled(Button)(({theme}) => ({
    height: '70px',
    bottom: "0vh",
    width: "100%",
    position: 'sticky',
    background: "#7ca4d4",
    textAlign: "center",
    border: "1px solid #D2D2D3",
}));
export default function ListUserAssignmentResponses() {
    const [item, setItem] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const params = useParams()

    async function loadFunc() {
        const question = await network.get(`${config.base_path}/api/community/dashboard/threads/${params.response_id}?page=${params.page}`)
        setHasMore(question.data.payload.length !== 0)
        setItem(question.data.payload)
    }

    return <BasePage>
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/assignments/${params.assignment_id}/users/${params.user_id}/${params.response_id}/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={<PageData items={item} loadFunc={loadFunc}/>}/>
    </BasePage>
}

function PageData(props) {
    const params = useParams()
    const [feedbackDrawer, setFeedbackDrawer] = useState(null)

    async function loadFeedbackFunc() {
        const feedback = {
            thread_id: params.response_id,
            body: '',
            attachments: [{
                type: '',
                value: ''
            }]
        }
        setFeedbackDrawer(feedback)
    }

    const handleSubmit = async (mainData) => {
        if (mainData.attachments[0].type === '') {
            const t = window.confirm("Do yo want to send feedback without any attachment ?")
            if (t === true)
                delete mainData.attachments
            else return
        }
        if (mainData.body === '') {
            return alert('please add some body text')
        }
        let res = await network.post(
            `${config.base_path}/api/community/dashboard/threads`, mainData
        )
        if (res.data.statusCode === 0) {
            setFeedbackDrawer(null)
            toast.success('Feedback Submitted', {autoClose: 2000})
            props.loadFunc()
        } else
            toast.error(res.data.message, {autoClose: 2000})
    }

    return <>
        <b>{props?.items[0]?.user?.full_name}</b>
        {props?.items?.map((a, index) => <Assignment key={index} {...a} clientUser={props?.items[0]?.user?.id}/>)}
        <SubmitButton variant={'outlined'} sx={{color: '#000'}} onClick={() => loadFeedbackFunc()}>
            Send Feedback
        </SubmitButton>
        {feedbackDrawer && <EditFormDrawer id={'assignments_feedback'} title='Assignment Feedback'
                                           onClose={() => setFeedbackDrawer(null)} open={!!feedbackDrawer}
                                           schema={feedbackDrawer} onSave={handleSubmit}/>}
    </>
}

function Assignment(item) {
    return <Grid item xs={12}>
        <Card style={{margin: "8px", padding: "8px", background: item.clientUser != item.user.id ? '#f69d3c' : ''}}>
            {item.clientUser != item.user.id && <b>Feedback:</b>}
            <p>{item.body}</p>
            {item?.attachments.length > 0 &&
                <p>Attachment: <Link rel="stylesheet" href={item?.attachments[0].value}
                                     target={'_blank'}>{item?.attachments[0].value}</Link></p>}
        </Card>
    </Grid>
}
