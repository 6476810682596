import React, {useState} from "react";
import BasePage from "components/BasePage";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";


const tagLists = [{
    label: "UNLU_SHOUTOUT",
    type: 0
}, {
    label: "UNLU_CLASS",
    type: 10
}, {
    label: "UNLU_BLOG",
    type: 20
}, {
    label: "UNLU_BOOK",
    type: 30
}, {
    label: "UNLU_SONG",
    type: 40
}, {
    label: "UNLU_LYRICS",
    type: 50
}, {
    label: "UNLU_POEM",
    type: 60
}]

const CustomBox = styled(Button)(() => ({
    marginBottom: '10px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: 'grey'
    }
}))


export default function UnluTags(props) {
    const navigate = useNavigate()
    const onClickHandler = (value) => {
        navigate(`/tags/${value.type}/1`,{replace:false})
    }

    return <BasePage>
            <b>Select field to add tags</b>
        <Card sx={{padding: "10vh", margin: '20px', alignItems: 'center', justifyContent: 'center'}}>
            {tagLists?.map((option) => (
                <CustomBox key={option.label} onClick={() => onClickHandler(option)}>
                    {option.label}
                </CustomBox>
            ))}
        </Card>
    </BasePage>
}
