import React from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import config from "../../config";
import utils, {withNavigation, withParams} from "../../utils";
import Skeleton from '@mui/material/Skeleton';
import network from "../../network";
import LinearProgress from '@mui/material/LinearProgress';
import Button from "@mui/material/Button";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Link} from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel"
import DialogBox from '../../components/custom/dialog-box'
import PublishIcon from '@mui/icons-material/Publish';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BasePage from "../../components/BasePage";
import Chip from "@mui/material/Chip";

const fileDownload = require('js-file-download');

const useStyles = theme => ({
    progress: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    root: {
        display: 'flex',
        background: "#F0F0F7"
    },
    toolbar: theme.mixins.toolbar,

    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
    },
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        marginRight: theme.spacing(5),
    },
    rootRadio: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
    rootCheck: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
});

class Requests extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            temp: [],
            next: true,
            page: this.props.match.params.page,
            videoLink: "",
            filter: "",
            filterText: "",
            payment_status: "",
            status: "",
            amount: "",
            filterQuery: "",
            private: false,
            sendMailBox: false
        };
    }

    apiCall = () => {
        const filterQ = localStorage.getItem("filterQuery") || ""
        network.get(`${config.base_path}/api/dashboard/requests/${this.state.page}${filterQ}`)
            .then((response) => {
                this.setState({
                    data: response.data.payload
                })
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    nextPage = () => {
        network.get(`${config.base_path}/api/dashboard/requests/${Number(this.state.page) + 1}${this.state.filterQuery}`)
            .then((response) => {
                if (response.data.payload.length === 0) {
                    this.setState({
                        next: false,
                    });
                } else {
                    this.setState({
                        next: true,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    next = () => {
        this.props.navigate(`/requests/${Number(this.state.page) + 1}${this.state.filterQuery}`)
        window.location.reload();
    }

    previous = () => {
        this.props.navigate(`/requests/${Number(this.state.page) - 1}${this.state.filterQuery}`)
        window.location.reload();
    }

    componentDidMount() {
        this.apiCall();
        utils.user_data(this);
        this.nextPage();
        if (localStorage.getItem("filterQuery") === null) localStorage.setItem("filterQuery", "?")
    }

    updateVideoLink = (request_id) => {
        if (this.state.videoLink.trim() === "") {
            return alert("Please enter the video call link")
        }
        network.post(`${config.base_path}/api/dashboard/request/${request_id}/update`,
            {
                deliverable: this.state.videoLink,
            })
            .then((response) => {
                if (response.data.statusCode === 0) {
                    alert('Video Link Updated')
                } else {
                    alert('Something went wrong: ' + response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    handleChange(key, value) {
        this.setState({
            [key]: value
        })
    }

    hideVideo = (request_id) => {
        network.post(`${config.base_path}/api/dashboard/request/${request_id}/update`,
            {
                private: this.state.private,
            },)
            .then((response) => {
                if (response.data.statusCode === 0) {
                    alert(this.state.private ? 'Video is now private' : 'Video is now public')
                } else {
                    alert('Something went wrong: ' + response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    updateFilter(key) {
        localStorage.setItem("filterQuery", `?${this.state.filter}=${key}`)
        this.setState({
            filterQuery: `?${this.state.filter}=${key}`,
        })
        this.apiCall()
    }

    selectFilter(key) {
        this.setState({
            filter: key,
        })
    }

    removeFilter() {
        this.setState({
            filter: "",
        })
        localStorage.setItem("filterQuery", "?")
        this.apiCall()
    }

    amountFilter(key, value) {
        this.setState({
            [key]: value
        })
        if (value === "") {
            localStorage.setItem("filterQuery", "?")
            this.apiCall()
        } else {
            localStorage.setItem("filterQuery", `?${this.state.filter}=${value}`)
            this.setState({
                filterQuery: `?${this.state.filter}=${value}`,
            })
            this.apiCall()
        }
    }

    statusFilter(key, value) {
        this.setState({
            [key]: value
        })
        if (value === "") {
            localStorage.setItem("filterQuery", "?")
            this.apiCall()
        } else {
            localStorage.setItem("filterQuery", `?${this.state.filter}=${value}`)
            this.setState({
                filterQuery: `?${this.state.filter}=${value}`,
            })
            this.apiCall()
        }
    }

    cancel() {
        this.setState({
            filter: "",
            filterQuery: ""
        })
    }

    convertDate = (milliseconds) => {
        // return new Date(milliseconds).toUTCString()
        return new Date(milliseconds).toLocaleDateString()
    }

    request(action, requestId, celeb_id) {
        network.post(
            config.base_path + `/api/dashboard/request/${action}`,
            {
                request_id: requestId,
                celeb_id: celeb_id
            }
        )
            .then(response => {
                alert(`${requestId} successfully ${action}ed`)
                // this.props.navigate("/home");

            })
            .catch(err => {
                    console.log(err);
                    alert("There is some error in the request. \n" + err)
                }
            );
    }

    exportData(copy) {
        const csvString = [
            [
                "Unlu Order ID",
                "PG Order ID",
                "Payment ID",
                "Payment Type",
                "Requested For",
                "Request Type",
                "Their Name",
                "Amount",
                "Coupon Used",
                "Original Amount",
                "Username",
                "User id",
                "User Name",
                "User Phone no",
                "User email id",
                "Date of Creation",
                "Payment Status",
                "Celeb Paid",
                "Instructions",
                "Celeb name",
                "Celeb username"
            ],
            ...this.state.data.map(item => [
                item.id,
                item.transaction.pg_order_id,
                item.transaction.extra.rzp_payment ? item.transaction.extra.rzp_payment["id"] : item.transaction?.extra?.payment?.response?.id,
                item.transaction.extra.order ? item.transaction.extra.order.type : item.transaction.extra.rzp_order ? 'Razorpay' : '',
                item.requested_for === 300 ? 'Other' : item.requested_for === 310 ? 'Self' : 'Brand',
                item.deliverable_type === 400 ? 'Video Shoutout' : item.deliverable_type === 410 ? 'Video Call' : null, // class 0r all access pass
                item.requested_for_extra || '',
                item.transaction.amount,
                item.transaction.coupon_code || '',
                item.transaction.original_amount,
                item.requester.username,
                item.requester.id,
                item.requester.name,
                item.requester.phone_number || '',
                item.requester.email || '',
                this.convertDate(item.created_at),
                this.paymentStatus(item.payment_status),
                item.celeb_paid,
                item.instructions.replaceAll('\r', ' ').replaceAll('\n', '') || '',
                item.requested.name || '',
                item.requested.username || ''
            ])
        ].map(e => e.map(a => `"${a}"`).join(",")).join("\n")
        if (copy) {
            return navigator.clipboard.writeText(csvString)
        }
        fileDownload(csvString, 'requestData.csv')
    }

    paymentStatus(payment_status) {
        if (payment_status === 200) {
            return 'Payment Pending'
        }
        if (payment_status === 210) {
            return 'Payment Received'
        }
        if (payment_status === 220) {
            return 'Payment Refund Pending'
        }
        if (payment_status === 230) {
            return 'Payment Refunded'
        }
    }

    statusCode(value) {
        if (value === 0) {
            return 'Requested'
        }
        if (value === 10) {
            return 'Rejected'
        }
        if (value === 20) {
            return 'Accepted'
        }
        if (value === 30) {
            return 'Fulfilled'
        }
    }

    reason(value) {
        if (value === 100) {
            return 'Birthday'
        }
        if (value === 110) {
            return 'Congratulatory'
        }
        if (value === 120) {
            return 'Anniversary'
        }
        if (value === 130) {
            return 'Others'
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <BasePage>
                <div style={{
                    background: "#F0F0F7",
                    padding: "50px",
                    paddingBottom: "0px",
                    paddingTop: "10px",
                }}>
                    <Grid container>
                        <Grid item xs={12}>

                            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                                <Button
                                    onClick={() => {
                                        this.exportData(false)
                                    }}
                                    variant="contained"
                                    color="primary"
                                    style={{marginLeft: '2vw'}}
                                >
                                    <PublishIcon style={{height: '1.25vw'}}/> Export
                                </Button>
                                <Button
                                    onClick={
                                        () => this.exportData(true)
                                    }
                                    variant="contained"
                                    color="primary"
                                >
                                    <FileCopyIcon style={{height: '1.25vw'}}/> Copy CSV
                                </Button>
                            </div>
                            <Typography
                                style={{
                                    marginTop: "10px",
                                    color: "#191237",
                                    fontFamily: "Source Sans Pro",
                                    fontSize: "1.6rem"
                                }}
                            >
                                Filters
                            </Typography>

                            <Grid
                                container
                                style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                }}
                            >
                                <Grid
                                    item
                                    style={{
                                        marginRight: "1vw",
                                    }}
                                >
                                    <Button
                                        onClick={
                                            () => this.selectFilter('pg_order_id')
                                        }
                                        variant="contained"
                                        color="primary"
                                    >
                                        PG Order ID
                                    </Button>
                                </Grid>
                                {/* <Grid
                                        item
                                        style={{
                                            marginRight: "1vw",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={
                                                () => this.selectFilter('amount')
                                            }
                                        >
                                            Amount
                                        </Button>
                                    </Grid> */}
                                <Grid
                                    item
                                    style={{
                                        marginRight: "1vw",
                                    }}
                                />
                                <Grid
                                    item
                                    style={{
                                        marginRight: "1vw",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={
                                            () => this.selectFilter('celeb')
                                        }
                                    >
                                        Celeb
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        marginRight: "1vw",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={
                                            () => this.selectFilter('user')
                                        }
                                    >
                                        User
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        marginRight: "1vw",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={
                                            () => this.selectFilter('payment_status')
                                        }
                                    >
                                        Payment Status
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        marginRight: "1vw",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={
                                            () => this.selectFilter('status')
                                        }
                                    >
                                        Request Status
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        marginRight: "1vw",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={
                                            () => this.removeFilter()
                                        }
                                    >
                                        Remove All Filters
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.filter === 'pg_order_id' || this.state.filter === 'celeb' || this.state.filter === 'user' ?
                        <>
                            <Grid container>
                                <Grid
                                    item
                                    xs={2}
                                />
                                <Grid item xs={8}>
                                    <Grid
                                        container
                                        style={{
                                            marginTop: "5px"
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={4}
                                        >
                                            <form
                                                style={{
                                                    justifyContent: "center",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <TextField
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="filterText"
                                                    // placeholder={"Enter Filter Value"}
                                                    onChange={
                                                        e => this.handleChange(e.target.name, e.target.value)
                                                    }
                                                    label={
                                                        <span
                                                            style={{
                                                                fontFamily: "Source Sans Pro",
                                                                color: "#43425D",
                                                                fontSize: "15px",
                                                            }}
                                                        >
                                                            Enter Filter Value
                                                        </span>
                                                    }
                                                    id="tag"
                                                />
                                            </form>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        style={{
                                            marginTop: "80px",
                                            marginBottom: "80px",
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={9}
                                            md={9}
                                            sm={9}
                                        />
                                        <Grid
                                            item
                                            xs={1}
                                            md={1}
                                            sm={1}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        >
                                            <Button
                                                onClick={
                                                    () => this.cancel()
                                                }
                                                style={{
                                                    marginRight: "10px",
                                                    width: "5vw"
                                                }}
                                                variant="outlined"
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            md={1}
                                            sm={1}
                                        />
                                        <Grid
                                            item
                                            xs={1}
                                            md={1}
                                            sm={1}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={
                                                    () => this.updateFilter(this.state.filterText)
                                                }
                                                style={{
                                                    width: "5vw",
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={2}
                                />
                            </Grid>
                        </>
                        : this.state.filter === 'amount' ? <div>
                                <FormControl
                                    style={{
                                        width: "50vh",
                                        marginLeft: '10px'
                                    }}
                                >
                                    <InputLabel
                                        id="demo-simple-select-label"
                                        style={{
                                            fontFamily: "Source Sans Pro",
                                        }}
                                    >
                                        Amount Order
                                    </InputLabel>

                                    <Select
                                        labelId="select-label"
                                        id="select"
                                        name="amount"
                                        value={this.state.amount}
                                        onChange={(e: any) => this.amountFilter(e.target.name, e.target.value)}
                                        style={{
                                            fontFamily: "Source Sans Pro",
                                        }}
                                    >
                                        <MenuItem value={""} disabled>Select</MenuItem>
                                        <MenuItem value={'desc'}>Descending</MenuItem>
                                        <MenuItem value={'asc'}>Ascending</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            : this.state.filter === 'payment_status' ? <div>
                                    <FormControl
                                        style={{
                                            width: "50vh",
                                            marginLeft: '10px'
                                        }}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            style={{
                                                fontFamily: "Source Sans Pro",
                                            }}
                                        >
                                            Payment Status
                                        </InputLabel>

                                        <Select
                                            labelId="select-label"
                                            id="select"
                                            name="payment_status"
                                            value={this.state.payment_status}
                                            onChange={(e: any) => this.statusFilter(e.target.name, e.target.value)}
                                            style={{
                                                fontFamily: "Source Sans Pro",
                                            }}
                                        >
                                            <MenuItem value={""} disabled>Select</MenuItem>
                                            <MenuItem value={200}>Payment Pending</MenuItem>
                                            <MenuItem value={210}>Payment Received</MenuItem>
                                            <MenuItem value={215}>Payment Pending on Payment Gateway</MenuItem>
                                            <MenuItem value={220}>Payment Refund Pending</MenuItem>
                                            <MenuItem value={230}>Payment Refunded</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                : this.state.filter === 'status' ? <div>
                                        <FormControl
                                            style={{
                                                width: "50vh",
                                                marginLeft: '10px'
                                            }}
                                        >
                                            <InputLabel
                                                id="demo-simple-select-label"
                                                style={{
                                                    fontFamily: "Source Sans Pro",
                                                }}
                                            >
                                                Request Status
                                            </InputLabel>

                                            <Select
                                                labelId="select-label"
                                                id="select"
                                                name="status"
                                                value={this.state.status}
                                                onChange={(e: any) => this.statusFilter(e.target.name, e.target.value)}
                                                style={{
                                                    fontFamily: "Source Sans Pro",
                                                }}
                                            >
                                                <MenuItem value={""} disabled>Select</MenuItem>
                                                <MenuItem value={0}>Requested</MenuItem>
                                                <MenuItem value={10}>Rejected</MenuItem>
                                                <MenuItem value={20}>Accepted</MenuItem>
                                                <MenuItem value={30}>Fulfilled</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    : this.state.filter !== '' ? alert("Coming Soon") : null
                    }
                    {this.state.data ?
                        <div style={{
                            paddingBottom: "5vh"
                        }}>

                            {this.state.data.map((row) => {
                                return (
                                    <Accordion key={row.id} style={{padding: "10px", marginTop: "10px",}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            {row.deliverable_type === 410 &&
                                                <img
                                                    src={'/zoom.png'}
                                                    style={{
                                                        height: '3vh',
                                                        marginTop: 'auto',
                                                        marginBottom: 'auto',
                                                        marginRight: '0.5vw',
                                                    }}
                                                    alt={'Zoom logo'}
                                                />
                                            }
                                            {row.deliverable_type === 400 &&
                                                <img
                                                    src={'/video-icon.png'}
                                                    style={{
                                                        height: '3vh',
                                                        marginTop: 'auto',
                                                        marginBottom: 'auto',
                                                        marginRight: '0.5vw',
                                                    }}
                                                    alt={'Video Icon'}
                                                />
                                            }
                                            <Chip label={requestStatusString(row.status)}/>

                                            <Chip label={paymentStatusString(row.payment_status)}/>

                                            <Typography style={{
                                                padding: "5px",
                                            }}>Requested For <b><Link
                                                to={`/user/${row.requested.id}`}>{row.requested.name}</Link></b> by <b>{row.requester_name}</b>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <List component={Paper}
                                                  style={{padding: "10px", marginTop: "10px",}}
                                            >
                                                <ListItem>
                                                    <ListItemText style={{width: "65vw"}}
                                                                  secondary={
                                                                      <Typography
                                                                          component="span"
                                                                          style={{
                                                                              fontFamily: "Source Sans Pro",
                                                                              color: "rgba(67, 66, 93, 0.5)",
                                                                              fontSize: "14px",
                                                                          }}
                                                                      >
                                                            <span>
                                                            <b>Unlu Order ID:</b> {row.id}
                                                            </span>
                                                                          <br/>
                                                                          <span>
                                                            <b>PG Order ID:</b> {row.transaction.pg_order_id}
                                                            </span>
                                                                          <br/>
                                                                          {row.transaction.extra.rzp_payment ?
                                                                              <span>
                                                                <b>Payment ID:</b> {row.transaction.extra.rzp_payment["id"]}
                                                            </span>
                                                                              : null
                                                                          }
                                                                          {row.transaction.extra.payment ?
                                                                              <span>
                                                                <b>Payment ID:</b> {row.transaction?.extra?.payment?.response?.id}
                                                            </span>
                                                                              : null
                                                                          }
                                                                          <br/>
                                                                          {row.transaction.extra.order ?
                                                                              <span>
                                                                    <b>Payment Type:</b> {row.transaction.extra.order.type}
                                                                </span>
                                                                              : null
                                                                          }
                                                                          {row.transaction.extra.rzp_order ?
                                                                              <span>
                                                                    <b>Payment Type: razorpay</b>
                                                                </span>
                                                                              : null
                                                                          }
                                                                          <br/>
                                                                          <span>
                                                            <b>Requested for:</b> {row.requested_for === 300 ? 'Other' : row.requested_for === 310 ? 'Self' : 'Brand'}
                                                            </span>
                                                                          <br/>
                                                                          <span>
                                                            <b>Request Type:</b> {row.deliverable_type === 400 ? 'Video Shoutout' : row.deliverable_type === 410 ? 'Video Call' : null}
                                                            </span>

                                                                          {row.requested_for === 300 && <>
                                                                              <br/>
                                                                              <span>
                                                                <b>Their Name:</b> {row.requested_for_extra}
                                                                </span>
                                                                          </>}
                                                                          <br/>
                                                                          <span>
                                                            <b>Amount:</b> {row.transaction.currency === 'INR' ? '₹' : row.transaction.currency === 'USD' ? '$' : row.transaction.currency} {row.transaction.amount}/-
                                                            </span>
                                                                          <br/>
                                                                          <span>
                                                            <b>Coupon Used:</b> {row.transaction.coupon_code || 'None'}
                                                            </span>
                                                                          {row.transaction.coupon_code ?
                                                                              <>
                                                                                  <br/>
                                                                                  <span>
                                                                    <b>Original Amount:</b> {row.transaction.currency === 'INR' ? '₹' : row.transaction.currency === 'USD' ? '$' : row.transaction.currency} {row.transaction.original_amount}/-
                                                                    </span>
                                                                              </>
                                                                              : null
                                                                          }

                                                                          {
                                                                              <>
                                                                                  <br/>
                                                                                  <span>
                                                                    <b>Celeb Paid:</b> {row.celeb_paid ? "Yes" : "No"}
                                                                    </span>
                                                                              </>
                                                                          }
                                                                          <br/>
                                                                          <span>
                                                              <b>Date of Creation:</b> {this.convertDate(row.created_at)}
                                                            </span>
                                                                          {row.deliverable &&
                                                                              <span>
                                                              <br/><b>Deliverable:</b> <a href={row.deliverable}
                                                                                          target={'blank'}>{row.deliverable}</a>
                                                            </span>
                                                                          }
                                                                          {row.status === 30 && <>
                                                                              <br/>
                                                                              <span>
                                                            <b>Delivered On:</b> {row.delivered_at ? this.convertDate(row.delivered_at) : 'NOT AVAILABLE'}</span>
                                                                          </>}
                                                                          <br/>
                                                                          <span>
                                                            <b>Instructions:</b> {row.instructions}
                                                            </span>
                                                                          <br/>
                                                                          <span>
                                                            <b>Payment Status:</b> {this.paymentStatus(row.payment_status)}
                                                            </span>
                                                                          <br/>
                                                                          {row.deliverable_type === 400 &&
                                                                              <FormControlLabel
                                                                                  control={
                                                                                      <Checkbox
                                                                                          defaultChecked={row.private}
                                                                                          onChange={(e: any) => {
                                                                                              this.handleChange(e.target.name, e.target.checked)
                                                                                          }}
                                                                                          name="private"
                                                                                          color="primary"
                                                                                      />
                                                                                  }
                                                                                  label={
                                                                                      <span
                                                                                          style={{
                                                                                              fontFamily: "Source Sans Pro",
                                                                                              // color: "#43425D",
                                                                                          }}
                                                                                      >
                                                                        <b>Private</b>
                                                                    </span>
                                                                                  }
                                                                              />}

                                                                          {row.deliverable_type === 410 && <>
                                                                              <TextField
                                                                                  variant="standard"
                                                                                  margin="normal"
                                                                                  required
                                                                                  fullWidth
                                                                                  defaultValue={row.deliverable}
                                                                                  // value={this.state.videoLink}
                                                                                  name="videoLink"
                                                                                  onChange={
                                                                                      e => this.handleChange(e.target.name, e.target.value)
                                                                                  }
                                                                                  id={row.id}
                                                                                  label={
                                                                                      <span
                                                                                          style={{
                                                                                              fontFamily: "Source Sans Pro",
                                                                                              color: "#43425D",
                                                                                              fontSize: "15px",
                                                                                          }}
                                                                                      >
                                                                            Video Call Link
                                                                        </span>
                                                                                  }
                                                                              />
                                                                          </>}
                                                                      </Typography>}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <Grid
                                                        container
                                                    >
                                                        <Grid
                                                            item
                                                            xs={5}
                                                            md={5}
                                                            sm={5}
                                                        />
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            md={1}
                                                            sm={1}
                                                            style={{
                                                                textAlign: "right",
                                                            }}
                                                        >

                                                            {row.deliverable_type === 410 && <Button
                                                                onClick={
                                                                    () => this.updateVideoLink(row.id)
                                                                }
                                                                style={{
                                                                    width: "5vw",
                                                                    backgroundColor: "#21c196"
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Update
                                                            </Button>}
                                                            {row.deliverable_type === 400 && <Button
                                                                onClick={
                                                                    () => this.hideVideo(row.id)
                                                                }
                                                                style={{
                                                                    width: "5vw",
                                                                    backgroundColor: "#21c196"
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Update
                                                            </Button>}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            md={1}
                                                            sm={1}
                                                        />
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            md={1}
                                                            sm={1}
                                                            style={{
                                                                textAlign: "right",
                                                            }}
                                                        >
                                                            <Button
                                                                disabled={row.payment_status === 200 || row.payment_status === 230 || row.status === 30 || row.status === 20}
                                                                onClick={
                                                                    () => this.request('refund', row.id, row.requested.id)
                                                                }
                                                                style={(row.payment_status === 200 || row.payment_status === 230 || row.status === 30 || row.status === 20) ?
                                                                    {
                                                                        width: "5vw",
                                                                        backgroundColor: "#E0E0E0"
                                                                    }
                                                                    : {
                                                                        width: "5vw",
                                                                        backgroundColor: "#3b86ff"
                                                                    }
                                                                }
                                                                // style={{
                                                                //     marginRight: "10px",
                                                                //     width: "5vw",
                                                                //     backgroundColor: "#3b86ff"
                                                                // }}
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Refund
                                                            </Button>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            md={1}
                                                            sm={1}
                                                        />
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            md={1}
                                                            sm={1}
                                                            style={{
                                                                textAlign: "right",
                                                            }}
                                                        >
                                                            <Button
                                                                disabled={row.status === 10 || row.status === 30 || row.payment_status === 220 || row.payment_status === 230}
                                                                onClick={
                                                                    () => this.request('reject', row.id, row.requested.id)
                                                                }
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "5vw",
                                                                }}
                                                                variant="outlined"
                                                                color="secondary"
                                                            >
                                                                Reject
                                                            </Button>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            md={1}
                                                            sm={1}
                                                        />
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            md={1}
                                                            sm={1}
                                                            style={{
                                                                textAlign: "right",
                                                            }}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={row.status === 20 || row.status === 30 || row.payment_status === 230 || row.payment_status === 200}
                                                                onClick={
                                                                    () => this.request('accept', row.id, row.requested.id)
                                                                }
                                                                style={(row.status === 20 || row.status === 30 || row.payment_status === 230 || row.payment_status === 200) ?
                                                                    {
                                                                        width: "5vw",
                                                                        backgroundColor: "#E0E0E0"
                                                                    }
                                                                    : {
                                                                        width: "5vw",
                                                                        backgroundColor: "#21c196"
                                                                    }
                                                                }
                                                            >
                                                                Accept
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>

                                            </List>


                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </div> : <div style={{height: "100vh"}}>
                            <Skeleton variant="text" height={"10vh"}/>
                            <Skeleton variant="circular" width={"15vh"} height={"15vh"}/>
                            <Skeleton variant="circular" width={"15vh"} height={"15vh"}/>
                            <Skeleton variant="circular" width={"15vh"} height={"15vh"}/>
                        </div>}
                    {this.state.data ?
                        <Grid
                            container
                            style={{
                                paddingBottom: "20px"
                            }}
                        >
                            <Grid
                                item
                                xs={7}
                            />
                            <Grid
                                item
                                xs={2}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        width: "100%",
                                    }}
                                    onClick={() => {
                                        this.previous()
                                    }
                                    }
                                    disabled={Number(this.state.page) === 1}
                                >
                                    Previous
                                </Button>
                            </Grid>
                            <Grid
                                style={{
                                    textAlign: 'center',
                                    width: "100%",
                                    margin: 'auto',
                                }}
                                item
                                xs={1}
                            >
                                Page: {this.state.page}
                            </Grid>
                            <Grid
                                item
                                xs={2}
                            >

                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        width: "100%",
                                    }}
                                    disabled={!this.state.next}
                                    onClick={() => {
                                        this.next()
                                    }
                                    }
                                >
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                        :
                        <div className={classes.root}>
                            <LinearProgress/>
                            <LinearProgress color="secondary"/>
                        </div>
                    }
                </div>
                {this.state.sendMailBox && <DialogBox closeBox={() => this.setState({sendMailBox: false})}
                                                      openBox={() => this.state.sendMailBox}
                                                      confirm={() => this.setState({sendMailBox: false})}
                                                      heading={'Coming Soon!'} acceptText={'Okay!'}/>}
            </BasePage>
        );
    };
}

function paymentStatusString(code) {
    switch (code) {
        case 220:
            return "Refund Pending"
        case 200:
            return "Payment Pending"
        case 210:
            return "Payment Received"
        case 230:
            return "Payment Refunded"
    }
}

function requestStatusString(code) {
    switch (code) {
        case 0:
            return "Requested"
        case 10:
            return "Rejected"
        case 20:
            return "Accepted"
        case 30:
            return "Fulfilled"
    }
}

export default withNavigation(withParams(Requests))
