import {Button} from "@mui/material";
import EditFormDrawer from "components/custom/EditFormDrawer";
import React, {useState} from "react";
import {toast} from "react-toastify";
import config from "config";
import network from "network";
import {useParams} from "react-router-dom";

export default function CreateRoom() {
    const [roomObject, setRoomObject] = useState(null)
    const params = useParams()

    async function openForm() {
        const obj = {
            "title": {
                "$m_required": false,
                "$m_type": "string",
                "$m_default": "",
                "$m_description": "Title of the item",
                "$m_label": "Title"
            },
            "description": {
                "$m_required": false,
                "$m_type": "string",
                "$m_default": "",
                "$m_description": "Description of the item",
                "$m_label": "Description"
            },
            "slug": {
                "$m_required": false,
                "$m_type": "string",
                "$m_default": "",
                "$m_description": "Unique identifier for the item",
                "$m_label": "Slug"
            },
            "image_url": {
                "$m_required": false,
                "$m_type": "url",
                "$m_default": "",
                "$m_description": "URL of the image",
                "$m_label": "Image URL"
            },
            "commission": {
                "$m_required": false,
                "$m_type": "number",
                "$m_default": 0,
                "$m_description": "Commission amount",
                "$m_label": "Commission"
            },
            "total_seats": {
                "$m_required": false,
                "$m_type": "number",
                "$m_default": 0,
                "$m_description": "Total number of seats",
                "$m_label": "Total Seats"
            },
            "brand_partner": {
                "$m_required": false,
                "$m_type": "string",
                "$m_default": "",
                "$m_description": "Name of the brand partner",
                "$m_label": "Brand Partner"
            },
            "allowed_publication_type": {
                "$m_required": false,
                "$m_type": "string",
                "$m_default": "story",
                "$m_description": "Type of publication allowed",
                "$m_label": "Allowed Publication Type",
                "$m_enum": ["story"]
            },
            "instructions": {
                "$m_required": false,
                "$m_type": "[string]",
                "$m_default": [],
                "$m_description": "List of instructions",
                "$m_label": "Instructions"
            },
            "project_brief": {
                "desc": {
                    "$m_required": false,
                    "$m_type": "array",
                    "$m_default": [],
                    "$m_description": "Description of the project brief",
                    "$m_label": "Project Brief Description"
                },
                "url": {
                    "$m_required": false,
                    "$m_type": "string",
                    "$m_default": "",
                    "$m_description": "URL of the project brief",
                    "$m_label": "Project Brief URL"
                }
            },
            "ui_metadata":{
                "v1":[]
            }
        }
        setRoomObject(obj)
    }

    const handleSubmit = async (mainData) => {
        let res = await network.post(
            `${config.base_path}/api/community/dashboard/house/${params.class_id}/rooms`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success("New room created successfully")
        } else
            toast.error(res.data.message, {autoClose: 2000})
    }

    return <>
        <Button variant={"outlined"} onClick={() => openForm()}>+Create New Room</Button>
        {!!roomObject && <EditFormDrawer onClose={() => setRoomObject(null)} open={!!roomObject} schema={roomObject}
                                         onSave={handleSubmit}/>}
    </>
}
