export enum WritingMode {
    Scene = "scene",
    Slugline = "slugline",
    Action = "action",
    Character = "character",
    Casting = "casting",
    Parenthetical = "parenthetical",
    Dialogue = "dialogue",
    Transition = "transition",
    General = "general"
}

export type Script = ScriptPage[]
export type ScriptPage = ScriptNode[]

export type ScriptNode = {
    type: WritingMode,
    data: any,
    id: string
}


export enum CastType {
    Narrator = "narrator",
    Member = "member"
}

export type CastData = {

    type: CastType
    actor: string,
    character?:string
}

export type CastList = CastData[]

