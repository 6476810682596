import React, { useState, useEffect } from 'react'
import { Button, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import config from "../../config";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import network from "../../network";




const useStyles = makeStyles({
  formLabel: {

    marginTop: "10px",
    color: "#191237",
    fontFamily: "Source Sans Pro",
    fontSize: "1.1rem"

  },
  textField: {
    width: '22ch',
  },

  submitBtn: {
    marginTop: "40px",
    width: "100%"

  }
});


const CreateGroupForm = (props) => {



  const [spaceId, setSpaceId] = useState("")
  const [spaceData, setSpaceData] = useState([])
  const [groupName, setGroupName] = useState("")
  const [groupDesc, setGroupDesc] = useState("")
  const [groupSubType, setGroupSubType] = useState("")
  const [groupType, setGroupType] = useState("")
  const [privateGroup, setPrivateGroup] = useState(true)
  const [isEnabled, setIsEnabled] = useState(true)
  const [ordering, setOrdering] = useState("")
  const [spaceSelectDisbaled , setSpaceSelectDisabled]=useState(false)




  const populateSpaceSelectList = async () => {

    network.get(`${config.base_path}/api/community/dashboard/spaces`)
      .then((response) => {

        setSpaceData(response.data.payload)
        if (!props.formData)
          setSpaceId(response.data.payload[0].id)
      }
      )
  }


  const populateGroupData = () => {
    setSpaceId(props.formData.space_id)
    setGroupName(props.formData.title)
    setGroupDesc(props.formData.description)
    setPrivateGroup(props.formData.is_private)
    setGroupSubType(props.formData.sub_type)
    setGroupType(props.formData.type)
    setIsEnabled(props.formData.enabled)
    setOrdering(props.formData.ordering)
    setSpaceSelectDisabled(true)
  }


  useEffect(() => {
    populateSpaceSelectList()
    if (props.formData) { populateGroupData() }
  }, [])


  const handleSubmit = e => {

    if (spaceId === "" || spaceId == null) {
      alert("Select Space Name")
      return
    }

    else if (groupName === "" || groupName == null) {
      alert("Enter Space Name")
      return

    }
    else if (groupDesc === "" || groupDesc == null) {
      alert("Enter Space Description")
      return
    }
    else if (groupSubType === "" || groupSubType == null) {
      alert("Enter Subtype value")
      return
    }
    else if (groupType === "" || groupType == null) {
      alert("Enter group type value")
      return
    }

    if (props.formTypeEdit) {

      network.post(config.base_path + `/api/community/dashboard/groups/${props.formId}`,
        { title: groupName, description: groupDesc, is_private: privateGroup, enabled: isEnabled, ordering: ordering, sub_type: groupSubType, type: groupType },
      )
        .then((response) => {
          if (response.data.statusCode === 0) {
            alert(groupName + " group edited successfully!")
            window.location.reload();
          }
          else {
            alert(response.data.message)
          }
        })
        .catch(err => console.log(err));
    }


    else {


      var generatedSlug = groupName.toLowerCase().replace(/[^a-z0-9\s]/g, "").split(" ").join("_")

      network.post(config.base_path + `/api/community/dashboard/spaces/${spaceId}/groups`,
        { title: groupName, description: groupDesc, slug: generatedSlug, is_private: privateGroup, sub_type: groupSubType, type: groupType },
      )
        .then((response) => {
          if (response.data.statusCode === 0) {
            alert(groupName + " group created successfully!")
            window.location.reload();
          }
          else {
            alert(response.data.message)
          }
        })
        .catch(err => console.log(err));
    }

  }

  const classes = useStyles();
  return (
    <Paper style={{ display:'grid', padding: "60px 20px", maxWidth: "500px", height: "100%" }}>
      <Grid>
        {!props.formTypeEdit && <h4>Create new group</h4>}
        {props.formTypeEdit && <h4>Edit group</h4>}
      </Grid>


      <Grid container direction='row' spacing={0} alignItems="center" >


        <Grid item xs={4}   >
          <Typography className={classes.formLabel}> Space Name </Typography>
        </Grid>
        <Grid item xs={8} >
          <Select
            labelId="Select Space"
            id="space-select"
            value={spaceId}
            onChange={(e: any) => setSpaceId(e.target.value)}
            disabled ={spaceSelectDisbaled}
          >
            {spaceData.map((curEle, index) => {
              return (
                <MenuItem key={index} value={curEle.id}>{curEle.title}</MenuItem>
              )
            })
            }
          </Select>


        </Grid>
        <Grid item xs={4}   >

          <Typography className={classes.formLabel}> Group Name </Typography>
        </Grid>
        <Grid item xs={8}  >
          <TextField
            className={classes.textField}
            required id="standard-required" label="Enter Group Name"
            onChange={(e: any) => setGroupName(e.target.value)}
            value={groupName}
          />
        </Grid>

        <Grid item xs={4}  >
          <Typography className={classes.formLabel}>
            Group Description
          </Typography>

        </Grid>

        <Grid item xs={8}  >
          <TextField
            className={classes.textField}
            required id="standard-required" label="Enter Group Description"
            multiline = {true}
            maxRows={5}
            onChange={(e: any) => setGroupDesc(e.target.value)}
            value={groupDesc}
          />
        </Grid>

        <Grid item xs={4}  >
          <Typography className={classes.formLabel}>
            Sub Type
          </Typography>

        </Grid>

        <Grid item xs={8}  >
          <TextField
            className={classes.textField}
            required id="standard-required" label="Enter sub_type of group"
            multiline = {true}
            maxRows={5}
            onChange={(e: any) => setGroupSubType(e.target.value)}
            value={groupSubType}
          />
        </Grid>

        <Grid item xs={4}  >
          <Typography className={classes.formLabel}>
            Type
          </Typography>

        </Grid>

        <Grid item xs={8}  >
          <TextField
            className={classes.textField}
            required id="standard-required" label="Enter type of group"
            multiline = {true}
            maxRows={5}
            onChange={(e: any) => setGroupType(e.target.value)}
            value={groupType}
          />
        </Grid>


        <Grid item xs={4}>
          <Typography className={classes.formLabel}>
            Group Type
          </Typography>

        </Grid>

        <Grid item xs={8} >
          <FormControlLabel
            control={
              <Switch
                checked={privateGroup}
                onChange={(e: any) => { setPrivateGroup(e.target.checked) }}
                name="privateGroup"
                color="primary"
              />
            }
            label="Private"
          />
        </Grid>


        {props.formTypeEdit && <><Grid item xs={4}>
          <Typography className={classes.formLabel}>
            Group Enabled
          </Typography>

        </Grid>

          <Grid item xs={8} >
            <FormControlLabel
              control={
                <Switch
                  checked={isEnabled}
                  onChange={(e: any) => { setIsEnabled(e.target.checked) }}
                  name="enabledGroup"
                  color="primary"
                />
              }
              label="Enabled"
            />
          </Grid>

          <Grid item xs={4}  >
            <Typography className={classes.formLabel}>
              Group Ordering
            </Typography>

          </Grid>

          <Grid item xs={8}  >
            <TextField
              className={classes.textField}
              required id="standard-required" label="Enter Group Ordering"
              onChange={(e: any) => setOrdering(e.target.value)}
              value={ordering}
              // pattern= "[0-9]*"
            />
          </Grid>

        </>}

      </Grid>
      <Button className={classes.submitBtn} style={{height:'40px'}} variant="contained" color="primary"  onClick={handleSubmit}>Submit</Button>
    </Paper>
  )
}

export default CreateGroupForm
