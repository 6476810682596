import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {UserSearch} from "../../pages/home";
import {useState} from "react";
import {executeBotCommand} from "./UserEnrollments";
import {toast} from "react-toastify";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props}/>;
});

export default function AccMergePopup(props: { user: any, open: boolean, handleClose: any }) {
    const {open, handleClose, user} = props
    const [checked, setChecked] = useState(false)
    const [getUser, setGetUser] = useState(null)

    async function onMerging() {
        const res = await executeBotCommand(`user merge ${user._id} ${getUser._id}`)
        console.log(res)
        if (!res.data.response.error) {
            toast.success('Account Merged')
            handleClose()
        } else toast.error('Error: ' + res.data.response.error)
    }

    return <React.Fragment>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Merge two accounts</DialogTitle>
            <DialogContent>
                <b>search account that has to be merge with <span style={{color: 'red'}}>{user.username}</span></b>
                <UserSearch desirablePath={"in_acc_merge"} getDetails={(e) => setGetUser(e)}/>
                <Stack direction={'row'} alignItems={'center'}>
                    <Checkbox checked={checked} onChange={() => setChecked(!checked)}/>
                    <DialogContentText id="alert-dialog-slide-description" style={{color: 'red'}}>
                        Both account details have been verified twice**
                    </DialogContentText>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={onMerging} disabled={!checked}>
                    Merge
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}