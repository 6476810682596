import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Grid from "@mui/material/Grid";
import BasePage from "../../components/BasePage";

const options = [
    "cmaf-av1",
    "cmaf",
    "community_collab_mugafi",
    "dash",
    "dashboard_mugafi",
    "extras",
    "mugafi_landing_page",
    "mugafi",
    "onboarding",
    "processed",
    "promotions",
    "sync",
    "test",
    "unlucourse",
    "website"
]

const configuration = {
    name: "class_type",
    options: [
        {name: "unlu_general", options: options},
        {name: "mugfi", options: options},
        {name: "unlucourse", options: options},
        {name: "fellowship", options: options},
        {name: "sync", options: options},
        {name: "mugafi_landing_page", options: options}
    ],
};


export default function FileUpload() {
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedFolder, setSelectedFolder] = useState("");
    const [file, setFile] = useState(null);

    const handleFileUpload = (e) => {
        setFile(e.target.files[0]);
    };


    const handleUploadClick = () => {
        const selectedOptionText = selectedOption;
        const selectedFolderText = selectedFolder;

        if (!file || !selectedOption || !selectedFolder) {
            alert("Please select an image, option, and folder");
        } else {
            const imageUrl = URL.createObjectURL(file as any);
            // setSelectedImageUrl(imageUrl);
            // alert(`Option Selected: ${selectedOptionText} Folder Selected: ${selectedFolderText} Image Uploaded: ${file.name}`);
            alert("Not implemented")
        }
    }

    const isDisabled = !selectedOption || !selectedFolder || !file;

    const RenderDropdown = (options) => {
        return <FormControl sx={{minWidth: 120}}>
            <InputLabel id="demo-simple-select-label">Option</InputLabel>
            <Select value={selectedFolder} onChange={handleChangeFolder} label={'Select Course'}>
                <MenuItem disabled>Select Folder</MenuItem>
                {Object.keys(options).map((key) => (
                    <MenuItem key={key} value={options[key]}>
                        {options[key]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    };

    const handleChangeOption = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleChangeFolder = (event) => {
        setSelectedFolder(event.target.value);
    };

    return <BasePage>
        <Box mt={4}>
            <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom>
                        Upload File
                    </Typography>
                    <TextField
                        type="file"
                        variant="outlined"
                        fullWidth
                        onChange={handleFileUpload}
                    />
                    <Box mt={2}>
                        <Stack direction={'row'}>
                            <RadioGroup aria-label="options" value={selectedOption} onChange={handleChangeOption}>
                                {configuration.options.map((option) => (
                                    <FormControlLabel key={option.name} value={option.name} control={<Radio/>}
                                                      label={option.name}/>
                                ))}
                            </RadioGroup>
                            {selectedOption &&
                                configuration.options.find((option) => option.name === selectedOption).options && (
                                    <Box>
                                        {RenderDropdown(configuration.options.find((option) => option.name === selectedOption).options)}
                                    </Box>
                                )}
                        </Stack>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<CloudUploadIcon/>}
                            onClick={handleUploadClick}
                            disabled={isDisabled}
                        >
                            Upload
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </BasePage>
}
