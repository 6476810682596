import React, { useState } from "react";
import network from "network";
import config from "config";
import Button from "@mui/material/Button";
import EditFormDrawer from "components/custom/EditFormDrawer";
import { toast } from 'react-toastify';

export default function CreateSyncTags() {
    const [SettingObject, setSettingObject] = useState(null)

    async function loadFunc() {
        const module = await network.get(`${config.base_path}/api/dashboard/asset_tag/sample`)
        setSettingObject(module.data.payload)
    }
    const handleSubmit = async (mainData) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/asset_tag/create`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success('sync tag Created', { autoClose: 2000 })
        }
        else
            toast.error(res.data.message, { autoClose: 2000 })
    }
    return <>
        <Button variant={"outlined"} onClick={() => loadFunc()}>+Create New Tag</Button>
        {!!SettingObject && <EditFormDrawer onClose={() => setSettingObject(null)} open={!!SettingObject} schema={SettingObject} onSave={handleSubmit} />}
    </>
}
