import React, {useState, useEffect} from 'react'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';


const RightDrawer = (props) => {

    const FormComponent = props.formComponent

    const formTypeEdit = props.formType === "edit"

    const toggleDrawer = (open) => (event) => {

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.setDrawerState(open);
    };

    return <Drawer anchor="right" open={props.drawerState} onClose={toggleDrawer(false)}>

        <IconButton
            onClick={toggleDrawer(false)}
            style={{position: "absolute", right: "10px", margin: "20px 0"}}
            size="large">
            <CloseIcon/>
        </IconButton>

        {!formTypeEdit && <FormComponent toggleDrawer={toggleDrawer}/>}


        {formTypeEdit &&
            <FormComponent toggleDrawer={toggleDrawer} formTypeEdit={formTypeEdit} formId={props.formId}
                           formData={props.formData}/>}
    </Drawer>;
}

export default RightDrawer
