import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import EditFormDrawer from "components/custom/EditFormDrawer";
import {useNavigate, useParams} from "react-router-dom";
import CreateFellowship from "../create";
import {tellSlack} from "network/apis";
import {toast} from "react-toastify"
import LongMenu from "components/custom/LongMenu";
import EditIcon from '@mui/icons-material/Edit';
import {styled, useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CopyIDButton from "../../../components/custom/CopyIdButton";
import useMediaQuery from "@mui/material/useMediaQuery";

interface OptionConfig {
    item: string,
    redirect?: string,
    onClickFunc?: any,
    extraInfo?: any
}

const EditButton = styled(IconButton)(() => ({
    width: '35px',
    height: '35px',
    borderRadius: '50%',
}))

function classType(type) {
    switch (type) {
        case 520:
            return 'club'
        case 510:
            return 'fellowship'
        case 500:
            return 'unluclass'
        default:
            return type
    }
}

function FellowShip(props) {
    const navigate = useNavigate()
    const params = useParams()
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'))

    const onClone = async (id) => {
        const text = "Are you sure about it?"
        if (window.confirm(text) === true) {
            const result = await network.post(`${config.base_path}/api/dashboard/class/${id}/next_batch`, {})
            if (result.data.statusCode === 0) {
                toast.success('cloned successfully')
                props.loadFunc
            } else
                toast.error(result.data.message)
        } else navigate(`/fellowship/list/${params.page}`)
    }

    const option: OptionConfig[] = [
        {
            item: 'Demos',
            redirect: `/fellowship/${props._id}/demo/list/1`
        },
        {
            item: 'FAQs',
            redirect: `/fellowship/${props._id}/faq/1`
        },
        {
            item: 'Clone fellowship',
            onClickFunc: () => onClone(props._id)
        }
    ]
    if (props.tags.includes('express')) {
        option.push({
            item: 'Get Leads',
            redirect: `/marketing/leads/${props.slug}`,
            extraInfo: 'express'
        })
    }

    return <Grid item xs={12}>
        <Card style={{margin: "8px", padding: "8px", width: '100%', overflowX: matchMobile ? 'scroll' : 'clip'}}>
            <Stack direction={matchMobile ? 'column' : 'row'} justifyContent={'space-between'}>
                <Box>
                    <CopyIDButton id={props._id}/>
                    <p>Title: {props.title}</p>
                    <p>Slug: {props.slug}</p>
                    <p>Class Type: {classType(props.class_type)}</p>
                </Box>
                <Stack direction={'row'} sx={{maxHeight: '5vh', alignSelf: 'center'}}
                       spacing={matchMobile ? 1 : 5} alignItems={'center'}>
                    <Button variant="outlined" sx={{color: '#5053EC', borderColor: '#5053EC'}}
                            onClick={() => navigate(`/fellowship/${props._id}/enrollments/list/1`)}>
                        enrollments
                    </Button>
                    <Button variant={"outlined"}
                            onClick={() => navigate(`/fellowship/${props._id}/modules/1`)}>
                        Modules
                    </Button>
                    <EditButton onClick={props.onEdit}>
                        <EditIcon sx={{margin: '8px', cursor: 'pointer'}} fontSize={'small'}/>
                    </EditButton>
                    <LongMenu options={option}/>
                </Stack>
            </Stack>
        </Card>
    </Grid>
}

export default function ListFellowship(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = props?.params || useParams()
    const classtype = props.classType

    async function loadFunc(page) {
        const fellowships = await network.get(`${config.base_path}/api/dashboard/unluclass/list/${page}?class_type=${classtype}`)
        setHasMore(fellowships.data.payload.length !== 0)
        setItems(fellowships.data.payload)
    }

    const onSave = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/unluclass/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc(params.page)
        toast.success("Edited Successfully", {autoClose: 1000})
    }


    return <BasePage>
        <CreateFellowship/>
        <CDataTable
            pageUrl={`/${classtype === 520 ? 'house' : 'fellowship'}/list/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
        >{items.map((a, index) => <FellowShip key={index} {...a} onEdit={() => setEditObject(a)}
                                              classType={classtype}/>)}</CDataTable>
        {!!editObject && <EditFormDrawer id='unlu_fellowship' title={"Edit fellowship"}
                                         onSave={onSave}
                                         onClose={() => setEditObject(null)}
                                         open={!!editObject}
                                         schema={editObject}/>}
    </BasePage>
}
