import {AxiosResponse} from "axios";

import {NI} from "./index";

export default (url, payload, config?): Promise<AxiosResponse> => {
    const {headers} = config || {}
    return NI.post(url, payload, {
        headers: Object.assign({Authorization: `Bearer ${localStorage.getItem('user_data')}`}, headers || {})
    })
}
