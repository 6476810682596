import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import ButtonGroup from "@mui/material/ButtonGroup";
import RightDrawer from "components/custom/RightDrawer";
import CDataTable from "components/custom/CDataTable"
import CreateSkillForm from "components/community/CreateSkillForm";
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useParams , Navigate } from "react-router-dom";

const CommunitySkills = (props) => {
    let pageSize = 10;
    const formType = "edit";
    const [skills, setSkills] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [editSkillDrawer, setEditSkillDrawer] = useState(false);
    const [formDataId, setFormDataId] = useState();
    const [formData, setFormData] = useState([]);
    const [createSkillDrawer, setCreateSkillDrawer] = useState(false);
    const [searchValue , setSearchValue] = useState("")
    const [page,setPage] = useState(0)
    const params = useParams()


    const handleChange = (e) => {
        setSearchValue(e.target.value)
    }

    useEffect(()=>{
        loadFunc(1)
    },[searchValue])

    async function loadFunc(page) {
        const response = await network.get(config.base_path + `/api/dashboard/skills?page=${page}&page_size=${pageSize}&search=${searchValue}`)
        setPage(page)
        setHasMore(response.data.payload.length !== 0)
        setSkills(response.data.payload)
    }
    function handleEdit(id) {
        let skillData = skills.find((o) => o.id === id);
        setEditSkillDrawer(true);
        setFormDataId(id);
        setFormData(skillData);
    }
    function handleDelete(id){
        const text = "Are you sure,wants to delete skill?";
        if (window.confirm(text) === true) {
            network
                ._delete(`${config.base_path}/api/dashboard/skills/${id}`)
                .then((response) => {
                    window.location.reload();
                });
        } else {
            Navigate({to:'/'})
    }
    }
    function Skill(props) {
        return <Grid item xs={12}>
            <Card style={{ margin: "8px", padding: "8px" }}>
                <Grid container direction={"row"}>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Grid item xs={12}>{pageSize * page - (pageSize - (props.index + 1))}. {props.name}</Grid>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Grid item xs={12}>
                            <ButtonGroup>
                                <Button variant={"outlined"} color={"success"} onClick={() => handleEdit(props.id)}>
                                    Edit
                                </Button>
                                <Button variant={"outlined"} color={"error"} onClick={()=>handleDelete(props.id)}>
                                    Delete
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }
    return <BasePage>
    <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography variant={"h6"} style={{flexGrow: 1 ,fontWeight: "bold"}}>Active skill list</Typography>
            <TextField
                type="text"
                name="searchValue"
                value={searchValue}
                onChange={handleChange}
                autoComplete="off"
                variant="outlined"
                label="Search Skill..."
            />
            <Button variant="outlined" onClick={() => setCreateSkillDrawer(true)}>
                + Create New Skill
            </Button>
        </Stack>
        <Divider style={{width: "100%", margin: "8px"}}/>
        <CDataTable
            pageUrl={`/community/skills/`}
            pageChanged={loadFunc}
            page={parseInt(params.page)}
            hasMore={hasMore}
            items={skills.map((a, index) => <Skill key={index} index={index} {...a} />)} />


            <RightDrawer
            drawerState={editSkillDrawer}
            setDrawerState={setEditSkillDrawer}
            formComponent={CreateSkillForm}
            formType={formType}
            formId={formDataId}
            formData={formData}
            />
            <RightDrawer
            drawerState={createSkillDrawer}
            setDrawerState={setCreateSkillDrawer}
            formComponent={CreateSkillForm}
            />
    </BasePage>
};

export default CommunitySkills;
