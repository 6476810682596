import React, {useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import config from "../config";
import network from "../network";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import BasePage from "../components/BasePage";
import {useState} from 'react';
import Typography from "@mui/material/Typography";
import {toast} from "react-toastify";
import {executeBotCommand} from "../components/users/UserEnrollments";
import Avatar from "@mui/material/Avatar";

export default function Home(props) {

    return <BasePage>
        <Stack justifyContent={'center'} spacing={4} sx={{height: '70vh'}}>
            <UserSearch/>
            <img src={'https://unlu-general.s3.ap-south-1.amazonaws.com/mugafi/Website_Header_Black.svg'} alt={''}
                 width={'600px'} style={{alignSelf: 'center'}}/>
        </Stack>
    </BasePage>
};

export function UserSearch(props: { desirablePath?: string, getDetails?: any }) {
    const [search, setSearch] = useState('')
    const [users, setUsers] = useState([])
    const [searchBy, setSearchBy] = useState('username');
    const [showInstantUserResult, setShowInstantUserResult] = useState(null);
    const [userData, setUserData] = useState<{
        id,
        name: { first: "...", last: "..." }
        username
        _id
        email
        phone_number
        about
        response_time
        gender
        scope
    }>(null)
    const inAccMerge = props.desirablePath === "in_acc_merge"

    async function userResult() {
        if (search !== "") {
            if (searchBy === "phone" || searchBy === "email") {
                const res = await executeBotCommand(searchBy !== "email" ? `user phone ${search}` : `user email ${search}`)
                if (!res.data.response?.error)
                    inAccMerge ? setShowInstantUserResult(search) : window.location.assign("/user/" + res.data.response.username)
                else toast.error(res.data.response.error)
            } else
                inAccMerge ? setShowInstantUserResult(search) : window.location.assign("/user/" + search)
        }
    }

    const debounce = (func, delay) => {
        let debounceTimer;
        return function (value) {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const delayedSearch = debounce(async (value) => {
        if (searchBy === "username" && !inAccMerge) {
            const res = await network.get(
                `${config.base_path}/api/community/search/members?searchBy=${searchBy}&search=${value}`
            )
            if (res.data.statusCode === 0)
                setUsers(res.data.payload.results || [{username: 'sat'}]);
            else
                console.error('Error fetching search results:', res.data.message);
        }
    }, 500);

    const onInputChange = (e) => {
        setSearch(e.target.value);
        delayedSearch(e.target.value);
    };

    useEffect(() => {
        if (userData && props.getDetails) {
            props.getDetails(userData)
        }
    }, [userData]);

    useEffect(() => {
        if (showInstantUserResult) {
            const fetchUser = () => {
                network.get(`${config.base_path}/api/dashboard/user/${search}/get`)
                    .then(response => {
                        if (response.data.payload.length !== 0) {
                            setUserData(response.data.payload)
                        }
                    })
                    .catch(err => console.log(err));
            };

            fetchUser()
        }
    }, [showInstantUserResult])

    if (showInstantUserResult) {
        if (!userData)
            return <Typography>
                We couldn't find a user with the provided information. Please double-check and try again.
            </Typography>

        return <Stack padding={'16px 0px'}>
            <Avatar
                alt={userData.name.first}
                src={`${config.base_path}/api/user/profile/${userData.id}/pic`}
            />
            <Typography variant={"subtitle1"}>
                Name: {userData.name?.first} {userData.name?.last}
                <br/>
                Username: {userData.username}
                <br/>
                UserId: {userData._id}
                <br/>
                {!!userData.email && <>Email: {userData.email}<br/></>}
                {!!userData.phone_number && <>Phone Number: {userData.phone_number}<br/></>}
            </Typography>
        </Stack>
    }

    return <Stack direction={"row"} spacing={2} sx={{minWidth: '550px', alignSelf: 'center'}}>
        <Stack>
            <Typography variant="subtitle1" sx={{alignSelf: 'center', fontWeight: 700, marginRight: 'auto'}}>
                Search By :
            </Typography>
            <Select
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
                variant="outlined"
                sx={{minWidth: '100px', maxHeight: '50%'}}
            >
                <MenuItem value="username">Username</MenuItem>
                <MenuItem value="phone">Phone Number</MenuItem>
                <MenuItem value="email">Email</MenuItem>
            </Select>
        </Stack>
        <Autocomplete
            id="search-user"
            options={users}
            onInputChange={(event, value) => {
                setSearch(value)
            }}
            onKeyPress={e => {
                if (e.which === 13)
                    userResult()
            }}
            onInput={(e) => {
                onInputChange(e)
            }}
            getOptionLabel={(option) => option.username}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label=" Search Username..."
                    variant="outlined"
                />
            }
            sx={{width: '100%', alignSelf: 'center'}}
        />
        <IconButton
            type="submit"
            aria-label="search"
            onClick={() => userResult()}
            size="large">
            <SearchIcon/>
        </IconButton>
    </Stack>
}
