import user_data from "./user_data";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";

export default {
    user_data
}

export function withNavigation(Component) {
    return props => <Component {...props} navigate={useNavigate()}/>;
}

export function withParams(Component) {
    return props => <Component {...props} match={{params: useParams()}}/>;
}
