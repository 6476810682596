import React, { useState } from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import CDataTable from "components/custom/CDataTable";
import Card from "@mui/material/Card";
import { useParams } from "react-router-dom";
import TagMapping from "./createtagmapping";
import { ButtonGroup, Button } from "@mui/material";
import { toast } from "react-toastify";


export default function ListSyncTags(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const params = useParams()

    async function loadFuncParent(page) {
        const asset_tag_mapping = await network.get(`${config.base_path}/api/dashboard/asset_tag_mapping/list/${page}?asset_id=${params.asset_id}`)
        setHasMore(asset_tag_mapping.data.payload.length !== 0)
        setItems(asset_tag_mapping.data.payload)
    }
    function SyncAssetTags(props) {
        return <Grid item xs={12}>
            <Card style={{ margin: "8px", padding: "8px" }}>
                <Grid container direction={"row"}>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Grid item xs={12}>Name: {props.name}</Grid>
                        <Grid item xs={12}>Type: {props.type}</Grid>
                    </Grid>
                    <ButtonGroup>
                        <Button variant={"outlined"} color={"error"} onClick={() => handleClick(props._id)}>Delete</Button>
                    </ButtonGroup>
                </Grid>
            </Card>
        </Grid>
    }
    async function handleClick(id) {
        await network.post(`${config.base_path}/api/dashboard/asset_tag_mapping/delete`, {
            asset_id: params.asset_id,
            tag_id: id
        })
        toast.success("Deleted Successfully", { autoClose: 1000 })
        setInterval(() => window.location.reload(), 1000);
    }


    return <BasePage>
        <TagMapping />
        <CDataTable
            pageUrl={`/sync/artists/${params.user_id}/assets/${params.asset_id}/`}
            page={parseInt(params.page)}
            pageChanged={loadFuncParent}
            hasMore={hasMore}
            items={items.map((a, index) => <SyncAssetTags key={index} {...a} />)} />
    </BasePage>
}
