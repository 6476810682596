import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";


function Member(props) {
    const params = useParams()
    const onRemove = async () => {
        if (window.confirm("Are you sure to remove " + props?.username)) {
            const removeMember = await network.post(`${config.base_path}/api/dashboard/group_members/remove?group_id=${params.group_id}`, {
                user_ids: [props?.id]
            })
            if (removeMember.data.statusCode === 0)
                return toast('Member Removed Successfully')
            else
                return toast(removeMember.data.message)
        }
    }
    return <Grid item xs={12}>
        <Card style={{margin: "8px", padding: "8px"}}>
            <Grid container direction={"row"} style={{alignItems: 'center'}}>
                <Grid item style={{flexGrow: 1}}>
                    <Grid item xs={12}>Name : <b>{props?.name.first + props?.name?.last}</b></Grid>
                    <Grid item xs={12}>UserName : <b>{props?.username}</b></Grid>
                </Grid>
                <Grid item xs={12} md={"auto"}>
                    <Grid item xs={12}>
                        <Button variant={"outlined"} color={"error"} onClick={onRemove}>Remove</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    </Grid>
}

export default function GroupMember(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const params = useParams()

    async function loadFunc(page) {
        const work = await network.get(`${config.base_path}/api/dashboard/group_members/list/${page}?group_id=${params.group_id}`)
        setHasMore(work.data.payload.length !== 0)
        setItems(work.data.payload)
    }


    return <BasePage>
        <Grid display={"flex"}>
            {/*<CreateWorkOppo />*/}
        </Grid>
        <CDataTable
            pageUrl={`/community/${params.group_id}/members/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <Member key={index} {...a}/>)}/>
    </BasePage>
}
