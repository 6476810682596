import React, {useState} from "react";
import network from "network";
import config from "config";
import {toast} from "react-toastify";
import Button from "@mui/material/Button";
import EditFormDrawer from "components/custom/EditFormDrawer";

export type TagType = {name: string, type: number, slug: string}

export default function CreateTags(props) {
    const [object, setObject] = useState(null)
    const tagType = parseInt(props.type)
    async function loadFunc() {
        const tags : TagType = {name: '', type: tagType, slug: ''}
        setObject(tags)
    }
    const handleSubmit = async (mainData) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/tags/add`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success(`New Tag Created`, {autoClose : 2000})
        }
        else
            toast.error(res.data.message , {autoClose:2000})
    }
    return <>
        <Button variant={"outlined"} onClick={() => loadFunc()}>{`+Create New Tag`}</Button>
        {!!object && <EditFormDrawer id={'unlu_tags'} title={`Create New Tag`} onClose={() => setObject(null)} open={!!object} schema={object} onSave={handleSubmit} />}
    </>
}