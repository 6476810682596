import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import config from "config";
import network from "network";
import DialogBox from 'components/custom/dialog-box'
import BasePage from "components/BasePage";
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import EditUserInfo from 'components/users/EditUserInfo';
import {Paper} from '../../../node_modules/@mui/material/index';
import {useUser} from "../../components/UserProvider";
import UserScopeDialog from "../../components/userscope-dialog";
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import UserEnrollments from 'components/users/UserEnrollments';
import AccMergePopup from "../../components/users/AccountMergePopup";
import ButtonGroup from "@mui/material/ButtonGroup";

export default function UserProfile() {
    const [data, setData] = useState<{
        id,
        name: { first: "...", last: "..." }
        username
        _id
        email
        phone_number
        about
        response_time
        gender
        scope
    }>()
    const user = useUser()
    const params = useParams()
    const [dialogBoxMessage, setDialogBoxMessage] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [resetPasswordModal, setResetPasswordModal] = useState(false)
    const [editUser, setEditUser] = useState(false);
    const [openUserScope, setOpenUserScope] = useState(false)
    const [openEnrollmentsDrawer, setOpenEnrollmentsDrawer] = useState(false)
    const [openAccMergePopup, setOpenAccMergePopup] = useState(false)
    const isAdmin = !!user?.scope?.find(a => a === 'admin')
    const isManager = !!user?.scope?.find(a => a === 'manager') || isAdmin

    const resetPassword = (userId) => {

        network.post(`${config.base_path}/api/dashboard/user/${userId}/password/reset`, {})
            .then(response => {
                setResetPasswordModal(true)
                setDialogBoxMessage(response.data.message)
                toast(response.data.statusCode === 0 ? `New Password: ${response.data.payload}` : "")
                setNewPassword(response.data.statusCode === 0 ? `New Password: ${response.data.payload}` : "")
            })
            .catch(err => {
                setResetPasswordModal(true)
                setDialogBoxMessage(err.message)
            });
    };

    useEffect(() => {
        fetchUser()
    }, [])

    const fetchUser = () => {
        network.get(`${config.base_path}/api/dashboard/user/${params.id}/get`)
            .then(response => {
                if (response.data.payload.length !== 0)
                    setData(response.data.payload)
            })
            .catch(err => console.log(err));
    };

    let userData = data;
    if (!userData)
        return <div> No Data to show</div>

    return <BasePage>
        <Paper variant={'outlined'} sx={{padding: '20px'}}>
            <Stack direction={'row'} alignItems={'center'} spacing={2} sx={{margin: '16px 0'}}>
                <Avatar
                    alt={userData.name.first}
                    src={`${config.base_path}/api/user/profile/${userData.id}/pic`}
                    style={{width: '60px', height: '60px'}}
                />
                <Typography><b>{userData.name?.first} {userData.name?.last}</b></Typography>
            </Stack>
            <Stack direction={'row'} spacing={8}>
                <Stack spacing={2}>
                    <Typography>Username: <br/><b>{userData.username}</b></Typography>
                    <Typography>UserId: <br/><b>{userData._id}</b></Typography>
                </Stack>
                {userData && <Stack spacing={2}>
                    <Typography>Email: <br/><b>{userData.email || `not provided`}</b></Typography>
                    <Typography>Phone Number: <br/><b>{userData.phone_number || `not provided`}</b></Typography>
                </Stack>}
            </Stack>
            <Stack sx={{margin: '16px 0'}} spacing={1}>
                <Typography>About: <b>{userData?.about}</b></Typography>
                <Typography>Response Time : <b>{userData.response_time / 86400000} Days</b></Typography>
                <Typography>Gender: <b>{userData.gender === 1 ? "Male" : "Female"}</b></Typography>
                <Typography>Response Time : <b>{userData.response_time / 86400000} Days</b></Typography>
                <Typography>User's Scope: <b>{userData.scope.join(", ")}</b></Typography>
            </Stack>
            <Stack spacing={2}>
                <ButtonGroup>
                    <Button variant={"outlined"} onClick={() => setEditUser(true)}>
                        Edit Profile
                    </Button>
                    {isManager && <Button variant="outlined" onClick={() => setOpenEnrollmentsDrawer(true)}>
                        Show Enrollments
                    </Button>}
                </ButtonGroup>
                {isAdmin && <ButtonGroup>
                    <Button variant={"outlined"} onClick={() => setOpenUserScope(true)}>
                        Edit Scope
                    </Button>
                    <Button variant={"outlined"} onClick={() => resetPassword(userData._id)}>
                        Reset Password
                    </Button>
                    <Button variant="outlined" onClick={() => setOpenAccMergePopup(true)}>
                        Merge account
                    </Button>
                </ButtonGroup>}
            </Stack>
        </Paper>
        {
            editUser && <EditUserInfo drawerState={editUser} setDrawerState={setEditUser} id={userData.id}
                                      userData={userData}/>
        }
        {
            openEnrollmentsDrawer && <UserEnrollments user={userData}
                                                      drawerState={openEnrollmentsDrawer}
                                                      setDrawerState={setOpenEnrollmentsDrawer}
            />
        }
        {
            openAccMergePopup &&
            <AccMergePopup user={userData} open={openAccMergePopup}
                           handleClose={() => setOpenAccMergePopup(false)}
            />
        }
        {
            openUserScope && <UserScopeDialog open={openUserScope}
                                              closeBox={() => setOpenUserScope(false)}
                                              dialogData={{userId: userData._id, existingScope: userData.scope}}/>
        }

        {
            resetPasswordModal && <DialogBox closeBox={() => setResetPasswordModal(false)}
                                             heading={dialogBoxMessage}
                                             acceptText={'Okay!'}
                                             open={resetPasswordModal}
                                             confirm={() => setResetPasswordModal(false)}
                                             text={newPassword} cancelText={'Cancel'}/>
        }
    </BasePage>
};
