import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import network from "../network";
import config from "../config";
import processCoupon from "../utils/coupon_processor";
import DialogBox from './custom/dialog-box'
import Stack from "@mui/material/Stack";
import MultiChipSelectV2 from "./custom/MultiChipSelectV2";
import {DatePicker} from "@mui/lab";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Switch} from '@mui/material';


export const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

const couponDeliverableTypeFilter = [
    {id: 430, label: "unluclass"},
    {id: 440, label: "All Access Pass"},
    {id: 450, label: "Fellowship"},
    {id: 520, label: "House"},
    {id: 499, label: "Cart"},
    {id: 490, label: "Sync Asset"}
]

function BatchCoupon(props) {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [confirmDialogContent, setConfirmDialogContent] = useState<any>(null)
    const [couponRulesCheckerText, setCouponRulesCheckerText] = useState<string[]>([])
    const [isDealValid, setIsDealValid] = useState(false)
    const [showClassFilter, setShowClassFilter] = useState(false)
    const [showFellowshipFilter, setShowFellowshipFilter] = useState(false)
    const [showHouseFilter, setShowHouseFilter] = useState(false)
    const [house, setHouse] = useState([])
    const [fellowships, setFellowships] = useState([])
    const [unluclasses, setUnluclasses] = useState([])
    const [couponFor, setCouponFor] = useState([])
    const [firstPurchase, setFirstPurchase] = useState(false)
    const [prefixValue, setPrefixValue] = useState("")
    const [batchNumber, setBatchNumber] = useState("")
    const [couponDeal, setCouponDeal] = useState("")
    const [totalCoupons, setTotalCoupons] = useState(1)
    const [email, setEmail] = useState("")
    const [codeLength, setCodeLength] = useState(8)
    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment().add(1, "month"))
    const [selectedUnluclass, setSelectedUnluclass] = useState([])
    const [selectedFellowship, setSelectedFellowship] = useState([])
    const [selectedHouse, setSelectedHouse] = useState([])

    function confirmCouponCreation() {
        const confirmationText = confirmCouponCreationText()
        setConfirmDialogContent(confirmationText)
        if (!!confirmationText)
            setConfirmDialogOpen(true);
    }

    function confirmCouponCreationText() {

        if (!isDealValid) {
            return alert("Please check the description at the end of the page and provide a valid coupon deal.")
        }

        if (batchNumber.trim() === "" || couponDeal.trim() === "" || totalCoupons < 1 || couponFor.length === 0 || email.trim() === "" || codeLength > 8) {
            return alert("All fields are mandatory.")
        }

        if (!Number.isInteger(Number(batchNumber.trim()))) {
            return alert("Batch number must be a number.");
        }

        if (!emailRegex.test(email)) {
            return alert('Enter a valid Email id.')
        }

        if (!startDate.isValid()) {
            return alert('Enter a valid Start Date')
        }

        if (!endDate.isValid()) {
            return alert('Enter a valid Expiry Date')
        }

        return <>
            {`Batch Number: ${batchNumber}`}<br/>
            {`Coupon Deal: ${couponDeal}`}<br/>
            {`StartDate: ${startDate}`}<br/>
            {`End Date: ${endDate}`}<br/>
            {`First Purchase Only: ${firstPurchase}`}<br/>
            {`Total Coupons: ${totalCoupons}`}<br/>
            {`Prefix: ${prefixValue}`}<br/>
            {`Coupon For: ${couponFor.map(a => a.label).join(",")}`}<br/>
            {`email: ${email.toLowerCase()}`}<br/>
            {`code_length: ${codeLength}`}<br/>
            {selectedUnluclass.length ? `unluclasses: ${selectedUnluclass.map(a => a.label).join(" | ")}` : ""}<br/>
            {selectedFellowship.length ? `fellowships: ${selectedFellowship.map(a => a.label).join(" | ")}` : ""}<br/>
            {selectedHouse.length ? `house: ${selectedHouse.map(a => a.label).join(" | ")}` : ""}<br/>
        </>
    }

    function createCouponApiCall() {
        const classes = (selectedUnluclass || []).concat((selectedFellowship || [])).concat((selectedHouse || []))

        const requestPayload = Object.assign({
                unique_batch_no: batchNumber,
                deal: couponDeal,
                start_date: startDate.toDate().getTime(),
                end_date: endDate.toDate().getTime(),
                coupon_for: couponFor.map(a => a.id),
                total: totalCoupons,
                prefix: prefixValue,
                email: email.toLowerCase(),
                code_length: codeLength,
                first_purchase_only: firstPurchase
            },
            classes.length > 0 ? {unluclasses: classes.map(a => a.id)} : {})

        network.post(`${config.base_path}/api/dashboard/coupon/batch/generate`, requestPayload)
            .then(response => {
                if (response.data.statusCode === 0) {
                    alert(`Coupon Batch ${batchNumber} generation started. Please check your email Id`)
                    return setConfirmDialogOpen(false)
                }
                alert("There is some error in the request. \n" + response.data.message)
            })
            .catch(err => {
                console.log(err);
                alert("There is some error in the request. \n" + err)
            });
    }

    function checkCouponDeal(value) {
        setCouponDeal(value)
        let tests = []
        let validityFlag = true;
        for (let i = 0; i < 5; i++) {
            const price = Math.pow(10, i)
            const currency = "INR"
            try {
                const re = processCoupon({deal: value}, price, currency)
                tests.push(<>
                    <b>{i + 1}. Original price:</b> {price} {currency} <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;new price: {re.price} <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;<b style={{color: 'green'}}>Description: {re.description}</b><br/><br/>
                </>)
            } catch (e) {
                tests.push(<>
                    <b>{i + 1}. Original price:</b> {price} {currency}
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <b style={{color: 'red'}}>Description: {e.message}</b><br/><br/>
                </>)
                validityFlag = false;
            }
        }

        setIsDealValid(validityFlag)
        setCouponRulesCheckerText(tests)
    }

    useEffect(() => {
        const couponForIds = couponFor.map(a => a.id)
        setShowClassFilter(couponForIds.includes(430))
        setShowFellowshipFilter(couponForIds.includes(450))
        setShowHouseFilter(couponForIds.includes(520))
    }, [couponFor])

    useEffect(() => {
        network.get(`${config.base_path}/api/web/unluclass/1/get?class_type=510&page_size=50`,)
            .then(res => {
                const classesList = res.data.payload.map(a => ({
                    id: a._id,
                    label: a.title,
                    class_type: a.class_type
                }))
                setFellowships(classesList.filter(a => a.class_type === 510))
            })
            .catch(err => console.error(err))

        network.get(`${config.base_path}/api/web/unluclass/1/get?class_type=520&page_size=50`,)
            .then(res => {
                const classesList = res.data.payload.map(a => ({
                    id: a._id,
                    label: a.title,
                    class_type: a.class_type
                }))
                setHouse(classesList.filter(a => a.class_type === 520))
            })
            .catch(err => console.error(err))

        network.get(`${config.base_path}/api/web/unluclass/1/get?class_type=500&page_size=50`,)
            .then(res => {
                const classesList = res.data.payload.map(a => ({
                    id: a._id,
                    label: a.title,
                    class_type: a.class_type
                }))
                setUnluclasses(classesList.filter(a => a.class_type === 500))
            })
            .catch(err => console.error(err))
    }, [])

    return (<>
        <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                    <Typography>Generate Coupon Batch</Typography>
                    <TextField
                        required
                        fullWidth
                        type={"number"}
                        name="batchNumber"
                        placeholder={"Enter a unique batch number"}
                        value={batchNumber}
                        onChange={(e: any) => setBatchNumber(e.target.value)}
                        label={<span>Enter a unique batch number</span>}
                    />
                    <TextField
                        required
                        fullWidth
                        name="couponDeal"
                        placeholder={"Enter Coupon Deal"}
                        value={couponDeal}
                        onChange={(e: any) => checkCouponDeal(e.target.value)}
                        label={<span>Enter Coupon Deal</span>}
                    />
                    <MultiChipSelectV2
                        preSelectedItems={null}
                        onSelectedItemsChanged={(items) => setCouponFor(items)}
                        defaultItems={couponDeliverableTypeFilter}
                        label={<span>Coupon For</span>}
                    />
                    {showClassFilter && <MultiChipSelectV2
                        preSelectedItems={null}
                        onSelectedItemsChanged={(items) => setSelectedUnluclass(items)}
                        defaultItems={unluclasses}
                        label={<span>unluclasses</span>}
                    />}
                    {showFellowshipFilter && <MultiChipSelectV2
                        preSelectedItems={null}
                        onSelectedItemsChanged={(items) => setSelectedFellowship(items)}
                        defaultItems={fellowships}
                        label={<span>Fellowships</span>}
                    />}
                    {showHouseFilter && <MultiChipSelectV2
                        preSelectedItems={undefined}
                        onSelectedItemsChanged={(items) => setSelectedHouse(items)}
                        defaultItems={house}
                        label={<span>house</span>}
                    />}
                    <DatePicker
                        inputFormat={"DD/MM/YYYY"}
                        value={startDate}
                        disablePast
                        renderInput={(props) => <TextField style={{flexGrow: 1}} {...props} />}
                        onChange={value => setStartDate(value)}
                        label={<span>Start Date</span>}
                    />
                    <DatePicker
                        inputFormat={"DD/MM/YYYY"}
                        value={endDate}
                        disablePast
                        minDate={startDate}
                        renderInput={(props) => <TextField style={{flexGrow: 1}} {...props} />}
                        onChange={value => setEndDate(value)}
                        label={<span>Expiry Date</span>}
                    />
                    <TextField
                        required
                        fullWidth
                        type={"number"}
                        name="totalCoupons"
                        value={totalCoupons}
                        placeholder={"Enter Number of Coupons Required"}
                        onChange={(e: any) => setTotalCoupons(e.target.value)}
                        label={<span>Enter Number of Coupons Required</span>}
                        id="tag"
                    />
                    <TextField
                        fullWidth
                        type={"string"}
                        name="prefix"
                        value={prefixValue}
                        placeholder={"Enter Prefix"}
                        onChange={(e: any) => setPrefixValue(e.target.value)}
                        label={<span>prefix</span>}
                    />
                    <FormControlLabel
                        label="only first purchase"
                        style={{
                            flexGrow: 1,
                            marginLeft: 0,
                            marginRight: 0,
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "4px",
                            padding: "8px",
                        }}
                        control={
                            <Switch
                                onChange={(e: any) => setFirstPurchase(e.target.checked)}
                                name="First Purchase"
                                color="primary"
                                checked={firstPurchase}
                            />
                        }
                        labelPlacement={"start"}
                        componentsProps={{typography: {style: {flexGrow: 1}}}}
                    />
                    <TextField
                        required
                        fullWidth
                        name="email"
                        value={email}
                        placeholder={"Enter your email Id"}
                        onChange={(e: any) => setEmail(e.target.value)}
                        label={<span>
                            Enter your email Id
                        </span>}
                    />
                    <TextField
                        required
                        fullWidth
                        name="codeLength"
                        value={codeLength}
                        placeholder={"Enter length of Coupons Required"}
                        onChange={(e: any) => setCodeLength(e.target.value)}
                        label={<span>
                            Enter length of Coupons Required
                        </span>}
                    />
                    <Button variant="contained" color="primary"
                            onClick={() => confirmCouponCreation()}>Add</Button>
                </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
                <Typography>Coupon deal Validator</Typography>
                <Typography>
                    {couponRulesCheckerText}
                </Typography>
            </Grid>
        </Grid>
        <DialogBox closeBox={() => setConfirmDialogOpen(false)}
                   open={confirmDialogOpen}
                   confirm={() => createCouponApiCall()}
                   heading={'Please check all the fields and confirm'}
                   text={confirmDialogContent} cancelText={'Cancel'}
                   acceptText={'Add Coupon Batch'}/>
    </>)
}

export default BatchCoupon
