import React, {useState, useEffect} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import {useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import Checkbox from '@mui/material/Checkbox';
import PublishIcon from '@mui/icons-material/Publish';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack"
import {convertDateDDMMYYYY} from "../../../../../utils/common";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

const fileDownload = require('js-file-download');

export default function ListEnrollments(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const params = useParams()
    const [multiSelect, setMultiSelect] = useState(false)
    const [selectAll, setSelectAll] = useState(true)
    const [isSelect, setIsSelect] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'))

    async function loadFunc(page) {
        const enrollment = await network.get(`${config.base_path}/api/dashboard/enrollments/${params.class_id}/${page}/get?page_size=${pageSize}`)
        setHasMore(enrollment.data.payload.length !== 0)
        setItems(enrollment.data.payload)
    }

    const onSendCertificate = async (certificate) => {
        const payload = {user_ids: certificate ? [certificate] : isSelect.map(i => i.id)}
        await network.post(`${config.base_path}/api/dashboard/unluclass/${params.class_id}/send_certificate`, payload)
            .then(response => {
                if (response.data.statusCode === 0) {
                    toast("certificate sent")
                    // window.location.reload();
                } else {
                    toast.error("can not send certificate: " + response.data.message)
                }
            })
            .catch(err => {
                console.error(err)
            });
    }

    useEffect(() => {
        console.log('selected', isSelect)
    }, [isSelect])

    function EnrollUser(props) {
        const userData = props.user || props.user_mini
        const onRemove = async (index) => {
            const list = isSelect
            const index1 = list.indexOf(index)
            list.splice(index1, 1)
            setIsSelect(list)
            setIsSelect([...isSelect])
        }

        const onClickHandle = (index) => {
            if (multiSelect)
                setIsSelect([...isSelect, index])
            else return;
        }

        return <Grid item xs={12}>
            {userData && <Card style={{
                margin: "8px",
                padding: "8px",
                cursor: multiSelect ? 'pointer' : '',
                background: (isSelect.includes(userData) && multiSelect) ? 'linear-gradient(135deg, #fcdf8a 0%,#f38381 100%)' : ''
            }}
                               onClick={() => (isSelect.includes(userData) && multiSelect) ? onRemove(userData) : onClickHandle(userData)}>
                <Stack direction={matchMobile ? "column" : "row"} justifyContent={'space-between'}
                       alignItems={'center'}>
                    <Stack spacing={1}>
                        <p>Name: &nbsp;
                            <b>{userData?.name?.first || userData?.user_mini?.full_name} {userData?.name?.last}</b></p>
                        <p>Username: &nbsp;<b>{userData?.username || userData?.user_mini?.username}</b></p>
                        <p>User ID: &nbsp;<b>{userData?.id || userData?.user_mini?.id}</b></p>
                        {userData?.email && <p>Email: &nbsp;<b>{userData?.email}</b></p>}
                        {userData?.phone_number && <p>phone_number: &nbsp;<b>{userData.phone_number}</b></p>}
                        {userData?.temp_phone_number &&
                            <p>phone_number: &nbsp;<b>{userData.temp_phone_number}</b></p>}
                        {props?.createdAt &&
                            <p>Date of enrollment: &nbsp;<b>{convertDateDDMMYYYY(props?.createdAt)}</b></p>}
                        <p>Progress: &nbsp;<b>{props?.progress}</b></p>
                    </Stack>
                    <Button variant="outlined" sx={{color: '#5053EC', borderColor: '#5053EC', maxHeight: '32px'}}
                            onClick={() => onSendCertificate(userData.id)}
                            disabled={isSelect.includes(userData)}>
                        {props.certificate_claimed ? 'Resend' : 'send certificate'}
                    </Button>
                </Stack>
            </Card>}
        </Grid>
    }

    const handleChange = () => {
        setMultiSelect(!multiSelect)
    }

    const onSelect = () => {
        if (isSelect.length > 0)
            setIsSelect([])
        else
            setIsSelect([...isSelect, items.map(item => [item?.user || item?.user_mini])])
    }

    function exportData(copy) {
        const csvString = [
            [
                'user_id',
                'name',
                'username',
                'email',
                'phone_num',
                'Date of enrollment'
            ],
            ...items.map(item => {
                const user = item?.user || item?.user_mini
                return [
                    user.id,
                    user.name?.first + ' ' + user?.name?.last,
                    user.username,
                    user?.temp_email ? user?.temp_email : user?.email,
                    user?.temp_phone_number ? user?.temp_phone_number : user?.phone_number,
                    convertDateDDMMYYYY(item.createdAt)
                ]
            })
        ].map(e => e.map(a => `"${a}"`).join(",")).join("\n")
        if (copy) {
            navigator.clipboard.writeText(csvString)
            toast('CSV data is copied to the clipboard')
            return
        }
        fileDownload(csvString, 'requestData.csv')
    }

    return <BasePage>
        <Checkbox onChange={handleChange}/>Select Multiple
        {multiSelect ? <Grid>
                <Button variant="contained" color="primary" onClick={() => onSendCertificate(null)}>Send
                    certificate</Button>
            </Grid>
            :
            <Stack style={{marginTop: '10px'}} direction={matchMobile ? 'column' : 'row'} spacing={matchMobile ? 1 : 'auto'}>
                <TextField
                    required
                    value={pageSize}
                    label="No of Records"
                    id="pageSize"
                    variant="outlined"
                    onChange={(e: any) => setPageSize(e.target.value)}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton edge="end" color="primary">
                                    <SendIcon onClick={() => {
                                        loadFunc(1)
                                    }}/>
                                </IconButton>
                            </InputAdornment>
                    }}
                />
                <Button onClick={() => exportData(false)} variant="contained" color="primary"
                        style={{marginLeft: '2vw'}}>
                    <PublishIcon style={{height: matchMobile ? 'auto' : '1.25vw'}}/> Export
                </Button>
                <Button variant="contained" color="primary"
                        onClick={() => exportData(true)} style={{marginLeft: '2vw'}}>
                    <FileCopyIcon style={{height: matchMobile ? 'auto' : '1.25vw'}}/> Copy CSV
                </Button>
            </Stack>
        }
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/enrollments/list/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <EnrollUser key={index} {...a} />)}/>
    </BasePage>
}
