import {useEffect, useRef} from "react";

export default function FolderSelector(props) {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (ref.current !== null) {
            ref.current.setAttribute("directory", "");
            ref.current.setAttribute("webkitdirectory", "");
            ref.current.setAttribute("multiple", "");
        }
    }, [ref]);

    return <input type="file" ref={ref} {...props}/>;
}
