import { Button } from "@mui/material"
import { useState } from "react"
import EditFormDrawer from "components/custom/EditFormDrawer"
import network from "network"
import config from "config"
import {toast} from "react-toastify"
export default function CreateCampaign(props){
    const [campaignObject , setCampaignObject] = useState(null)
    async function loadFunc() {
        const campaign = await network.get(`${config.base_path}/api/dashboard/campaign/sample`)
        setCampaignObject(campaign.data.payload)
    }
    const handleSubmit = async (mainData, diff, diffPath) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/campaign/create`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success('Campaign Created', {autoClose : 2000})
        }
        else
            toast.error(res.data.message , {autoClose:2000})
    }
    return <>
    <Button variant={"outlined"} onClick={() => loadFunc()}>+Create New Campaign</Button>
    {!!campaignObject && <EditFormDrawer onClose={() => setCampaignObject(null)} open={!!campaignObject} schema={campaignObject} onSave={handleSubmit} />}
    </>
}
