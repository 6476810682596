import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginIn from './pages/loginIn';
import AddUser from "./pages/users/add-creator";
import User from "./pages/users/user-profile";
import Home from "./pages/home";
import Coupon from './pages/marketing/coupons'
import BmsClassRunner from "./pages/thirdparty/bms-class-runner";
import Insights from './pages/insights'
import CommunityInterests from './pages/community/interests';
import CommunityMember from './pages/community/member';
import CommunityReportedPosts from './pages/community/reported_posts';
import CommunitySkills from './pages/community/skills';
import CommunitySpace from './pages/community/space';
import {CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import UnluclassOrders from "./pages/unluclass/orders"
import Settings from 'pages/settings/_page';
import ShoutoutRequests from "./pages/shoutouts/requests"
import ListUnluclass from "./pages/unluclass";
import ListCampaigns from "./pages/marketing/campaign/list/_page";
import {UserProvider} from "./components/UserProvider";
import ListClassEpisodes from 'pages/unluclass/_class_id/episodes/_page';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import RequestedQuotes from 'pages/sync/requestquotes';
import ManageArtist from 'pages/sync/artists/_page';
import ManageGroups from 'pages/sync/groups/_page';
import ManageSyncTags from 'pages/sync/tags/_page';
import ManageAssets from 'pages/sync/artists/_user_id/assets/_page';
import ListSyncTags from 'pages/sync/artists/_user_id/assets/_asset_id/_page';
import UnluLive from 'pages/community/live/list/_page';
import UnluASL from 'pages/community/live/_meeting_id/acl/_page';
import Works from "./pages/works/_page";
import GroupMember from "./pages/community/_group_id/members/_page";
import WorksOppApplications from "./pages/works/_work_opp/applications/_page";
import Events from "./pages/events/_page";
import EventRegistrations from "./pages/events/[event_id]/_page";
import GroupAsset from "./pages/sync/groups/_group_id/_page";
import ScriptEditor from "./pages/experiments/script-editor";
import AudioEditor from "./pages/experiments/audio-editor";
import UnluTags from "./pages/tags";
import TagList from "./pages/tags/_tag_type/_page";
import DataValidator from "./pages/sync/data-validator";
import {AudioProvider} from "./components/experiment/audio-editor/audio-provider";


import ListFaqs from 'pages/fellowship/_class_id/faq/_page';
import ListDemoClass from 'pages/fellowship/_class_id/demo/list/_page';
import ListDemoUsers from 'pages/fellowship/_class_id/demo/list/_demo_id/booking/_page';
import ListEnrollments from 'pages/fellowship/_class_id/enrollments/list/_page'
import ListAssignmentModule from 'pages/fellowship/_class_id/modules/_module_id/assignments/list/_page';
import ListAssignmentUnluClass from 'pages/fellowship/_class_id/assignments/list/_page';
import ListAssignmentQuestions from 'pages/fellowship/_class_id/assignments/_assignment_id/questions/_page';
import ListAssignmentResponses from 'pages/fellowship/_class_id/assignments/_assignment_id/responses/_page';
import ListUserAssignmentResponses
    from 'pages/fellowship/_class_id/assignments/_assignment_id/users/_user_id/_response_id/_page';
import FellowShipApplications from "./pages/fellowship/applications"
import FellowshipOrders from "./pages/fellowship/orders"
import CreateFellowShip from "./pages/fellowship/create";
import ListFellowship from "./pages/fellowship/list/_page";
import ListModules from "./pages/fellowship/_class_id/modules/_page";
import ListEpisodes from "./pages/fellowship/_class_id/modules/_module_id/episodes/_page";
import ListWorkBook from "./pages/fellowship/_class_id/modules/_module_id/workbook/list/_page";
import ListHouse from "pages/house/list/_page";
import {EditorProvider} from 'components/experiment/script-editor/EditorProvider';
import ListAssignmentQueResponses from "./pages/fellowship/_class_id/assignments/_assignment_id/users/_user_id/_page";
import FileUpload from "./pages/file-upload";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Leads from 'pages/marketing/leads/_slug';
import AttachTags from "./pages/sync/artists/_user_id/assets/_asset_id/attach-tags";
import UserSubmission from "./pages/submission/_resource_id/_user_id/_page";
import WorkRooms from 'pages/rooms/_page';
import AWSMediaConvert from "./pages/thirdparty/aws-media-convert";

toast.configure()
let theme = responsiveFontSizes(createTheme());

function App(props) {

    return <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment.utc}>
        <UserProvider>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <BrowserRouter>
                        <Routes>
                            <Route path={"/"} element={<Home/>}/>
                            <Route path={"/login"} element={<LoginIn/>}/>
                            <Route path={"submission"}>
                                <Route path={":resource_id"}>
                                    <Route path={":user_id"}>
                                        <Route path={":page"} element={<UserSubmission/>}/>
                                    </Route>
                                </Route>
                            </Route>
                            <Route path={"fellowship"}>
                                <Route path={"list"}>
                                    <Route path={":page"} element={<ListFellowship classType={510}/>}/>
                                </Route>
                                <Route path={":class_id"}>
                                    <Route path={"modules"}>
                                        <Route path={":page"} element={<ListModules/>}/>
                                        <Route path={":module_id"}>
                                            <Route path={"episodes"}>
                                                <Route path={":page"} element={<ListEpisodes/>}/>
                                            </Route>
                                            <Route path={"assignments"}>
                                                <Route path={"list"}>
                                                    <Route path={":page"} element={<ListAssignmentModule/>}/>
                                                </Route>
                                            </Route>
                                            <Route path={"workbook"}>
                                                <Route path={"list"}>
                                                    <Route path={":page"} element={<ListWorkBook/>}/>
                                                </Route>
                                            </Route>
                                        </Route>
                                    </Route>
                                    <Route path={"assignments"}>
                                        <Route path={"list"}>
                                            <Route path={":page"} element={<ListAssignmentUnluClass/>}/>
                                        </Route>
                                        <Route path={":assignment_id"}>
                                            <Route path={"users"}>
                                                <Route path={":user_id"}>
                                                    <Route path={":page"} element={<ListAssignmentQueResponses/>}/>
                                                    <Route path={":response_id"}>
                                                        <Route path={":page"}
                                                               element={<ListUserAssignmentResponses/>}/>
                                                    </Route>
                                                </Route>
                                            </Route>
                                            <Route path={"questions"}>
                                                <Route path={":page"} element={<ListAssignmentQuestions/>}/>
                                            </Route>
                                            <Route path={"responses"}>
                                                <Route path={":page"} element={<ListAssignmentResponses/>}/>
                                            </Route>
                                        </Route>
                                    </Route>
                                    <Route path={"faq"}>
                                        <Route path={":page"} element={<ListFaqs/>}/>
                                    </Route>
                                    <Route path={"demo"}>
                                        <Route path={"list"}>
                                            <Route path={":page"} element={<ListDemoClass/>}/>
                                            <Route path={":demo_id"}>
                                                <Route path={"booking"}>
                                                    <Route path={":page"} element={<ListDemoUsers/>}/>
                                                </Route>
                                            </Route>
                                        </Route>
                                    </Route>
                                    <Route path={"enrollments"}>
                                        <Route path={"list"}>
                                            <Route path={":page"} element={<ListEnrollments/>}/>
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path={"orders"} element={<FellowshipOrders/>}/>
                                <Route path={"applications"} element={<FellowShipApplications/>}/>
                                <Route path={"create"} element={<CreateFellowShip/>}/>
                            </Route>
                            <Route path={"house"}>
                                <Route path={"list"}>
                                    <Route path={":page"} element={<ListHouse/>}/>
                                </Route>
                            </Route>
                            <Route path={"unluclass"} element={<ListUnluclass/>}/>
                            <Route path={"unluclass"}>
                                <Route path={":page"} element={<ListUnluclass/>}/>
                                <Route path={":class_id"}>
                                    <Route path={"episodes"}>
                                        <Route path={":page"} element={<ListClassEpisodes/>}/>
                                    </Route>
                                </Route>
                                <Route path={"orders"} element={<UnluclassOrders/>}/>
                            </Route>
                            <Route path={"script-editor-experiment"}
                                   element={<EditorProvider><ScriptEditor/></EditorProvider>}/>
                            <Route path={"audio-editor-experiment"}
                                   element={<AudioProvider><AudioEditor/></AudioProvider>}/>
                            <Route path={"settings"}>
                                <Route path={":page"} element={<Settings/>}/>
                            </Route>
                            <Route path={"rooms"}>
                                <Route path={":page"} element={<WorkRooms/>}/>
                            </Route>
                            <Route path={"works"}>
                                <Route path={":page"} element={<Works/>}/>
                                <Route path={":work_opp"}>
                                    <Route path={"applications"}>
                                        <Route path={":page"} element={<WorksOppApplications/>}/>
                                    </Route>
                                </Route>
                            </Route>
                            <Route path={"events"}>
                                <Route path={":page"} element={<Events/>}/>
                                <Route path={":event_id"}>
                                    <Route path={":page"} element={<EventRegistrations/>}/>
                                </Route>
                            </Route>
                            <Route path={"sync"}>
                                <Route path={"artists"}>
                                    <Route path={":page"} element={<ManageArtist/>}/>
                                    <Route path={":user_id"}>
                                        <Route path={"assets"}>
                                            <Route path={":page"} element={<ManageAssets/>}/>
                                            <Route path={":asset_id"}>
                                                <Route path={":page"} element={<ListSyncTags/>}/>
                                                <Route path={"attach-tags"} element={<AttachTags/>}/>
                                            </Route>
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path={"tags"}>
                                    <Route path={":page"} element={<ManageSyncTags/>}/>
                                </Route>
                                <Route path={"groups"}>
                                    <Route path={":page"} element={<ManageGroups/>}/>
                                    <Route path={":group_id"}>
                                        <Route path={':page'} element={<GroupAsset/>}/>
                                    </Route>
                                </Route>
                                <Route path={"requestquotes"} element={<RequestedQuotes/>}/>
                                <Route path={"data-validator"} element={<DataValidator/>}/>
                            </Route>
                            <Route path={"shoutout"}>
                                <Route path={"requests"}>
                                    <Route path={":page"} element={<ShoutoutRequests/>}/>
                                </Route>
                            </Route>
                            <Route path={"community"}>
                                <Route path="member" element={<CommunityMember/>}/>
                                <Route path="interests">
                                    <Route path={":page"} element={<CommunityInterests/>}/>
                                </Route>
                                <Route path=":group_id">
                                    <Route path={"members"}>
                                        <Route path={":page"} element={<GroupMember/>}/>
                                    </Route>
                                </Route>
                                <Route path="skills">
                                    <Route path={":page"} element={<CommunitySkills/>}/>
                                </Route>
                                <Route path="space" element={<CommunitySpace/>}/>
                                <Route path="posts">
                                    <Route path={"reported"} element={<CommunityReportedPosts/>}/>
                                </Route>
                                <Route path={"live"}>
                                    <Route path={"list"}>
                                        <Route path={":page"} element={<UnluLive/>}/>
                                    </Route>
                                    <Route path={":meeting_id"}>
                                        <Route path={"acl"}>
                                            <Route path={":page"} element={<UnluASL/>}/>
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                            <Route path={"user"}>
                                <Route path="add" element={<AddUser/>}/>
                                <Route path={":id"} element={<User/>}/>
                            </Route>
                            <Route path={"marketing"}>
                                <Route path={"coupons"} element={<Coupon/>}/>
                                <Route path={"campaign"}>
                                    <Route path={"list"}>
                                        <Route path={":page"} element={<ListCampaigns/>}/>
                                    </Route>
                                </Route>
                                <Route path={"leads"}>
                                    <Route path={":slug"} element={<Leads/>}/>
                                </Route>
                            </Route>
                            <Route path={"thirdparty"}>
                                <Route path={"bookmyshow"} element={<BmsClassRunner/>}/>
                                <Route path={"aws-media-convert"} element={<AWSMediaConvert/>}/>
                            </Route>
                            <Route path={"insights"} element={<Insights/>}/>
                            <Route path={"file_upload"} element={<FileUpload/>}/>
                            <Route path={"tags"} element={<UnluTags/>}/>
                            <Route path={"tags"}>
                                <Route path={":tag_type"}>
                                    <Route path={':page'} element={<TagList/>}/>
                                </Route>
                                {/*<Route path={"update"} element={<UpdateTag/>}/>*/}
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </StyledEngineProvider>
        </UserProvider>
    </LocalizationProvider>

}

export default App;
