import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import network from "../network";
import config from "../config";
import qs from "querystring";
import {useLocation, Navigate,} from "react-router-dom";

export type IUserContext = {
    isLoggedIn: boolean, allowed: any, profile: any, scope: any, login: any, logout: any
}
const UserContext = createContext<IUserContext>({
    isLoggedIn: false,
    allowed: null,
    profile: null,
    scope: null,
    logout: null,
    login: null
})

export function UserProvider(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [scope, setScope] = useState([]);
    const [profile, setProfile] = useState({});
    const [allowed, setAllowed] = useState({
        fellowship: {edit: false, view: false},
        house: {edit: false, view: false},
        unluclass: {edit: false, view: false},
        unluskill: {edit: false, view: false},
        shoutout: {edit: false, view: false},
        thirdparty: {bookmyshow: false, awsmediaconvert: false},
        users: {edit: false, view: false},
        community: {edit: false, view: false},
        coupons: {edit: false, view: false},
        sync: {edit: false, view: false},
        campaigns: {edit: false, view: false},
        tags: {edit: false, view: false},
        insights: {view: false},
        fileUpload: {edit: false, view: false},
        settings: {edit: false, view: false},
        works: {edit: false, view: false},
        events: {edit: false, view: false}
    })
    const [mounted, setMounted] = useState(false)
    const [forceRefreshFlag, setForceRefreshFlag] = useState(false)

    function navigate(path) {
        window.location.assign(path)
    }

    useEffect(() => {
        const userData = localStorage.getItem("user_data")
        setIsLoggedIn(!!userData)
        if (!userData) {
            setScope(null)
            setProfile(null)
            setMounted(true)
            return
        } else {
            const scope = localStorage.getItem("scope")?.split(",") || ["user"]
            if (JSON.stringify(scope) === JSON.stringify(["user"])) {
                localStorage.clear();
                alert("Sorry! You dont have permission to access unlu dashboard");
                navigate("/");
                setMounted(true)
                return
            }
            const profile = JSON.parse(localStorage.getItem("profile"))
            setScope(scope)
            setProfile(profile)
        }
        setMounted(true)
    }, [forceRefreshFlag])

    useEffect(() => {
        if (!scope) {
            setAllowed(null)
            return
        }
        const admin = scope.includes("admin");
        const manager = scope.includes("manager");
        const unlu_events = scope.includes("unlu_events");
        const coupon = scope.includes("coupon");
        const bms = scope.includes("bms");
        const celeb = scope.includes("celeb");
        const user_secure = scope.includes("user_secure");
        const community = scope.includes("community");
        const campaign = scope.includes("campaign");
        const settings = scope.includes("settings");
        const work_opportunity = scope.includes("work_opportunity");


        setAllowed({
            house: {edit: admin || manager, view: admin || manager},//classes
            fellowship: {edit: admin || manager, view: admin || manager},//classes
            unluclass: {edit: admin || manager, view: admin || manager},//classes
            unluskill: {edit: admin || manager, view: admin || manager},//classes

            tags: {edit: admin || manager, view: admin || manager},//community/classes/sync
            sync: {edit: admin || manager, view: admin || manager},
            insights: {view: admin || manager},
            fileUpload: {edit: admin || manager, view: admin || manager},
            events: {edit: admin || unlu_events, view: admin || unlu_events},
            users: {edit: admin || manager, view: admin || manager},

            shoutout: {edit: admin || celeb, view: admin || celeb},

            thirdparty: {bookmyshow: admin || bms, awsmediaconvert: admin},
            community: {edit: admin || community, view: admin || community},
            coupons: {edit: admin || coupon, view: admin || coupon},
            campaigns: {edit: admin || campaign || manager, view: admin || campaign || manager},
            settings: {edit: admin || settings, view: admin || settings},
            works: {edit: admin || work_opportunity, view: admin || work_opportunity}
        })
    }, [scope]);

    function logout() {
        localStorage.clear();
        setForceRefreshFlag(!forceRefreshFlag)
    }

    async function login(email, password) {
        const res = await network.post(`${config.base_path}/api/token`, qs.stringify({
            username: email,
            password: password,
            client_id: "unlo-dashboard",
            client_secret: "OurClearPassword",
            grant_type: "password",
            scope: 'admin,manager,coupon,bms,celeb,user,user_secure,community,settings,work_opportunity,unlu_events'
        }), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
            .catch(err => {
                console.log(err)
                alert("Invalid email or password.")
                return null;
            })

        if (!res?.data?.access_token) {
            alert(res.data.message || "Invalid email or password.")
        } else {
            const scope = res.data.scope.split(',')
            if (scope.length === 0) {
                localStorage.setItem('scope', 'user')
            }
            if (scope.length === 1 && scope[0] === 'user') {
                localStorage.clear()
                alert('Sorry! You dont have permission to access unlu dashboard')
                return navigate('/')
            }
            localStorage.setItem('scope', scope)
            localStorage.setItem('user_data', res.data.access_token)
            const profile = await network.get(`${config.base_path}/api/user/profile/me`)
            localStorage.setItem("profile", JSON.stringify(profile.data.payload))
        }
        setForceRefreshFlag(!forceRefreshFlag)
    }

    const value = useMemo<IUserContext>(() => {
        return {isLoggedIn, allowed, profile, scope, login, logout}
    }, [isLoggedIn, allowed, profile, scope, login, logout])

    return <UserContext.Provider value={value}>
        {mounted && props.children}
    </UserContext.Provider>
}

export function useUser() {
    return useContext(UserContext)
}
