import { Button } from "@mui/material"
import { useState } from "react"
import EditFormDrawer from "components/custom/EditFormDrawer"
import network from "network"
import config from "config"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
export default function CreateUnluLive() {
    const [meetingObject, setMeetingObject] = useState(null)
    const params = useParams()
    async function loadFunc() {
        const meeting = { title: '', type: '', slug: '' }
        setMeetingObject(meeting)
    }
    const handleSubmit = async (mainData, diff, diffPath) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/meetings/create`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success('meeting Created', { autoClose: 2000 })
        }
        else
            toast.error(res.data.message, { autoClose: 2000 })
    }
    return <>
        <Button variant={"outlined"} onClick={() => loadFunc()}>+Add New Meeting</Button>
        {!!meetingObject && <EditFormDrawer id={'unlulive'} title='Create New Meeting' onClose={() => setMeetingObject(null)} open={!!meetingObject} schema={meetingObject} onSave={handleSubmit} />}
    </>
}