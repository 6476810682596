import React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import TableRowComponent from '../../components/custom/TableRowComponent';
import BasePage from "../../components/BasePage";


const useStyles = makeStyles({
    content: {
        marginTop: "80px",
        marginLeft: "240px",
    },
    table: {
        minWidth: 550,
        fontFamily: "Source Sans Pro",
    },

    pageHeading: {
        color: "#191237",
        fontFamily: "Source Sans Pro",
        fontSize: "1.6rem",
        fontWeight: "700"

    }
    ,
    tableHeading: {
        fontWeight: "700",
        fontSize: "1.1rem"
    },
});

const tableData = {
    head: [{
        id: 'name',
        label: 'Name'
    },
        {
            id: 'email',
            label: 'Email'
        },
        {
            id: 'role',
            label: 'Role'
        },
        {
            id: 'actions',
            label: 'Actions'
        }],
    body: [
        {
            name: 'Sanya',
            email: 'sanya@unlu.io',
            role: 'QA',
            actions: [
                'edit',
                'delete'
            ]
        },
        {
            name: 'Satyender',
            email: 'satyendra.sg@unlu.io',
            role: 'Web developer',
            actions: [
                'edit',
                'delete'
            ]
        }
    ]
};

const CommunityMember = (classes) => {

    classes = useStyles();

    const editClickHandler = (id) => {
    };

    const deleteClickHandler = (id) => {

    };

    return (
        <BasePage>
            <Typography className={classes.pageHeading}>Member Profiles</Typography>

            <TextField label="Search member" margin="normal" variant="outlined"/>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                tableData.head.map((tr) => <TableCell key={tr.id}
                                                                      className={classes.tableHeading}>{tr.label}</TableCell>)
                            }
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableData.body.map((row, index) => <TableRowComponent onEditClick={editClickHandler}
                                                                               onDeleteClick={deleteClickHandler}
                                                                               key={index} rowId={index} rowbody={row}
                                                                               rowhead={tableData.head}/>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </BasePage>
    )
}

export default CommunityMember
