import React, {useState, useEffect} from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import {Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import {Grid} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableRowComponent from './custom/TableRowComponent';
import network from "../network";
import config from '../config';
import RightDrawer from './custom/RightDrawer';
import CreateGroupForm from '../components/community/CreateGroupForm'
import AddMemberGroupForm from '../components/community/AddMemberGroupForm'
import EditFormDrawer from './custom/EditFormDrawer';
import {tellSlack} from "network/apis";
import {toast} from 'react-toastify';
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles({
    btn: {
        marginLeft: "20px"
    },
});

export default function SpaceExpandableComponent(props) {


    const classes = useStyles();

    const [groupData, setGroupData] = useState([])
    const [dataAvailable, setDataAvailable] = useState(false)
    const [editSpaceDrawer, setEditSpaceDrawer] = useState(false);
    const [formDataId, setFormDataId] = useState()
    const [formData, setFormData] = useState([])
    const formType = "edit"
    const [editGroupDrawer, setEditGroupDrawer] = useState(false);
    const [addMemberDrawer, setAddMemberDrawer] = useState(false);
    const navigate = useNavigate()

    const populateGroupData = async () => {

        network.get(`${config.base_path}/api/community/dashboard/spaces/${props.spaceData.id}/groups`)
            .then((response) => {
                    if (response.data.payload.length === 0) {
                        setDataAvailable(false)
                    } else {
                        setGroupData(response.data.payload)
                        setDataAvailable(true)
                    }
                }
            )
    }


    useEffect(() => {
        populateGroupData()
    }, [])


    const tableData = {
        head: [{
            id: 'title',
            label: 'Title'
        },
            {
                id: 'members_count',
                label: 'Email'
            },
            {
                id: 'actions',
                label: ''
            },
        ],
        body: groupData.map(a => {
            return {
                groupId: a.id,
                title: a.title,
                members_count: a.members_count + ' members',
                actions: ['edit', 'addMember', 'view']
            }
        })
    };


    const editClickHandler = (id) => {

        let grpData = groupData.find(o => o.id === id);
        setEditGroupDrawer(true);
        setFormDataId(id);
        setFormData(grpData);
    };


    const addMemberClickHandler = (id) => {
        let grpData = groupData.find(o => o.id === id);
        setAddMemberDrawer(true);
        setFormDataId(id);
        setFormData(grpData);
    }
    const onViewClickHandler = (id) => {
        navigate(`/community/${id}/members/1`)
    }

    const handleSubmit = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editSpaceDrawer,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/community/dashboard/spaces/${mainData.id}`, diff)
        setEditSpaceDrawer(false)
        toast.success("Edited Successfully", {autoClose: 1000})
    }


    return (

        <>
            <Grid container direction='row' justifyContent="space-between" alignItems="flex-start"
                  style={{margin: "15px", maxWidth: "95%", alignItems: 'center'}}>
                <Grid item xs={10}>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{props.spaceData.ordering} {props.spaceData.title}</Typography>
                        </AccordionSummary>
                        {!!dataAvailable ? <AccordionDetails>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            {tableData.body.map((row, index) => <TableRowComponent
                                                onEditClick={() => editClickHandler(row.groupId)}
                                                onAddMemberClick={() => addMemberClickHandler(row.groupId)} key={index}
                                                rowId={index}
                                                rowbody={row} rowhead={tableData.head}
                                                onViewClick={() => onViewClickHandler(row.groupId)}/>)}
                                            <RightDrawer drawerState={editGroupDrawer} setDrawerState={setEditGroupDrawer}
                                                         formComponent={CreateGroupForm} formId={formDataId}
                                                         formType={formType}
                                                         formData={formData}/>
                                            <RightDrawer drawerState={addMemberDrawer} setDrawerState={setAddMemberDrawer}
                                                         formComponent={AddMemberGroupForm} formId={formDataId}
                                                         formType={formType}
                                                         formData={formData}/>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                            :
                            <Typography style={{margin: "15px"}}> Create the group for space
                                "{props.spaceData.title}" </Typography>
                        }
                    </Accordion>
                </Grid>
                <Grid item xs={2}>
                    &nbsp;<Button variant={"outlined"} color={"success"}
                                  onClick={() => setEditSpaceDrawer(props.spaceData)}>Edit</Button>

                    {!!editSpaceDrawer &&
                        <EditFormDrawer onClose={() => setEditSpaceDrawer(null)} open={!!editSpaceDrawer}
                                        schema={editSpaceDrawer}
                                        onSave={handleSubmit}/>}
                </Grid>
            </Grid>

        </>
    )
}
