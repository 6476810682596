import _ from "lodash";

export default function tsv2json(tsv, header_validation) {
    const final_header_validation = header_validation || ((header) => header)
    const lines: string[] = tsv.split("\r\n")
    const headerLines = lines.slice(0, 1)
    const headerLine = headerLines[0]
    const ogHeaderLine = headerLine.split("\t")
    const headers = final_header_validation(ogHeaderLine)
    const dataLines = lines.splice(1)

    return dataLines.map((dataLine, index) => {
        const data = dataLine.split("\t");
        return _.reduce(headers, (collector, current_object: string, index, list) => {
            if (current_object?.trim()?.length > 0)
                collector[current_object] = data[ogHeaderLine.indexOf(current_object)]
            return collector
        }, {index: index + 1} as any) as any
    })
}

