import Tagging from "../../components/experiment/script-editor/Tagging2";
import React, {useState} from "react";
import {CastList, CastType, Script, ScriptPage} from "../../components/experiment/script-editor/DataTypes";
import {EditorProvider} from "../../components/experiment/script-editor/EditorProvider";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CharacterList from "../../components/experiment/script-editor/CharacterList";


export function makeid(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export default function ScriptEditor() {
    const [data, setData] = useState<Script>([])
    const [castList, setCastList] = useState<CastList>([])
    const [value, setValue] = React.useState('1');


    function onChooseFile(event, onLoadFileHandler) {
        if (typeof (window as any).FileReader !== 'function')
            throw new Error("The file API isn't supported on this browser.");
        let input = event.target;
        if (!input)
            throw new Error("The browser does not properly implement the event object");
        if (!input.files)
            throw new Error("This browser does not support the `files` property of the file input.");
        if (!input.files[0])
            return undefined;
        let file = input.files[0];
        let fr = new FileReader();
        fr.onload = onLoadFileHandler;
        fr.readAsText(file);
    }


    const parseCharacters = (fdxString) => {
        const parser = new DOMParser();
        const scriptDoc = parser.parseFromString(fdxString, "text/xml");
        const cast = scriptDoc.getElementsByTagName("Cast")[0];
        const castList: CastList = []
        for (let i = 0; i < cast.childElementCount; i++) {
            const child = cast.children[i]
            if (child.tagName === "Member") {
                const actor = child.getAttribute('Actor');
                const character = child.getAttribute('Character');
                castList.push({type: child.tagName as CastType, actor: actor, character: character})
            } else if (child.tagName === "Narrator") {
                const actor = child.getAttribute('Actor');
                castList.push({type: child.tagName as CastType, actor: actor})
            }
        }
        return castList
    }

    const parseFinalDraft = (fdxString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(fdxString, "text/xml");
        const script: Script = []
        const content = doc.getElementsByTagName("Content")[0];
        let currentPage = 1
        let currentScript: ScriptPage = []

        for (let i = 0; i < content.childElementCount; i++) {
            const child = content.children[i]
            if (child.tagName === "Paragraph") {
                const data = child.getElementsByTagName("Text")[0].innerHTML;
                const type = typeConverter(child.getAttribute("Type"));
                let id = `${makeid(6)}`
                if (type === 'scene') {
                    if (currentPage !== Number(child.getElementsByTagName("SceneProperties")[0].getAttribute("Page"))) {
                        currentPage = Number(child.getElementsByTagName("SceneProperties")[0].getAttribute("Page"))
                        script.push(currentScript)
                        currentScript = []
                        currentScript.push({type, data, id})
                    }
                } else
                    currentScript.push({type, data, id})
            }
        }

        return script;
    }

    function typeConverter(type) {
        switch (type) {
            case "Scene Heading":
                return "scene";
            case "Shot":
                return "slugline";
            case "Cast List":
                return "casting";
            default:
                return type.toLowerCase()
        }
    }

    function onData(fileData: string) {
        setData(parseFinalDraft(fileData));
        setCastList(parseCharacters(fileData))
    }


    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    return <>
        <input type='file' onChange={(event) => onChooseFile(event, (data) => onData(data.target.result))}/>
        <EditorProvider data={data} castList={castList}>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList value={value} onChange={handleTabChange}>
                            <Tab label="Script" value={'1'}/>
                            <Tab label="Character List" value={'2'}/>
                        </TabList>
                    </Box>
                    <TabPanel value={'1'}>
                        <Tagging/>
                    </TabPanel>
                    <TabPanel value={'2'}>
                        <CharacterList/>
                    </TabPanel>
                </TabContext>
            </Box>
        </EditorProvider>
    </>
}
