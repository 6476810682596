interface IConfig {
    base_path: string,
    host: string,
    production: boolean
}

//**********For changing environment while in development only
const _PRODUCTION = false
//**********

//**********Ignore this or talk to a senior dev before changing anything here
export const PRODUCTION = isProduction()
//**********

const prod: IConfig = {
    base_path: "https://api.unlu.io",
    host: "api.unlu.io",
    production: true,
}
const testing: IConfig = {
    base_path: "https://dev.unlu.io",
    host: "dev.unlu.io",
    production: false,
}
const local: IConfig = {
    base_path: "https://local.unlu.io:7071",
    host: "local.unlu.io",
    production: false,
}

function isProduction() {
    const backend = process.env.backend
    switch (backend) {
        case "production":
            return true;
        case "development":
            return false;
        default:
            return _PRODUCTION
    }
}


export default PRODUCTION ? prod : testing
