import React, {useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import {useParams, useNavigate} from "react-router-dom";
import EditFormDrawer from "components/custom/EditFormDrawer";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import CreateAssignmentsQuestion from "./create";
import {tellSlack} from "network/apis";


export default function ListAssignmentQuestions() {
    const [item, setItem] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [editObject, setEditObject] = useState(null)
    const params = useParams()
    const navigate = useNavigate()

    async function loadFunc() {
        const question = await network.get(`${config.base_path}/api/dashboard/assignment_questions/list/1?assignment_id=${params.assignment_id}`)
        setHasMore(question.data.payload.length !== 0)
        setItem(question.data.payload)
    }

    const onSave = async (mainData, diff) => {
        await tellSlack(JSON.stringify({
            original: editObject,
            update: diff
        }, null, 1))
        await network.post(`${config.base_path}/api/dashboard/assignment_questions/update?_id=${mainData._id}`, diff)
        setEditObject(null)
        loadFunc()
        toast.success("Edited Successfully", {autoClose: 1000})
    }


    function Assignment(item) {

        return <Grid item xs={12}>
            <Card style={{margin: "8px", padding: "8px"}}>
                <Grid container direction={"row"}>
                    <Grid item style={{flexGrow: 1}}>
                        <Grid item xs={12}>Title: &nbsp;<b>{item.title}</b></Grid>
                        <Grid item xs={12}>desc: &nbsp;{item.desc}</Grid>
                    </Grid>
                    <Grid item>
                        <Button variant={"outlined"} color={"success"} onClick={() => setEditObject(item)}>Edit</Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    }

    return <BasePage>
        <CreateAssignmentsQuestion/>
        <CDataTable
            pageUrl={`/fellowship/${params.class_id}/assignments/${params.assignment_id}/questions/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={item.map((a, index) => <Assignment key={index} {...a} />)}/>
        {!!editObject && <EditFormDrawer id='assignments'
                                         title={"Edit Assignment"}
                                         onSave={onSave}
                                         onClose={() => setEditObject(null)}
                                         open={!!editObject}
                                         schema={editObject}/>}
    </BasePage>
}
