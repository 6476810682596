import Stack from "@mui/material/Stack";
import PageBox from "./PageBox";
import {WritingMode} from "./DataTypes";
import Button from "@mui/material/Button";
import {styled} from "@mui/material";
import Box from "@mui/material/Box";
import {useEditor} from "./EditorProvider";

const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time))

const CustomBox2 = styled(Box)(({theme}) => ({
    "@media only print": {
        display: "none"
    },
    background: 'white',
    position: 'sticky',
    top: '0'
}))

export default function Tagging() {

    const {currentWritingMode, setChangeWritingMode, setSaveData, data} = useEditor()

    return <>
        <Stack spacing={1} justifyContent={"center"} alignItems={"center"} sx={{position: 'relative'}}>
            <CustomBox2>
                {Object.values(WritingMode).map((item: string, index) => {
                    return <Button disabled={currentWritingMode === item} key={index}
                                   onMouseDown={(e) => (e.preventDefault(),
                                       setChangeWritingMode(item))
                                   }
                    >{item}</Button>
                })}
                <Button onMouseDown={(e) => (e.preventDefault(), setSaveData(true))}>Save</Button>
            </CustomBox2>
            {data.map((contentData, index) => <PageBox
                data={contentData}
                key={index}/>)}
        </Stack>
    </>
}


