import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { ButtonGroup } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import SortIcon from '@mui/icons-material/Sort';
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
export default function CDataTable(props) {
    const [page, setPage] = useState(props.page || 1)
    const [isLoading, setIsLoading] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null)
    const [sortData, setSortData] = useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        props.pageChanged(page)
            .then(a => setIsLoading(false))
            .catch(e => { console.log("Loading has trouble", e.message) })
    }, [page])

    function changePage(page) {
        setPage(page)
        navigate(props.pageUrl + page);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    function onSort(param) {
        const sortedData = [...props.items].sort((a, b) => {
            switch (param) {
                case "Asc":
                    return a.props.title > b.props.title ? 1 : -1
                case "Des":
                    return a.props.title > b.props.title ? -1 : 1
                case "Asc Order":
                    return a.props.ordering > b.props.ordering ? 1 : -1
                case "Des Order":
                    return a.props.ordering > b.props.ordering ? -1 : 1
                case "Default":
                    return a.props.ordering > b.props.ordering ? 1 : -1
            }

        })
        setSortData(sortedData)
        handleClose()
    }
    if (isLoading)
        return <Loader />

    return <Grid container spacing={0} style={props?.style}>
        {props.sortBy === 'hide' ? '' :
            <>
                <Button style={{ marginLeft: "auto" }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}><SortIcon />
                    Sort By
                </Button>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={() => onSort("Asc Order")}>Ascending Order</MenuItem>
                    <MenuItem onClick={() => onSort("Des Order")}>Descending Order</MenuItem>
                    <MenuItem onClick={() => onSort("Asc")}>Ascending Name</MenuItem>
                    <MenuItem onClick={() => onSort("Des")}>Descending Name</MenuItem>
                    <MenuItem onClick={() => onSort("Default")}>Default </MenuItem>
                </Menu>
            </>
        }
        <Grid item container justifyContent={"center"}>
            {(!sortData) ? (props.children || props.items || null) : sortData}
        </Grid>
        {props.pagination === 'hide' ? '' :
            <>
                <Grid item container style={{ width: "100%" }} justifyContent={"flex-end"}>
                    <ButtonGroup>
                        <Button variant={"outlined"} disabled={(page - 1) < 1}
                            onClick={() => changePage(page - 1)}>{page - 1}</Button>
                        <Button disabled>{page}</Button>
                        <Button variant={"outlined"} disabled={!props.hasMore}
                            onClick={() => changePage(page + 1)}>{page + 1}</Button>
                    </ButtonGroup>
                </Grid>
            </>}
    </Grid>
}
