// import config from "../../config.json";
// import network from "../network"

const userData = (state) => {
    // const user_id = localStorage.getItem("user_data");
    // network.post(config.base_path, {user_id: user_id})
    //     .then(res => {
    //         state.setState({
    //             user_id: res.data.data[0]["user_id"],
    //             name: res.data.data[0]["name"],
    //             email: res.data.data[0]["email"],
    //             admin: res.data.data[0]["Admin"],
    //             status: true
    //         });
    //     })
    //     .catch(err => console.log(err));
}

export default userData