import React, { useState } from "react";
import network from "network";
import config from "config";
import Button from "@mui/material/Button";
import EditFormDrawer from "components/custom/EditFormDrawer";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";


export default function CreateFaq(props) {
    const [faqObject, setfaqObject] = useState(null)
    const params = useParams()

    async function loadFunc() {
        const module = await network.get(`${config.base_path}/api/dashboard/faq/sample`)
        setfaqObject(module.data.payload)
    }
    const handleSubmit = async (mainData, diff, diffPath) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/faq/create`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success("New Faqs created successfully")
        }
        else
        toast.error(res.data.message , {autoClose:2000})
    }
    return <>
        <Button variant={"outlined"} onClick={() => loadFunc()}>+Create New Faq</Button>
        {!!faqObject && <EditFormDrawer onClose={() => setfaqObject(null)} open={!!faqObject} schema={faqObject} onSave={handleSubmit} />}
    </>
}
