import React from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import config from "config";
import network from "network";
import LinearProgress from '@mui/material/LinearProgress';
import Button from "@mui/material/Button";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from "@mui/material/TextField";
import DialogBox from 'components/custom/dialog-box'
import PublishIcon from '@mui/icons-material/Publish';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BasePage from "components/BasePage";
import Chip from "@mui/material/Chip";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import {ButtonGroup} from '@mui/material/index';
import {useState} from 'react';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Loader from 'components/custom/Loader';
import {toast} from 'react-toastify';

const fileDownload = require('js-file-download');

export default function Home() {
    const params = useParams()
    const [pageSize, setPageSize] = useState(params.page_size || 10)
    const [data, setData] = useState([])
    const [nextPage, setNextPage] = useState(true)
    const [page, setPage] = useState(params.page || 1)
    const [sendMailBox, setSendMailBox] = useState(false)

    const apiCall = () => {
        network.get(`${config.base_path}/api/dashboard/unluclass?deliverable_types=430,440&page=${page}&page_size=${pageSize}`)
            .then((response) => {
                setData(response.data.payload)
                if (response.data.payload.length === 0)
                    setNextPage(false)
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    useEffect(() => {
        apiCall();
    }, [page])


    const convertDate = (milliseconds) => {
        return new Date(milliseconds).toLocaleDateString()
    }

    function exportData(copy) {
        const csvString = [
            [
                'Unlu Order ID',
                'PG Order ID',
                'Payment ID',
                'Payment Type',
                'Requested For',
                'Request Type',
                'Their Name',
                'Amount',
                'Coupon Used',
                'Original Amount',
                'Username',
                'User id',
                'User Name',
                'User Phone no',
                'User email id',
                'Date of Creation',
                'Payment Status',
                'Celeb Paid',
                'Instructions',
                'Celeb name',
                'Celeb username'
            ],
            ...data.map(item => [
                item.id,
                item.transaction?.pg_order_id,
                item.transaction?.extra.rzp_payment ? item.transaction?.extra.rzp_payment["id"] : item?.transaction?.extra?.payment?.response?.id,
                item.transaction?.extra.order ? item.transaction?.extra.order.type : item.transaction?.extra.rzp_order ? 'Razorpay' : '',
                item.requested_for === 300 ? 'Other' : item.requested_for === 310 ? 'Self' : item.requested_for === 320 ? 'Brand' : '',
                deliverableType(item.deliverable_type),
                item.requested_for_extra === ':' ? '' : item.requested_for_extra || '',
                item.transaction?.amount,
                item.transaction?.coupon_code || '',
                item.transaction?.original_amount,
                item.requester.username,
                item.requester.id,
                item.requester?.name,
                item.requester.phone_number || '',
                item.requester.email || '',
                convertDate(item.created_at),
                paymentStatus(item.payment_status),
                item.celeb_paid || '',
                item.instructions.replaceAll('\r', ' ').replaceAll('\n', '') || '',
                item.requested?.name || '',
                item.requested?.username || ''
            ])
        ].map(e => e.map(a => `"${a}"`).join(",")).join("\n")
        if (copy) {
            navigator.clipboard.writeText(csvString)
            toast('CSV data is copied to the clipboard')
            return
        }
        fileDownload(csvString, 'requestData.csv')
    }

    function paymentStatus(payment_status) {
        if (payment_status === 200) {
            return 'Payment Pending'
        }
        if (payment_status === 210) {
            return 'Payment Received'
        }
        if (payment_status === 220) {
            return 'Payment Refund Pending'
        }
        if (payment_status === 230) {
            return 'Payment Refunded'
        }
    }

    function deliverableType(deliverable_type) {
        if (deliverable_type === 400) {
            return 'Video Shoutout'
        }
        if (deliverable_type === 410) {
            return 'Video Call'
        }
        if (deliverable_type === 430) {
            return 'Unlu Class'
        }
        if (deliverable_type === 440) {
            return 'All Access Pass'
        }
    }

    return (
        <BasePage>
            <div>

                {data ? <div style={{paddingBottom: "5vh"}}>
                        <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                            <Button onClick={() => exportData(false)} variant="contained" color="primary"
                                    style={{marginLeft: '2vw'}}>
                                <PublishIcon style={{height: '1.25vw'}}/> Export
                            </Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => exportData(true)} style={{marginLeft: '2vw'}}>
                                <FileCopyIcon style={{height: '1.25vw'}}/> Copy CSV
                            </Button>
                            <TextField style={{width: '22vh'}}
                                       required
                                       value={pageSize}
                                       label="No of Records"
                                       id="pageSize"
                                       variant="outlined"
                                       onChange={(e: any) => setPageSize(e.target.value)}
                                       InputProps={{
                                           endAdornment:
                                               <InputAdornment position="end">
                                                   <IconButton edge="end" color="primary">
                                                       <SendIcon onClick={() => {
                                                           apiCall()
                                                       }}/>
                                                   </IconButton>
                                               </InputAdornment>
                                       }}
                            />
                        </div>

                        {data.map((row) => {
                            return (
                                <Accordion key={row.id} style={{padding: "10px", marginTop: "10px",}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Chip label={paymentStatusString(row.payment_status)}/>
                                        <Typography style={{padding: "5px",}}>Requested For <b
                                            style={{color: '#A3A0FB'}}>{row.requested?.title}</b> by <b>{row.requester_name}</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List component={Paper} style={{padding: "10px", marginTop: "10px",}}>
                                            <ListItem>
                                                <ListItemText style={{width: "65vw"}}
                                                              secondary={
                                                                  <Typography component="span" style={{fontSize: "14px",}}>
                                                        <span style={{fontSize: "18px",}}>
                                                            <b>Order Details:</b>
                                                        </span>
                                                                      <br/>
                                                                      <b>Unlu Order ID:</b> {row.id}
                                                                      <br/>
                                                                      <b>PG Order ID:</b> {row.transaction?.pg_order_id}
                                                                      <br/>
                                                                      {row.transaction?.extra.rzp_payment ?
                                                                          <span>
                                                                <b>Payment ID:</b> {row.transaction?.extra.rzp_payment["id"]}
                                                            </span>
                                                                          : null
                                                                      }
                                                                      {row.transaction?.extra.payment ?
                                                                          <span>
                                                                <b>Payment ID:</b> {row.transaction?.extra.payment?.response?.id}
                                                            </span>
                                                                          : null
                                                                      }
                                                                      <br/>
                                                                      {row.transaction?.extra.order ?
                                                                          <span>
                                                                <b>Payment Type:</b> {row.transaction?.extra.order.type}
                                                            </span>
                                                                          : null
                                                                      }
                                                                      {row.transaction?.extra.rzp_order ?
                                                                          <b>Payment Type: razorpay</b>
                                                                          : null
                                                                      }
                                                                      <br/>
                                                                      <span>
                                                            <b> Amount Paid: </b> {row.transaction?.currency === 'INR' ? '₹' :
                                                                          row.transaction?.currency === 'USD' ? '$' : row.transaction?.currency} {row.transaction?.amount}/-
                                                        </span>
                                                                      <br/>
                                                                      <span>
                                                            <b>Coupon Used:</b> {row.transaction?.coupon_code || 'None'}
                                                        </span>
                                                                      <br/>
                                                                      <span>
                                                            <b>Original Amount:</b> {row.transaction?.currency === 'INR' ? '₹' :
                                                                          row.transaction?.currency === 'USD' ? '$' : row.transaction?.currency} {row.transaction?.original_amount}/-
                                                        </span>
                                                                      <br/>
                                                                      <span>
                                                            <b>Created at:</b> {convertDate(row.created_at)}
                                                        </span>
                                                                      {row.deliverable &&
                                                                          <span>
                                                                <br/><b>Deliverable:</b> <a href={row.deliverable}
                                                                                            target={'blank'}>{row.deliverable}</a>
                                                            </span>
                                                                      }
                                                                      <br/>
                                                                      <span>
                                                            <b>Payment Status:</b> {row.payment_status === 200 ? 'Payment Pending' : row.payment_status === 210 ? 'Payment Received' :
                                                                          row.payment_status === 220 ? 'Payment Refund Pending' : row.payment_status === 230 ? 'Payment Refunded' : null}
                                                        </span>
                                                                      <br/>
                                                                      <span style={{fontSize: "18px",}}>
                                                            <b>User Details:</b>
                                                        </span>
                                                                      <br/>
                                                                      <span>
                                                            <b>User id:</b> {row.requester.id}
                                                        </span>
                                                                      <br/>
                                                                      <span>
                                                            <b>User Name:</b> {row.requester.name}
                                                        </span>
                                                                      <br/>
                                                                      <span>
                                                            <b>User Phone Number:</b> {row.requester.phone_number || 'NOT AVAILABLE'}
                                                        </span>
                                                                      <br/>
                                                                      <span>
                                                            <b>User Email:</b> {row.requester.email || 'NOT AVAILABLE'}
                                                        </span>
                                                                      <br/>
                                                                      <span>
                                                            <b>Payment Type:</b> {row.transaction?.extra.payment.type || 'NOT AVAILABLE'}
                                                        </span>
                                                                  </Typography>}
                                                />
                                            </ListItem>

                                        </List>


                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>
                    :
                    <Loader/>}
                {data ?
                    <Grid item container style={{width: "100%"}} justifyContent={"flex-end"}>
                        <ButtonGroup>
                            <Button variant={"outlined"} disabled={Number(page) === 1}
                                    onClick={() => setPage(Number(page) - 1)}>{Number(page) - 1}</Button>
                            <Button disabled>{page}</Button>
                            <Button variant={"outlined"} disabled={!nextPage}
                                    onClick={() => setPage(Number(page) + 1)}>{Number(page) + 1}</Button>
                        </ButtonGroup>
                    </Grid>
                    :
                    <div>
                        <LinearProgress/>
                        <LinearProgress color="secondary"/>
                    </div>
                }
            </div>
            {sendMailBox && <DialogBox closeBox={() => setSendMailBox(false)}
                                       openBox={() => sendMailBox}
                                       confirm={() => setSendMailBox(false)}
                                       heading={'Coming Soon!'} acceptText={'Okay!'}/>}
        </BasePage>
    );
};

function paymentStatusString(code) {
    switch (code) {
        case 220:
            return "Refund Pending"
        case 200:
            return "Payment Pending"
        case 210:
            return "Payment Received"
        case 230:
            return "Payment Refunded"
    }
}

