import {Button} from "@mui/material"
import {useNavigate, useParams} from "react-router-dom"
import CreateSyncTags from "components/sync/createtags"

export default function TagMapping(props) {
    const navigate = useNavigate()
    const params = useParams()

    return <>
        <CreateSyncTags/>
        <Button variant={"outlined"} onClick={() => navigate(`/sync/artists/${params.user_id}/assets/${params.asset_id}/attach-tags`)}> Attach Tags</Button>
    </>
}
