import React, {useState, useEffect} from "react";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import config from "config";
import utils from "utils";
import network from "network";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DialogBox from "components/custom/dialog-box";
import PublishIcon from "@mui/icons-material/Publish";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import moment from "moment";
import BasePage from "components/BasePage";
import Chip from "@mui/material/Chip";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import {useParams} from "react-router-dom";
import Loader from "components/custom/Loader";
import {FormControl} from "../../../node_modules/@mui/material/index";
import { convertDateAndTime } from "utils/common";

const fileDownload = require("js-file-download");


export default function Applications(props) {
    const params = useParams()
    const [pageSize, setPageSize] = useState(params.page_size || 10)
    const [data, setData] = useState([])
    const [course, setCourse] = useState('vocal-music')
    const [next, setNext] = useState(true)
    const [page, setPage] = useState(params.page || 1)
    const [status, setStatus] = useState(localStorage.getItem("course_application_status") || 600)
    const [sendMailBox, setSendMailBox] = useState(false)
    const [offeredPrice, setOfferedPrice] = useState(null)
    const [addPrice, setAddPrice] = useState(false)
    const [search, setSearch] = useState('')
    const [editOfferedPrice, setEditOffferedPrice] = useState(false)
    const [updateOPrice, setUpdateOPrice] = useState('')


    const apiCall = () => {
        network.get(`${config.base_path}/api/unluclass/${course}/applications?page=${page}&status=${status}&page_size=${pageSize}&search=${search}`)
            .then((response) => {
                setData(response.data.payload)
                if (response.data.payload?.length === 0) {
                    setNext(false)
                } else {
                    setNext(true)
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    };

    useEffect(() => {
        apiCall()
        if (localStorage.getItem("filterQuery") === null)
            localStorage.setItem("filterQuery", "?");
    }, [course, page, status, pageSize, search])

    async function request(action, request_id, user_id) {
        network
            .post(
                config.base_path +
                `/api/unluclass/${course}/${action}_application`,
                {
                    remark: action,
                    user_id: user_id,
                    offered_price: offeredPrice || "",
                }
            )
            .then((response) => {
                if (response.data.statusCode === 0) {
                    alert(`${request_id} successfully ${action}ed`);
                    window.location.reload();
                } else {
                    alert(
                        "There is some error in the request. \n" + response.data.message
                    );
                }
            })
            .catch((err) => {
                console.log(err);
                alert("There is some error in the request. \n" + err);
            });
    }

    async function updateOfferedPrice(request_id, user_id, offered_price) {
        if (updateOPrice === offered_price) {
            return alert("Please change the amount and try again");
        }
        network
            .post(
                config.base_path +
                `/api/unluclass/${course}/update_offered_price`, {
                    remark: "Update offered price",
                    user_id: user_id,
                    offered_price: updateOPrice || "",
                })
            .then((response) => {
                if (response.data.statusCode === 0) {
                    alert(`${request_id} successfully updated`);
                    window.location.reload();
                } else {
                    alert(
                        "There is some error in the request. \n" + response.data.message
                    );
                }
            })
            .catch((err) => {
                console.log(err);
                alert("There is some error in the request. \n" + err);
            });
    }

    async function exportData(copy) {
        let csv_data;
        await network
            .get(
                `${config.base_path}/api/unluclass/${course
                }/applications?page=1&page_size=${pageSize
                }`
            )
            .then((response) => {
                csv_data = response.data.payload;
            })
            .catch(function (error) {
                console.log(error);
                return alert("Something went wrong. Please contact admin!");
            });
        const csvString = [
            [
                "S No",
                "Unlu Application ID",
                "User Name",
                "User Phone Number",
                "User Email",
                "User id",
                "First touch url",
                "Application Date",
                "Acceptance Criteria",
                "Acceptance Criteria Attachments",
                "Acceptance Status",
                "Payment Status",
                "Offered Price",
                "Amount Paid",
                "Remarks",
            ],
            ...csv_data.map((item, index) => [
                index + 1,
                item.id,
                item.user.name,
                item.user.phone_number,
                item.user.email,
                item.user.id,
                item.user.first_touch_url,
                convertDateAndTime(item.created_at),
                item.acceptance_criteria,
                item.acceptance_criteria_attachments.join("\n"),
                item.status === 600
                    ? "Applied"
                    : item.status === 610
                        ? "Accepted"
                        : item.status === 620
                            ? "Rejected"
                            : item.status === 630
                                ? "Applied for next cohort"
                                : "",
                !!item.success_request ? "Payment Received" : "Payment Not Received",
                item.offered_price || "",
                item.success_request?.transaction?.amount || "",
                item.remark,
            ]),
        ]
            .map((e) => e.map((a) => `"${a}"`).join(","))
            .join("\n");
        if (copy) {
            return navigator.clipboard.writeText(csvString);
        }
        fileDownload(csvString, "requestData.csv");
    }

    function applicationStatusLabel(status) {
        switch (status) {
            case 610:
                return "Accepted"
            case 630:
                return "Next Cohort"
            case 620:
                return "Rejected"
            case 600:
                return "Applied"
        }
    }

    return (
        <BasePage>
            <Grid container spacing={2}>
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel> Select Course </InputLabel>
                        <Select
                            id="select"
                            name="course"
                            value={course}
                            label={'Select Course'}
                            onChange={(e: any) => setCourse(e.target.value)}
                        >
                            <MenuItem value={"vocal-music"}>Vocal Music</MenuItem>
                            <MenuItem value={"lyrics-writing"}>Lyrics Writing</MenuItem>
                            <MenuItem value={"voice-acting"}>Voice Acting</MenuItem>
                            <MenuItem value={"screenwriting"}>Screenwriting</MenuItem>
                            <MenuItem value={"youtuber"}>Youtuber</MenuItem>
                            <MenuItem value={"author"}>Author</MenuItem>
                            <MenuItem value={"audio-story-writing"}>Audio Story Writing</MenuItem>
                            <MenuItem value={'music-composition'}>Music Composition</MenuItem>
                            <MenuItem value={'music-production'}>Music Production</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel>Select Status</InputLabel>
                        <Select
                            id="select"
                            name="course"
                            value={status}
                            label={'Select Status'}
                            onChange={(e: any) => setStatus(e.target.value)}
                        >
                            <MenuItem value={600}>Applied</MenuItem>
                            <MenuItem value={610}>Accepted</MenuItem>
                            <MenuItem value={620}>Rejected</MenuItem>
                            <MenuItem value={630}>Applied for next cohort</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <TextField style={{width: '16vh'}}
                                   required
                                   value={pageSize}
                                   id="pageSize"
                                   variant="outlined"
                                   label={'No of Records'}
                                   onChange={(e: any) => setPageSize(e.target.value)}
                                   InputProps={{
                                       endAdornment:
                                           <InputAdornment position="end">
                                               <IconButton edge="end" color="primary">
                                                   <SendIcon onClick={() => {
                                                       apiCall()
                                                   }}/>
                                               </IconButton>
                                           </InputAdornment>

                                   }}/>
                    </FormControl>
                </Grid>
                <Grid item md={4}></Grid>
                <Grid item>
                    <Button
                        onClick={() => exportData(true)}
                        variant="contained"
                        color="secondary"
                    >
                        <FileCopyIcon style={{height: "1.25vw"}}/> Copy CSV
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => {
                            exportData(false);
                        }}
                        variant="contained"
                        color="primary"
                    >
                        <PublishIcon/> Export
                    </Button>
                </Grid>
            </Grid>
            <Grid>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    name="search"
                    onChange={(e: any) => setSearch(e.target.value)}
                    label={"Search by User Name/Email/Phone"}
                    id="tag"
                />
            </Grid>
            {data ? (<>
                {data.map((row) => {
                    return (
                        <Accordion key={row.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Stack justifyContent={"flex-start"} direction={"row"}>
                                    <Stack direction={"column"}>
                                        <Chip label={applicationStatusLabel(row.status)}/>
                                        {!!row.success_request && (
                                            <Chip label={"Payment Received"}
                                                  style={{color: "#2FAC8B"}}/>
                                        )}
                                    </Stack>
                                    <Typography variant={"subtitle2"}>
                                        <br/>Requested by <br/><b>{row.user.name}</b>
                                    </Typography>
                                </Stack>

                            </AccordionSummary>
                            <AccordionDetails>
                                <List
                                    component={Paper}
                                    style={{padding: "10px", marginTop: "10px"}}
                                >
                                    <ListItem>
                                        <ListItemText
                                            style={{width: "100%"}}
                                            secondary={
                                                <Typography
                                                    component="span"
                                                    style={{
                                                        fontFamily: "Source Sans Pro",
                                                        color: "rgba(67, 66, 93, 0.5)",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    <span>
                                                        <b>Unlu Application ID:</b> {row.id}
                                                    </span>
                                                    <br/>
                                                    <span>
                                                        <b>Application Date:</b>{" "}
                                                        {convertDateAndTime(row.created_at || null)}
                                                    </span>
                                                    <br/>
                                                    <span>
                                                        <b>First touch url:</b>{" "}
                                                        {row.user.first_touch_url}
                                                    </span>
                                                    <br/>
                                                    {
                                                        <span>
                                                            <b>Offered Amount:</b>{" "}
                                                            {!editOfferedPrice &&
                                                                (row.offered_price || "N/A")}
                                                            {editOfferedPrice && (
                                                                <TextField
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    required
                                                                    fullWidth
                                                                    defaultValue={row.offered_price}
                                                                    name="update_offered_price"
                                                                    onChange={(e: any) => setUpdateOPrice(e.target.value)}
                                                                    id={row.id}
                                                                    label={'Update Offered Price'}
                                                                />
                                                            )}
                                                        </span>
                                                    }
                                                    <Button
                                                        disabled={
                                                            !(
                                                                !row.success_request &&
                                                                row.status === 610
                                                            )
                                                        }
                                                        onClick={() =>
                                                            editOfferedPrice
                                                                ? updateOfferedPrice(
                                                                    row.id,
                                                                    row.user.id,
                                                                    row.offered_price
                                                                )
                                                                : (setEditOffferedPrice(true) , setUpdateOPrice(row.offered_price))
                                                        }
                                                        style={{marginLeft: "10px", width: "5vw"}}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {editOfferedPrice
                                                            ? "Update"
                                                            : "Edit"}
                                                    </Button>
                                                    {editOfferedPrice && (
                                                        <Button
                                                            disabled={
                                                                !(
                                                                    !row.success_request &&
                                                                    row.status === 610
                                                                )
                                                            }
                                                            onClick={() => (setEditOffferedPrice(false) , setUpdateOPrice(''))}
                                                            style={{
                                                                marginLeft: "10px",
                                                                width: "5vw",
                                                            }}
                                                            variant="outlined"
                                                            color="secondary"
                                                        >
                                                            Cancel
                                                        </Button>
                                                    )}
                                                    <br/>
                                                    {
                                                        <span>
                                                            <b>Amount Received:</b>{" "}
                                                            {row.success_request?.transaction
                                                                ?.amount || "N/A"}
                                                        </span>
                                                    }
                                                    <br/>
                                                    {
                                                        <span>
                                                            <b>Payment Date:</b>{" "}
                                                            {convertDateAndTime(
                                                                row.success_request?.transaction
                                                                    ?.created_at || null
                                                            )}
                                                        </span>
                                                    }
                                                    <br/>
                                                    {
                                                        <span>
                                                            <b>Payment Gateway ID:</b>{" "}
                                                            {row.success_request?.transaction
                                                                ?.pg_order_id || "N/A"}
                                                        </span>
                                                    }
                                                    <br/>
                                                    <span>
                                                        <b>Acceptance Criteria:</b>{" "}
                                                        {row.acceptance_criteria}
                                                    </span>
                                                    <br/>
                                                    <span>
                                                        <b>Acceptance Criteria Attachments:</b>{" "}
                                                        {row.acceptance_criteria_attachments.map(
                                                            (link, index) => (
                                                                <span key={index}>
                                                                    <br/>
                                                                    {index + 1}.{" "}
                                                                    <a
                                                                        key={index}
                                                                        href={link}
                                                                        target={"blank"}
                                                                    >
                                                                        {link}
                                                                    </a>
                                                                </span>
                                                            )
                                                        )}
                                                    </span>
                                                    <br/>
                                                    <span>
                                                        <b>User Phone Number:</b>{" "}
                                                        {row.user.phone_number}
                                                    </span>
                                                    <br/>
                                                    <span>
                                                        <b>User Email:</b> {row.user.email}
                                                    </span>
                                                    <br/>
                                                    <span>
                                                        <b>User id:</b> {row.user.id}
                                                    </span>
                                                    <br/>
                                                    {addPrice && (
                                                        <>
                                                            <TextField
                                                                variant="standard"
                                                                margin="normal"
                                                                required
                                                                fullWidth
                                                                defaultValue={null}
                                                                name="offered_price"
                                                                onChange={(e: any) => setOfferedPrice(e.target.value)}
                                                                id={row.id}
                                                                label={'Add Offered Price'}
                                                            />
                                                        </>
                                                    )}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <Grid container>
                                            <Grid item xs={1} md={1} sm={1}/>
                                            <Grid item
                                                  style={{textAlign: "right"}}
                                            >
                                                <Button
                                                    disabled={
                                                        row.status === 610 || row.status === 620
                                                    }
                                                    onClick={() =>
                                                        request("reject", row.id, row.user.id)
                                                    }
                                                    style={{
                                                        marginRight: "10px",
                                                        width: "5vw",
                                                    }}
                                                    variant="outlined"
                                                    color="secondary"
                                                >
                                                    Reject
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1} md={1} sm={1}/>
                                            <Grid item style={{textAlign: "right"}}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={
                                                        row.status === 620 || row.status === 610
                                                    }
                                                    onClick={() =>
                                                        true
                                                            ? setAddPrice(true)
                                                            : request(
                                                                "accept",
                                                                row.id,
                                                                row.user.id
                                                            )
                                                    }
                                                    style={
                                                        row.status === 620
                                                            ? {
                                                                width: "5vw",
                                                                backgroundColor: "#E0E0E0",
                                                            }
                                                            : {
                                                                width: "5vw",
                                                                backgroundColor: "#21c196",
                                                            }
                                                    }
                                                >
                                                    Accept
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
                <Grid item container style={{width: "100%"}} justifyContent={"flex-end"}>
                    <ButtonGroup>
                        <Button variant={"outlined"} disabled={Number(page) === 1}
                                onClick={() => setPage(Number(page) - 1)}>{Number(page) - 1}</Button>
                        <Button disabled>{page}</Button>
                        <Button variant={"outlined"} disabled={!next}
                                onClick={() => setPage(Number(page) + 1)}>{Number(page) + 1}</Button>
                    </ButtonGroup>
                </Grid>
            </>) : (<>
                <LinearProgress/>
                <LinearProgress color="secondary"/>
                <Loader/>
            </>)}
            {sendMailBox && (
                <DialogBox
                    closeBox={() => setSendMailBox(false)}
                    openBox={() => sendMailBox}
                    confirm={() => setSendMailBox(false)}
                    heading={"Coming Soon!"}
                    acceptText={"Okay!"}
                />
            )}
        </BasePage>
    );
}
