import React, {useEffect, useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Link} from "@mui/material";


function WorkOppo(props) {
    const navigate = useNavigate()
    const params = useParams()
    const {status} = props
    const [showEntry, setShowEntry] = useState(false)
    const [attachment, setAttachment] = useState(null)
    const onAcceptorRejectClick = async (value) => {
        if (window.confirm("Are you sure about it ?")) {
            const response = await network.post(`${config.base_path}/api/dashboard/work_opportunity/${params.work_opp}/applications/${props.id}`, {
                status: value
            })
            if (response.data.statusCode === 0) {
                props.loadFunc(params?.page)
                return toast(`Application ${value}`)
            } else
                return toast(response.data.message)
        }
    }

    async function getEntry() {
        const response = await network.get(`${config.base_path}/api/community/dashboard/attachments?_id=${props.metadata?.submission_id}`)
        setAttachment(response.data.payload[0].attachment)
    }

    useEffect(() => {
        getEntry()
    }, [])

    return <Grid item xs={12}>
        <Card style={{
            margin: "8px",
            padding: "8px",
            backgroundColor: status === 'rejected' ? '#EC4C4C' : status === "accepted" ? '#6ECD57' : 'auto'
        }}>
            <Grid container direction={"row"}>
                <Grid item style={{flexGrow: 1}}>
                    <Grid item xs={12}>Name: &nbsp;<b>{props?.applicant?.full_name}</b></Grid>
                    <Grid item xs={12}>Username: &nbsp;{props?.applicant?.username}</Grid>
                    {props.metadata?.submission_id &&
                        <Grid item xs={12}>
                            Attachment: <Link href={attachment?.url} target={"_blank"}>{attachment?.url}</Link>
                        </Grid>
                    }
                </Grid>
                <Grid item>
                    {props?.opportunity?.assignment_question && <Button variant={"outlined"}
                                                                        onClick={() => navigate(`/works/${params.work_opp}/assignments/${props?.opportunity?.assignment_question?.assignment_id}/user/${props?.applicant?.id}/1`)}>
                        Assignment Response</Button>}
                    <Link href={`https://www.unlu.io/profile/${props?.applicant?.username}`} target={"_blank"}
                          sx={{textDecoration: 'none'}}><Button variant={"outlined"}>Go To Profile</Button></Link>
                    <Button variant={"outlined"} onClick={() => onAcceptorRejectClick("accepted")}>Accept</Button>
                    <Button variant={"outlined"} onClick={() => onAcceptorRejectClick("rejected")}>Reject</Button>
                </Grid>
            </Grid>
        </Card>
    </Grid>
}

export default function WorksOppApplications(props) {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const params = useParams()

    async function loadFunc(page) {
        const work = await network.get(`${config.base_path}/api/dashboard/work_opportunity/${params.work_opp}/applications?page=${page}`)
        setHasMore(work.data.payload.length !== 0)
        setItems(work.data.payload)
    }

    return <BasePage>
        <CDataTable
            pageUrl={`/works/${params.work_opp}/applications/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <WorkOppo key={index} {...a} loadFunc={loadFunc}/>)}/>
    </BasePage>
}
