import React, { useState } from "react";
import network from "network";
import config from "config";
import Button from "@mui/material/Button";
import EditFormDrawer from "components/custom/EditFormDrawer";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";


export default function CreateModules(props) {
    const [moduleObject, setModuleObject] = useState(null)
    const params = useParams()

    async function loadFunc() {
        const module = await network.get(`${config.base_path}/api/dashboard/module/sample?class_id=${params.class_id}`)
        setModuleObject(module.data.payload)
    }
    const handleSubmit = async (mainData, diff, diffPath) => {
        let res = await network.post(
            `${config.base_path}/api/dashboard/module/create?class_id=${params.class_id}`, mainData
        )
        if (res.data.statusCode === 0) {
            window.location.reload()
            toast.success("Module created successfully", {autoClose : 1000})
        }
        else
            toast.error(res.data.message)
    }
    return <>
        <Button variant={"outlined"} onClick={() => loadFunc()}>+Create New Module</Button>
        {!!moduleObject && <EditFormDrawer id={'edit_module'} onClose={() => setModuleObject(null)} open={!!moduleObject} schema={moduleObject} onSave={handleSubmit} />}
    </>
}
