import React, {useEffect, useState} from "react";
import network from "network";
import config from "config";
import BasePage from "components/BasePage";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CDataTable from "components/custom/CDataTable"
import Card from "@mui/material/Card";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify"
import FileCopyIcon from "@mui/icons-material/FileCopy";
import fileDownload from "js-file-download";
import Link from "@mui/material/Link";
import {ButtonGroup, FormControl, Tab, Tabs} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import PublishIcon from "@mui/icons-material/Publish";
import Stack from "@mui/material/Stack";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';


function UnluEvent(props) {
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'))

    async function onSendMailClick() {
        const text = `Do you want to send email to ${props?.user?.name?.first || props?.guest?.name?.first}?`;
        if (window.confirm(text) === true) {
            const res = await network.post(`${config.base_path}/api/dashboard/unlu_events/${props.event_id}/user/${props.user_id}/send_purchase_mail`, {})
            if (res.data.statusCode === 0)
                toast.success('Mail has been sent', {autoClose: 2000})
            else toast.error(res.data.message, {autoClose: 2000})
        }
    }

    async function onSendCertificate() {
        const text = `Do you want to send certificate to ${props?.user?.name?.first || props?.guest?.name?.first}?`;
        if (window.confirm(text) === true) {
            const res = await network.post(`${config.base_path}/api/dashboard/unlu_events/${props.event_id}/user/${props.user_id}/send_certificate`, {})
            if (res.data.statusCode === 0)
                toast.success('Certificate has been sent', {autoClose: 2000})
            else toast.error(res.data.message, {autoClose: 2000})
        }
    }

    return <Grid item xs={12}>
        <Card style={{margin: "8px", padding: "8px"}}>
            <Stack spacing={1}>
                <p><b style={{color: '#1976d2'}}>{props?.order_id ? 'paid' : ''}</b></p>
                {!props.is_guest ?
                    <p>Name: <b>{props?.user?.name?.first} {props?.user?.name?.last}</b></p> :
                    <p>Name: <b>{props?.guest?.name?.first} {props?.guest?.name?.last}<b
                        style={{color: '#1976d2'}}> {`/ Alien`}</b></b></p>}
                {props?.attachments[0]?.url && <p>Attachment: <Link rel="stylesheet" href={props?.attachments[0]?.url}
                                                                    target={'_blank'}>{props?.attachments[0]?.url}</Link>
                </p>}
                {props?.guest && <><p>Email: <b>{props?.guest?.email}</b></p>
                    <p>phone_number: <b>{props?.guest?.phone_number}</b></p></>}
                {props?.user && <p>username: <b>{props?.user?.username}</b></p>}
                {props?.user?.email && <p>email: <b>{props?.user?.email}</b></p>}
                {props?.user?.phone_number && <p>phone_number: <b>{props?.user?.phone_number}</b></p>}
                {props?.extra?.category && <p>category: <b>{props?.extra?.category}</b></p>}
                {props?.extra?.state && <p>state: <b>{props?.extra?.state}</b></p>}
                {props?.extra?.district && <p>district: <b>{props?.extra?.district}</b></p>}
                {props?.extra?.address && <p>address: <b>{props?.extra?.address}</b></p>}
                {props?.submissions?.[0]?.attachment?.url &&
                    <p>Attachment: <Link href={props?.submissions?.[0]?.attachment?.url} target={"_blank"}>
                        {props?.submissions?.[0]?.attachment?.url}
                    </Link></p>}
                {props?.submissions?.[0]?.attachment.type === 'short_story' &&
                    <p>Story: <Link
                        href={`https://www.mugafi.com/tools/write-with-ved/${props?.submissions?.[0]?.attachment?.story_metadata?.id}`}
                        target={"_blank"}>
                        {props?.user?.name?.first + `'s story`}
                    </Link></p>}
            </Stack>
            <ButtonGroup>
                {props.event_id === "63a070cdb88311ce2b8457ad" &&
                    <Button variant={'outlined'} onClick={() => onSendCertificate()}>
                        {props.certificate_sent ? 'Re-send Certificate' : 'Send certificate'}
                    </Button>}
                {props?.order_id &&
                    <Button variant={'outlined'}
                            onClick={() => onSendMailClick()}>{props?.purchase_mail_sent ? 'Re-send purchase mail' : 'Send purchase mail'}</Button>}
            </ButtonGroup>
        </Card>
    </Grid>
}

export default function EventRegistrations(props) {
    const params = useParams()
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [pageSize, setPageSize] = useState(params.page_size || 10)
    const [tabValue, setTabValue] = useState(0)
    const [selectedDate, setSelectedDate] = useState(null)
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('md'))

    const handleDateChange = (date) => {
        const updatedDateString = date.format('YYYY-MM-DD');
        setSelectedDate(updatedDateString.toString())
    };

    async function loadFunc(page) {
        const work = await network.get(`${config.base_path}/api/dashboard/unlu_events/${params.event_id}/list/${page}?page_size=${pageSize}&purchased=${tabValue === 0}`)
        setHasMore(work.data.payload.length !== 0)
        setItems(work.data.payload)
    }

    async function loadDataViaDate(page) {
        const priorDate = new Date(selectedDate); // Create a Date object with the specified date
        priorDate.setDate(priorDate.getDate() - 1); // Subtract one day
        const updatedDate = priorDate.toISOString().slice(0, 10);
        const work = await network.get(`${config.base_path}/api/dashboard/unlu_events/${params.event_id}/list/${page}?page_size=10000&purchased=${tabValue === 0}&start_date=${updatedDate}&end_date=${selectedDate}`)
        setHasMore(work.data.payload.length !== 0)
        setItems(work.data.payload)
    }

    const handleChange = (e, newValue) => {
        setTabValue(newValue);
    }

    useEffect(() => {
        loadFunc(params.page)
    }, [tabValue])

    function exportData(copy) {
        const csvString = [
            [
                'no.',
                'id',
                'name',
                'username',
                'email',
                'phone_number',
                'attachments',
                'category',
                'state',
                'district',
                'address',
                'payment order_id',
                'payment status',
                'enrolled_for_event_id'
            ],
            ...items.map((item, index) => {
                const attachment = item?.submissions?.[0]?.attachment?.story_metadata?.id ? `https://www.mugafi.com/tools/write-with-ved/${item?.submissions?.[0]?.attachment?.story_metadata?.id}` : item?.submissions?.[0]?.attachment?.url

                return [
                    index + 1,
                    item?.id,
                    item?.is_guest ? item?.guest?.name?.first + " " + item?.guest?.name?.last : item.user?.name?.first + " " + item.user?.name?.last,
                    item?.is_guest ? item?.guest?.username : item?.user?.username,
                    item?.is_guest ? item?.guest?.email : item?.user?.email,
                    item?.is_guest ? item?.guest?.phone_number : item?.user?.phone_number,
                    attachment,
                    item?.extra?.category,
                    item?.extra?.state,
                    item?.extra?.district,
                    item?.extra?.address,
                    item?.order_id,
                    item.order_id ? 'paid' : 'unpaid',
                    item?.event_id
                ];
            })
        ].map(e => e.map(a => `"${a}"`).join(",")).join("\n")
        if (copy) {
            navigator.clipboard.writeText(csvString);
            toast('CSV data is copied to the clipboard')
            return;
        }
        fileDownload(csvString, 'contestRequestData.csv')
    }

    return <BasePage>
        <Stack direction={matchMobile ? 'column' : 'row'} spacing={2} sx={{justifyContent: 'flex-end'}}>
            <Tabs value={tabValue} onChange={handleChange}>
                <Tab label="Paid" value={0}/>
                <Tab label="Unpaid" value={1}/>
            </Tabs>
            <FormControl>
                <TextField style={{minWidth: '60px'}}
                           required
                           value={pageSize}
                           id="pageSize"
                           variant="outlined"
                           label={'No of Records'}
                           onChange={(e: any) => setPageSize(e.target.value)}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <IconButton edge="end" color="primary" onClick={() => {
                                           loadFunc(params.page)
                                       }}>
                                           <SendIcon/>
                                       </IconButton>
                                   </InputAdornment>

                           }}/>
            </FormControl>
            <Button
                onClick={() => exportData(true)}
                variant="contained"
                color="secondary"
            >
                <FileCopyIcon style={{height: "1.25vw"}}/> Copy CSV
            </Button>
            <Button
                onClick={() => {
                    exportData(false);
                }}
                variant="contained"
                color="primary"
            >
                <PublishIcon/> Export
            </Button>
        </Stack>
        {params.event_id === '64aff1d54506f8d6293fe2d4' && <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker onChange={e => handleDateChange(e)}/>
                </DemoContainer>
            </LocalizationProvider>
            <Button variant={'contained'} color="primary" onClick={() => loadDataViaDate(params.page)}>
                Export data via date
            </Button>
        </>}
        <CDataTable
            pageUrl={`/events/${params.event_id}/`}
            page={parseInt(params.page)}
            pageChanged={loadFunc}
            hasMore={hasMore}
            items={items.map((a, index) => <UnluEvent key={index} {...a} />)}/>
    </BasePage>
}