import Skeleton from '@mui/material/Skeleton';
export default function Loader(){
    return <div>
    <Skeleton variant="text" height={"10vh"}/>
    <Skeleton variant="text"/>
    <Skeleton variant="text" height={"10vh"}/>
    <Skeleton variant="text"/>
    <Skeleton variant="text" height={"10vh"}/>
    <Skeleton variant="text"/>
    <Skeleton variant="text" height={"10vh"}/>
    <Skeleton variant="text"/>
    <Skeleton variant="text" height={"10vh"}/>
    <Skeleton variant="text"/>
    <Skeleton variant="text" height={"10vh"}/>
    <Skeleton variant="text"/>
</div>
}