import {useEffect, useState} from 'react';
import network from "../../network";
import config from "../../config";

export function useFetchPrograms(forDetailInfo = false) {
    const [fellowships, setFellowships] = useState([]);
    const [program, setProgram] = useState([]);
    const [unluclasses, setUnluclasses] = useState([]);

    useEffect(() => {
        const fetchData = async (classType, setterFunc) => {
            try {
                const res = await network.get(
                    forDetailInfo ?
                        `${config.base_path}/api/unluclass/1/get_all_classes?class_type=${classType}&page_size=50&is_purchased=false&is_live=true`
                        :
                        `${config.base_path}/api/web/unluclass/1/get?class_type=${classType}&page_size=50`
                );
                const classesList = res.data.payload.map((a) => ({
                    id: a._id,
                    label: a.title,
                    class_type: a.class_type,
                    is_purchased: a.is_purchased
                }));
                setterFunc(classesList.filter((a) => a.class_type === classType));
            } catch (error) {
                console.error(error);
            }
        };

        fetchData(510, setFellowships)
        fetchData(520, setProgram)
        fetchData(500, setUnluclasses)
    }, []);

    return {
        fellowships,
        program,
        unluclasses
    };
}
